import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Alert, Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faExchange, faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import { API_URL } from "../../config";
import { useNavigate } from "react-router-dom";
import { Chat } from "@mui/icons-material";
import CaptureLoading from "./CaptureLoading";
import StarRating from "./AnimatedStarRating";
import { keyframes } from "@mui/system";
import log from "loglevel";
import * as Sentry from "@sentry/react";
import { useTour } from "@reactour/tour"; // Import loglevel

interface CastMember {
  adult: boolean;
  gender: number;
  id: number;
  known_for_department: string;
  name: string;
  original_name: string;
  popularity: number;
  profile_path: string | null;
  cast_id: number;
  character: string;
  credit_id: string;
  order: number;
}

interface TMDBResult {
  id: number;
  title: string;
  release_date: string;
  poster_path: string;
  overview: string;
  media_type: string;
  popularity: number;
  cast?: CastMember[];
  genres?: { name: string }[];
  vote_average?: number;
  vote_count?: number;
  original_language?: string;
  runtime?: number;
  budget?: number;
  revenue?: number;
  number_of_seasons?: number;
  number_of_episodes?: number;
  first_air_date?: string;
  last_air_date?: string;
  status?: string;
  type?: string;
}

interface TitleResponse {
  extracted_title: string;
  tmdb_result: TMDBResult | null;
}

const AnimatedBox = styled(Box)`
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes slideUp {
        from {
            transform: translateY(100%);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
`;

const summonEffect = keyframes`
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.05);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
`;

const CameraCaptureSearch: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [tmdbResult, setTmdbResult] = useState<TMDBResult | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [usingFrontCamera, setUsingFrontCamera] = useState(false);
  const [showPosterOverlay, setShowPosterOverlay] = useState(false);
  const [posterPath, setPosterPath] = useState<string | null>(null);
  const [capturedImageSrc, setCapturedImageSrc] = useState<string | null>(
    null
  );

  const { setSteps, setCurrentStep, setIsOpen, currentStep } = useTour();
  useEffect(() => {
    log.debug(
      "useEffect - isOverlayOpen:",
      isOverlayOpen,
      "capturedImageSrc:",
      capturedImageSrc
    );
    if (isOverlayOpen && !capturedImageSrc) {
      // if (setSteps) {
      //   setSteps([
      //     {
      //       selector: ".movie-display-item-0",
      //       content: "Click on your favorite movies to see their details!"
      //     }, {
      //       selector: ".camera-icon-highlight",
      //       content: "Scan movie poster/paused screen"
      //     },
      //     {
      //       selector: ".camera-container",
      //       content: "Point the camera at the movie poster/paused screen"
      //     },{
      //       selector: "#summon-button",
      //       content: "Click the Summon button to start chatting to your favorite characters!"
      //     }
      //   ]);
      //   if (!tmdbResult){
      //     setCurrentStep(2);
      //   }
      //   setIsOpen(true);
      // }
      startCamera();
    } else {
      stopCamera();
    }
  }, [isOverlayOpen, capturedImageSrc]);


  const toggleCamera = () => {
    log.debug("toggleCamera - usingFrontCamera:", !usingFrontCamera);
    setUsingFrontCamera(!usingFrontCamera);
    if (isCameraActive) {
      stopCamera();
      startCamera();
    }
  };

  const startCamera = async () => {
    log.debug("startCamera - usingFrontCamera:", usingFrontCamera);
    try {
      const constraints = {
        video: {
          facingMode: usingFrontCamera ? "user" : "environment"
          // width: { ideal: 1920 },
          // height: { ideal: 1080 }
        }
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      log.debug("Camera stream started");
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        setIsCameraActive(true);
        log.debug("Camera is active");
      }
    } catch (err) {

      Sentry.captureException(err);
      console.error("Failed to start camera:", err);
      log.error("Failed to start camera:", err);
      setError(
        "Failed to access camera. Please ensure you've granted camera permissions."
      );
    }
  };

  const stopCamera = () => {
    log.debug("stopCamera");
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      log.debug("Camera stream stopped");
    }
    setIsCameraActive(false);
  };

  const captureImage = () => {
    log.debug("captureImage");
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        context.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        const imageDataUrl = canvasRef.current.toDataURL("image/jpeg");
        log.debug("Image captured, data URL length:", imageDataUrl.length);
        setCapturedImageSrc(imageDataUrl);
        stopCamera(); // Stop the camera after capturing the image
        uploadImage(imageDataUrl);
      }
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      log.debug("File uploaded:", file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageSrc = e.target?.result as string;

        const img = new Image();
        img.onload = () => {
          if (canvasRef.current) {
            const canvas = canvasRef.current;
            canvas.width = img.width;
            canvas.height = img.height;
            const context = canvas.getContext("2d");
            if (context) {
              context.drawImage(img, 0, 0, canvas.width, canvas.height);
              const imageDataUrl = canvas.toDataURL("image/jpeg"); // Ensure JPEG format
              setCapturedImageSrc(imageDataUrl); // Display the image
              stopCamera(); // Stop the camera if it's running
              uploadImage(imageDataUrl); // Send the image to backend
              log.debug("File read and processed");
            }
          }
        };
        img.onerror = (err) => {
          log.error("Error loading image", err);
          setError("Failed to load image");
        };
        img.src = imageSrc; // Trigger image load
      };
      reader.onerror = (err) => {
        log.error("Error reading file", err);
        setError("Failed to read file");
      };
      reader.readAsDataURL(file); // Read file as data URL
    }
  };

  const uploadImage = async (imageDataUrl: string) => {
    if (!imageDataUrl) return;

    log.debug("uploadImage - imageDataUrl length:", imageDataUrl.length);
    setIsLoading(true);
    try {
      const response = await fetch(imageDataUrl);
      const blob = await response.blob();
      log.debug("Image blob size:", blob.size);
      const formData = new FormData();
      formData.append("file", blob, "image.jpg");

      log.debug("Sending image to API");
      const apiResponse = await axios.post<TitleResponse>(
        `${API_URL}/api/extract-title/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      log.debug("API response status:", apiResponse.status);
      if (apiResponse.status !== 200) {
        stopCamera(); // Stop the camera after capturing the image
        throw new Error("Failed to upload image");
      }

      setTmdbResult(apiResponse.data.tmdb_result);
      setCurrentStep(3)
      if (apiResponse.data.tmdb_result) {
        stopCamera();
        log.debug("Received TMDB result:", apiResponse.data.tmdb_result);
        setPosterPath(apiResponse.data.tmdb_result.poster_path);
        setShowPosterOverlay(true);
      } else {
        log.warn("No movie or TV show information found");
        setError("No movie or TV show information found");
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error("Error uploading image:", err);
      log.error("Error uploading image:", err);
      setError("Failed to upload and process image");
    } finally {
      setIsLoading(false);
      setCapturedImageSrc(null);
      log.debug("Image upload process completed");
    }
  };


  const handleNavigateChat = (result: TMDBResult) => {
    log.debug("handleNavigateChat - Navigating to chat with result:", result);
    const chatType = result.media_type === "movie" ? "movie" : "series";
    const movieOrSeries = {
      id: result.id,
      title: result.title,
      name: result.title,
      overview: result.overview,
      poster_path: result.poster_path,
      release_date: result.release_date || result.first_air_date,
      vote_average: result.vote_average,
      vote_count: result.vote_count,
      popularity: result.popularity,
      original_language: result.original_language,
      cast:
        result.cast?.map((c) => ({
          name: c.name,
          character: c.character,
          profile_path: c.profile_path,
          from_movie: result.title
        })) || [],
      random_index: Math.floor(Math.random() * 1000),
      source: "tmdb"
    };

    navigate("/chat", { state: { movie: movieOrSeries, chat_type: chatType } });
    log.debug("Navigation to chat initiated");
  };

  const openOverlay = () => {
    log.debug("openOverlay");
    setIsOverlayOpen(true);
    setImageSrc(null);
    setShowConfirmation(false);
  };

  const closeOverlay = () => {
    log.debug("closeOverlay");
    setIsOverlayOpen(false);
    setImageSrc(null);
    setTmdbResult(null);
    setError(null);
    setShowConfirmation(false);
  };

  return (
    <>
      <IconButton onClick={openOverlay} color="primary" aria-label="open camera">
        <FontAwesomeIcon className={"camera-icon-highlight"} icon={faCamera} />
      </IconButton>
      <Modal
        open={isOverlayOpen}
        onClose={closeOverlay}
        aria-labelledby="camera-modal"
        aria-describedby="camera-modal-description"
      >
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "black",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
            {!capturedImageSrc && (
              <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover"
                  }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    position: "absolute",
                    top: 16,
                    left: "50%",
                    transform: "translateX(-50%)",
                    color: "white",
                    textAlign: "center",
                    textShadow: "0 2px 4px rgba(0,0,0,0.5)",
                    width: "100%"
                  }}
                >
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    Scene
                  </Box>{" "}
                  Summon
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    height: "60%",
                    pointerEvents: "none"
                  }}
                >
                  {["top-left", "top-right", "bottom-left", "bottom-right"].map(
                    (corner) => (
                      <Box
                        key={corner}
                        sx={{
                          position: "absolute",
                          width: "20px",
                          height: "20px",
                          border: "2px solid white",
                          ...(corner.includes("top") ? { top: 0 } : { bottom: 0 }),
                          ...(corner.includes("left")
                            ? { left: 0 }
                            : { right: 0 }),
                          borderBottom: corner.includes("top")
                            ? "none"
                            : undefined,
                          borderTop: corner.includes("bottom")
                            ? "none"
                            : undefined,
                          borderRight: corner.includes("left")
                            ? "none"
                            : undefined,
                          borderLeft: corner.includes("right")
                            ? "none"
                            : undefined
                        }}
                      />
                    )
                  )}
                  <Typography
                    variant="subtitle2"
                    sx={{
                      position: "absolute",
                      top: "-30px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      color: "white",
                      textShadow: "0 2px 4px rgba(0,0,0,0.5)",
                      width: "100%",
                      textAlign: "center",
                      fontSize: "0.9rem",
                      fontWeight: 500
                    }}
                  >
                    Scan movie poster/paused screen
                  </Typography>
                </Box>
              </Box>
            )}
            {capturedImageSrc && (
              <img
                src={capturedImageSrc}
                alt="Captured"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            )}
            {isLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.7)"
                }}
              >
                <CaptureLoading />
              </Box>
            )}

            {/* Top left - Swap camera button */}
            {!isLoading && !capturedImageSrc && (
              <IconButton
                onClick={toggleCamera}
                sx={{
                  position: "absolute",
                  top: 16,
                  left: 16,
                  width: "2rem",
                  height: "2rem",
                  backgroundColor: "black",
                  "&:hover": { backgroundColor: "rgba(255,255,255,0.9)" }
                }}
                aria-label="switch camera"
              >
                <FontAwesomeIcon icon={faExchange} />
              </IconButton>
            )}

            {/* Top right - Close button */}
            <IconButton
              onClick={closeOverlay}
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                width: "2rem",
                height: "2rem",
                backgroundColor: showPosterOverlay
                  ? "rgba(255,255,255,0.9)"
                  : "black",
                zIndex: 9999,
                "&:hover": { backgroundColor: "rgba(255,255,255,0.9)" }
              }}
              aria-label="close camera overlay"
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>

            {/* Bottom buttons */}
            {!isLoading && !capturedImageSrc && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "10%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: "flex",
                  gap: 2,
                  justifyContent: "center",
                  backgroundColor: "rgba(0,0,0,0.7)",
                  padding: 2,
                  borderRadius: 2
                }}
              >
                {/* Left side - Upload button */}
                <IconButton
                  onClick={() => fileInputRef.current?.click()}
                  sx={{
                    width: "3rem",
                    height: "3rem",
                    backgroundColor: "black",
                    "&:hover": { backgroundColor: "rgba(255,255,255,0.9)" }
                  }}
                  aria-label="upload image"
                >
                  <FontAwesomeIcon icon={faUpload} />
                </IconButton>

                {/* Center bottom - Camera button */}
                <IconButton
                  onClick={captureImage}
                  sx={{
                    width: "3rem",
                    height: "3rem",
                    backgroundColor: "black",
                    "&:hover": { backgroundColor: "rgba(255,255,255,0.9)" }
                  }}
                  aria-label="capture image"
                >
                  <FontAwesomeIcon icon={faCamera} className="camera-container" />
                </IconButton>
              </Box>
            )}
          </Box>

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept="image/*"
            style={{ display: "none" }}
          />

          <canvas
            ref={canvasRef}
            width="640"
            height="480"
            style={{ display: "none" }}
          />

          {error && (
            <Alert
              severity="error"
              style={{ position: "absolute", bottom: 16, left: 16, right: 16 }}
            >
              {error}
            </Alert>
          )}
        </Box>
      </Modal>

      {showPosterOverlay && posterPath && tmdbResult && (
        <Modal
          open={showPosterOverlay}
          onClose={() => setShowPosterOverlay(false)}
          aria-labelledby="poster-modal"
          aria-describedby="movie-poster-overlay"
        >
          <AnimatedBox
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden"
            }}
          >
            <IconButton
              onClick={closeOverlay}
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                width: "2rem",
                height: "2rem",
                backgroundColor: showPosterOverlay
                  ? "rgba(255,255,255,0.9)"
                  : "black",
                zIndex: 9999,
                "&:hover": { backgroundColor: "rgba(255,255,255,0.9)" }
              }}
              aria-label="close camera overlay"
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
            <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
              <Box
                component="img"
                src={`https://image.tmdb.org/t/p/original${posterPath}`}
                alt="Movie Poster"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  opacity: 0,
                  animation: "fadeIn 0.5s ease-in-out 0.5s forwards"
                }}
              />
              <AnimatedBox
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background:
                    "linear-gradient(to top, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.7) 70%, rgba(0,0,0,0) 100%)",
                  padding: "4rem 1rem 1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                {tmdbResult && (
                  <Box sx={{ width: "100%", maxWidth: "600px" }}>
                    <Typography
                      variant="h4"
                      color="white"
                      sx={{
                        fontWeight: 900,
                        textAlign: "left",
                        mb: 2,
                        animation: "slideUp 0.5s ease-out 0.2s forwards"
                      }}
                    >
                      {tmdbResult.title} (
                      {new Date(
                        tmdbResult.release_date ||
                        tmdbResult.first_air_date ||
                        ""
                      ).getFullYear()}
                      )
                    </Typography>
                    {tmdbResult.vote_average && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          opacity: 0,
                          animation: "fadeIn 0.5s ease-in-out 0.7s forwards",
                          mb: 2.5
                        }}
                      >
                        <Typography variant="h6" color="white" sx={{ mr: 1 }}>
                          {/* Rating */}
                        </Typography>
                        <Box sx={{ transform: "scale(0.95)" }}>
                          <StarRating rating={Number(tmdbResult.vote_average)} />
                        </Box>
                        <Typography
                          variant="body2"
                          color="gray"
                          sx={{ ml: 1 }}
                        >
                          100K votes
                        </Typography>
                      </Box>
                    )}
                    <Button
                      variant="contained"
                      onClick={() => {
                        const button =
                          document.getElementById("summon-button");
                        if (button) {
                          button.style.animation = `${summonEffect} 0.5s ease-out forwards`;
                        }
                        setTimeout(() => handleNavigateChat(tmdbResult), 400);
                      }}
                      id="summon-button"
                      startIcon={<Chat />}
                      sx={{
                        mt: 1,
                        backgroundColor: "#FF8A00",
                        color: "white",
                        fontWeight: "bold",
                        borderRadius: "20px",
                        padding: "10px 20px",
                        opacity: 1,
                        animation: "fadeIn 0.5s ease-in-out 0.9s forwards",
                        width: "100%",
                        maxWidth: "300px",
                        margin: "0 auto",
                        display: "flex",
                        marginBottom: "20px",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          backgroundColor: "#FF9A20",
                          transform: "scale(1.02)"
                        },
                        "& .MuiButton-startIcon": {
                          marginRight: "8px"
                        }
                      }}
                    >
                      SUMMON CHARACTERS
                    </Button>
                  </Box>
                )}
              </AnimatedBox>
            </Box>
          </AnimatedBox>
        </Modal>
      )}
    </>
  );
};

export default CameraCaptureSearch;
