import React from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { getWithoutExpiry } from "../../helper/storageUtils";
import "../../css/menuBar.css";
import { Box, styled } from '@mui/material';

const StyledLink = styled(RouterLink)<{ isactive: string }>(({ theme, isactive }) => ({
  color: 'white',
  textDecoration: isactive === 'true' ? 'none' : 'none', // Prevent default underline
  fontSize: '14px', // Reduced from 16px
  fontWeight: isactive === 'true' ? 600 : 400,
  position: 'relative',
  '&::after': isactive === 'true' ? {
    content: '""',
    position: 'absolute',
    bottom: -8,
    left: 0,
    right: -8,
    height: '2px',
    backgroundColor: '#FF9500',
    borderRadius: '2px'
  } : undefined
}));

const TopMenuBar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const messages = getWithoutExpiry("messages") ? JSON.parse(getWithoutExpiry("messages") as string) : {};

  const historyToShow = Object.entries(messages)
    .map(([character, characterMessages]) => {
      const msgs = characterMessages as any[];
      const lastMessage = msgs[msgs.length - 1];
      return {
        character,
        lastMessageTime: lastMessage?.timestamp,
        profileImage: lastMessage?.profileImage || `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`
      };
    });

  const chatState = {
    chat_type: "history",
    movie: {
      title: "Recent Chats",
      cast: historyToShow.map(({ character, lastMessageTime, profileImage }) => ({
        name: character,
        profile_path: profileImage,
        character: character,
        from_movie: "Unknown",
        lastInteractionTimestamp: lastMessageTime,
        lastMessageTime,
        profileImage,
        isFromMessages: true
      })),
      poster_path: "",
      id: 0,
      adult: false,
      backdrop_path: "",
      genre_ids: [],
      original_language: "",
      original_title: "",
      overview: "",
      popularity: 0,
      release_date: "",
      video: false,
      vote_average: 0,
      vote_count: 0,
      random_index: 0,
      source: "messages"
    },
    cast: historyToShow.map(({ character, lastMessageTime, profileImage }) => ({
      name: character,
      profile_path: profileImage,
      character: character,
      from_movie: "Unknown",
      lastInteractionTimestamp: lastMessageTime,
      lastMessageTime,
      profileImage,
      isFromMessages: true
    }))
  };

  return (
    <div className="top-menu-bar">
      <button
        className="back-button"
        onClick={() => navigate(-1)}
        style={{ background: "transparent" }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ color: "orange" }} />
      </button>

      <StyledLink
        to="/chat"
        state={chatState}
        className={`top-menu-item ${location.pathname.startsWith("/chat") ? "active-link" : ""}`}
        isactive={location.pathname.startsWith("/chat") ? "true" : "false"}
      >
        <span>Cast</span>
      </StyledLink>

      <StyledLink
        to="/scenario"
        className={`top-menu-item ${location.pathname === "/scenario" ? "active-link" : ""}`}
        isactive={location.pathname === "/scenario" ? "true" : "false"}
      >
        <span>Scenario</span>
        <img
          className="soon"
          src={`${process.env.PUBLIC_URL}/new.svg`}
          alt="new-badge"
        />
        <span className="soon-text" style={{ color: '#FF9500' }}>NEW</span>
      </StyledLink>

      <StyledLink
        to="/shop"
        className={`top-menu-item disabled-link ${location.pathname === "/shop" ? "active-link" : ""}`}
        isactive={location.pathname === "/shop" ? "true" : "false"}
      >
        <span>Shop</span>
        <img
          className="soon"
          src={`${process.env.PUBLIC_URL}/soon.svg`}
          alt="soon-svg"
        />
        <span className="soon-text">SOON</span>
      </StyledLink>
    </div>
  );
};

export default TopMenuBar;