import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';

interface Review {
  text: string;
  upvotes: number;
  username: string;
}

interface ReviewCarouselProps {
  reviews: Review[];
}

export function ReviewCarousel({ reviews }: ReviewCarouselProps) {
  const [visibleReviews, setVisibleReviews] = useState<Review[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized && reviews.length > 0) {
      setVisibleReviews(reviews.slice(0, 3));
      setIsInitialized(true);
    }
  }, [reviews, isInitialized]);

  useEffect(() => {
    if (!containerRef.current) return;

    const interval = setInterval(() => {
      setVisibleReviews(prev => {
        const nextReview = reviews[(reviews.indexOf(prev[prev.length - 1]) + 1) % reviews.length];
        return [...prev.slice(1), nextReview];
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [reviews]);

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        maxWidth: '320px',
        height: '360px',
        position: 'relative',
        mx: 'auto',
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <AnimatePresence mode="popLayout">
        {visibleReviews.map((review, index) => (
          <motion.div
            key={review.text}
            initial={{ opacity: 0, y: 20 }}
            animate={{
              opacity: 1,
              y: index * 120,
              scale: 1 - index * 0.05,
              filter: `brightness(${1 - index * 0.15})`
            }}
            exit={{ opacity: 0, y: -20 }}
            transition={{
              type: 'spring',
              stiffness: 300,
              damping: 30,
              opacity: { duration: 0.2 }
            }}
            style={{
              position: 'absolute',
              width: '100%',
              padding: '16px',
              borderRadius: '16px',
              background: 'rgba(255, 255, 255, 0.05)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
              overflow: 'hidden',
              fontSize: '14px',
              lineHeight: 1.5,
              color: '#fff',
              textAlign: 'center'
            }}
          >
            <div style={{ 
              marginBottom: '12px',
              paddingRight: '24px'
            }}>
              {review.text}
            </div>
            <div style={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              gap: '12px',
              fontSize: '12px',
              color: 'rgba(255, 255, 255, 0.5)'
            }}>
              <span style={{ fontWeight: 500 }}>u/{review.username}</span>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <span style={{ color: '#FF4500' }}>↑</span>
                {review.upvotes}
              </div>
            </div>
            <div style={{
              position: 'absolute',
              top: 16,
              right: 16,
              opacity: 0.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <img
                src="/reddit-logo.svg"
                alt="Reddit"
                style={{
                  height: '16px',
                  width: 'auto'
                }}
              />
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </Box>
  );
} 