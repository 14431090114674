import { API_URL } from "../config";
import { getObjectFromLocal, getWithoutExpiry, setWithoutExpiry } from "../helper/storageUtils";
import { User } from "../types/UserType";
import axios, { AxiosInstance } from "axios";
import { User as FirebaseUser } from "@firebase/auth";
import { supabase } from "../helper/supabase_client";
import { Session } from "@supabase/supabase-js";
import * as Sentry from "@sentry/react";

const api: AxiosInstance = axios.create({
  baseURL: `${API_URL}/api`
});

api.defaults.withCredentials = true;

api.interceptors.request.use(
  config => {
    const userId = getWithoutExpiry("User_identifier");
    if (userId) {
      config.headers["User_identifier"] = userId;
    }
    config.headers["ngrok-skip-browser-warning"] = "True";
    return config;
  },
  error => Promise.reject(error)
);

async function getAuthorizationHeader() {
  try {
    // Try to get current Supabase session first
    const { data: { session } } = await supabase.auth.getSession();
    if (session?.access_token) {
      return `Bearer ${session.access_token}`;
    }

    const supabaseObject = JSON.parse(
      getObjectFromLocal("sb-vcsqhuxpigrhqgausqit-auth-token") || "{}"
    );

    const access_token = supabaseObject.access_token || getWithoutExpiry("access_token") || "";
    return `Bearer ${access_token}`;
  } catch (error) {
    Sentry.captureException(error);
    console.error("Error getting authorization header:", error);
    return "Bearer ";
  }
}

export const login = (email: string, password: string) => {
  return api.post("/token", { username: email, password });
};

export const loginSimple = (email: string) => {
  return api.post("/login", { username: email });
};

export const validateOTP = (email: string, password: string) => {
  return api.post(`/verify-otp/${email}`, { otp: password });
};

export const getUser = async (): Promise<User> => {
  try {
    const response = await axios.get(API_URL + "/api/users/me/new", {
      headers: {
        "X-Auth-Provider": "SUPABASE",
        Authorization: await getAuthorizationHeader()
      }
    });
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw error;
  }
};

export const applyRetroactiveReferral = async (referralCode: string): Promise<any> => {
  try {
    const response = await api.post("/retroactive-referral", { referral_code: referralCode }, {
      headers: {
        Authorization: await getAuthorizationHeader()
      }
    });
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw error;
  }
};

export const sendReferralCode = async (emailList: string): Promise<any> => {
  try {
    const response = await api.get("/send-referral-code", {
      headers: {
        Authorization: await getAuthorizationHeader()
      },
      params: { email_list: emailList }
    });
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw error;
  }
};


export const getUserForFirebase = async (user: FirebaseUser): Promise<User> => {
  try {
    const unwrappedUser = user.toJSON();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const accessToken = unwrappedUser?.stsTokenManager?.accessToken;
    const response = await api.post("/users/me/firebase", { accessToken }, {
      headers: {
        Authorization: await getAuthorizationHeader()
      }
    });
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw error;
  }
};

export const getUserForSupabase = async (): Promise<User> => {
  try {
    const supabaseObject = JSON.parse(
      getObjectFromLocal("sb-vcsqhuxpigrhqgausqit-auth-token")
    );
    const access_token = supabaseObject.access_token;
    const response = await axios.post(
      API_URL + "/api/users/me/supabase",
      { accessToken: access_token },
      {
        headers: {
          "X-Auth-Provider": "SUPABASE",
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`
        }
      }
    );
    setWithoutExpiry("access_token", response.data.access_token);
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    const error = err as AxiosError;
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw error;
  }
};

export const getUserForSupabaseSession = async (session: any): Promise<User> => {
  try {
    // Ensure that the session and access_token are available
    if (!session || !session.access_token) {
      throw new Error("No session or access token available");
    }

    const access_token = session.access_token;

    // Make the API request to fetch user data
    const response = await axios.post(
      `${API_URL}/api/users/me/supabase`,
      { accessToken: access_token },
      {
        headers: {
          "X-Auth-Provider": "SUPABASE",
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      }
    );

    // Store the new access token if provided
    if (response.data.access_token) {
      setWithoutExpiry("access_token", response.data.access_token);
    }

    return response.data as User;
  } catch (err) {
    Sentry.captureException(err);
    const error = err as AxiosError;
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    console.error("Error fetching user for session:", error);
    throw error;
  }
};

export const createAccount = (email: string, password: string) => {
  return api.post("/create-account", { email, password });
};

export const newLogin = (email: string, password: string | null, otp: string | null) => {
  const data = {
    email: email || null,
    password: password || null,
    otp: otp || null
  };
  return api.post("/new_auth_route", data);
};

export const getToken = (username: string, password: string, on_the_fly = false) => {
  const data = on_the_fly ? { on_the_fly: true } : { username, password };
  return api.post("/token", data);
};

export const getUserDetails = async (session: Session | null) => {
  const response = await api.get("/users/me/details", {
    headers: {
      Authorization: getAuthHeader(session)
    }
  });
  return response.data;
};

export const updateUserDetails = async (session: Session | null, details: any) => {
  const response = await api.put("/users/me/details", details, {
    headers: {
      Authorization: getAuthHeader(session)
    }
  });
  return response.data;
};

export interface NotificationsConfig {
  daily_notifications: boolean;
  // movie_notifications: boolean;
  // anime_notifications: boolean;
  // series_notifications: boolean;
  // actors_notifications: boolean;
}

export interface ConfigUpdate {
  config: Record<string, any>;
  notifications: NotificationsConfig;
}

const getAuthHeader = (session: Session | null) => {
  return session?.access_token ? `Bearer ${session.access_token}` : "";
};

export const getUserConfig = async (session: Session | null): Promise<{ config: Record<string, any> }> => {
  try {
    const response = await api.get("/users/config", {
      headers: {
        Authorization: getAuthHeader(session)
      }
    });
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw error;
  }
};

export const updateUserConfig = async (session: Session | null, configUpdate: ConfigUpdate): Promise<{
  message: string;
  config: Record<string, any>
}> => {
  try {
    const response = await api.patch("/users/config", configUpdate, {
      headers: {
        Authorization: getAuthHeader(session)
      }
    });
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    if (error.response && error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw error;
  }
};