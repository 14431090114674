import React from 'react';
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const FAQRoot = styled(Box)(({ theme }) => ({
  backgroundColor: '#1C1C1C',
  padding: theme.spacing(8, 0),
  minHeight: '100vh',
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'rgba(28, 28, 28, 0.95)',
  backdropFilter: 'blur(20px)',
  WebkitBackdropFilter: 'blur(20px)',
  color: 'white',
  borderRadius: '12px !important',
  marginBottom: theme.spacing(2),
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root': {
    padding: theme.spacing(2, 3),
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(2, 3),
    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: 'white',
  marginBottom: theme.spacing(6),
  textAlign: 'center',
  fontSize: '48px',
  fontWeight: 700,
  background: 'linear-gradient(to right, #FFB6C1, #FFD700)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));

const faqData = [
  {
    question: "What should I do if I can't find a movie/show?",
    answer: "If you can't find a specific movie or show, try searching with different keywords or check the spelling. If it's still not available, you can request it through our support channel on Discord.",
  },
  {
    question: "What if the Microphone for the voice chat doesn't work?",
    answer: "First, ensure your browser has microphone permissions enabled. Check your system's audio settings and make sure the correct input device is selected. If issues persist, try refreshing the page or using a different browser.",
  },
  {
    question: "Why should I log in via Google?",
    answer: "Logging in with Google provides a secure and convenient way to access your account. It enables features like saving your chat history, syncing your preferences across devices, and accessing premium features if you're a MAX subscriber.",
  },
  {
    question: "Whats the plus button on the left of the chat?",
    answer: "The plus button allows you to create a new chat session or add more characters to your current conversation. It's a quick way to start fresh conversations or enhance your existing ones with more participants.",
  },
  {
    question: "What are some features I might be missing out on?",
    answer: "Some of our premium features include unlimited character interactions, voice chat capabilities, custom character creation, and advanced conversation settings. Upgrade to MAX to unlock all these features!",
  },
  {
    question: "When is the Mobile App coming?",
    answer: "Our mobile app is currently in development. While we don't have an exact release date, we're working hard to bring you a native mobile experience. Stay tuned to our social media channels for updates!",
  },
  {
    question: "What do I do if the search takes too long?",
    answer: "If the search is taking longer than usual, try refreshing the page. If the issue persists, check your internet connection or try clearing your browser cache. You can also report slow performance to our support team.",
  },
];

export const FAQ: React.FC = () => {
  return (
    <FAQRoot>
      <Container maxWidth="lg">
        <Title variant="h1">
          Frequently Asked Questions
        </Title>
        <Box>
          {faqData.map((faq, index) => (
            <StyledAccordion key={index}>
              <AccordionSummary
                expandIcon={<AddIcon sx={{ color: 'white' }} />}
              >
                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </StyledAccordion>
          ))}
        </Box>
      </Container>
    </FAQRoot>
  );
}; 