import React, { useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faArrowUpFromBracket, faSearch } from '@fortawesome/free-solid-svg-icons';
import '../../assets/css/character-profile.css';
import useIsMobile from "../hooks/useIsMobile";
import { toast } from "react-toastify";

interface CharacterProfileProps {
  isOpen: boolean;
  onClose: () => void;
  characterData: {
    char_name: string;
    content_type: string;
    source_title: string;
    userDescription: string;
    aiDescription: string;
    chatStarters: string[];
    profileImage?: string;
  };
}

export const CharacterProfile: React.FC<CharacterProfileProps> = ({
  isOpen,
  onClose,
  characterData
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const startY = useRef<number>(0);
  const isMobile = useIsMobile();


  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const handleTouchStart = (e: React.TouchEvent) => {
    startY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const deltaY = e.touches[0].clientY - startY.current;
    if (deltaY > 0) {
      if (modalRef.current) {
        modalRef.current.style.transform = `translateY(${deltaY}px)`;
      }
    }
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    const deltaY = e.changedTouches[0].clientY - startY.current;
    if (deltaY > 100) {
      onClose();
    } else {
      if (modalRef.current) {
        modalRef.current.style.transform = '';
      }
    }
  };

  const handleMessageClick = () => {
    onClose();
    // If you have chat navigation, you can add it here
  };

  const dummyDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";
  
  const dummyPersonality = "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
  
  const dummyStarters = [
    "Tell me about your most challenging adventure...",
    "What's your biggest regret and why?",
    "How did you first discover your special abilities?",
    "What's the one thing nobody knows about you?",
    "If you could change one decision in your past..."
  ];

  const handleShare = async () => {
    const currentUrl = window.location.href;

    try {
      if (isMobile && navigator.share) {
        // Mobile native share
        await navigator.share({
          title: characterData.char_name,
          text: `${characterData.userDescription}\n\n`,
          url: currentUrl,
        });
      } else {
        // Desktop clipboard copy
        await navigator.clipboard.writeText(currentUrl);
        toast.success("Profile link copied to clipboard!");
      }
    } catch (err) {
      if (err instanceof Error && err.name === 'AbortError') return;

      console.error('Sharing failed:', err);
      await navigator.clipboard.writeText(currentUrl);
      toast.success("Profile link copied to clipboard!");
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="char-profile-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          <motion.div
            ref={modalRef}
            className="char-profile-modal"
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{ type: 'spring', damping: 30, stiffness: 300 }}
            onClick={e => e.stopPropagation()}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div className="char-profile-handle" />
            
            <div className="char-profile-grid">
              <div className="char-profile-header">
                <div className="char-profile-image-container">
                  <img 
                    src={characterData.profileImage || '/default-avatar.png'} 
                    alt={characterData.char_name}
                    className="char-profile-image"
                  />
                </div>
                <div className="char-profile-info">
                  <h1 className="char-profile-name">{characterData.char_name}</h1>
                  <p className="char-profile-source">{characterData.source_title}</p>
                  <div className="scenextras-badge">
                    <span className="scenextras-dot">●</span>
                    Content by SceneXtras AI
                  </div>
                </div>
                <button className="char-share-btn" onClick={handleShare}>
                  <FontAwesomeIcon icon={faArrowUpFromBracket} />
                </button>
              </div>

              <div className="char-profile-actions">
                <button className="char-action-btn" onClick={handleMessageClick}>
                  <FontAwesomeIcon icon={faMessage} />
                  <span>Message</span>
                </button>
                <button className="char-action-btn">
                  <FontAwesomeIcon icon={faSearch} />
                  <span>Search</span>
                </button>
              </div>

              <div className="char-profile-content">
                <div className="char-profile-section">
                  <div className="char-section-header">About</div>
                  <div className="char-section-content">
                    {characterData.userDescription || dummyDescription}
                  </div>
                </div>

                <div className="char-profile-section">
                  <div className="char-section-header">Personality</div>
                  <div className="char-section-content">
                    {characterData.aiDescription || dummyPersonality}
                  </div>
                </div>

                <div className="char-profile-section">
                  <div className="char-section-header">Chat Starters</div>
                  <div className="char-starters-list">
                    {(characterData.chatStarters.length > 0 ? characterData.chatStarters : dummyStarters).map((starter, index) => (
                      <div key={index} className="char-starter-item">
                        {starter}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}; 