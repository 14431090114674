import React from "react";
import { OnboardingForm } from "../onboarding/OnboardingForm";
import "../../css/UserDetailsModal.css";
import { User } from "../../types/UserType";

interface UserDetailsModalProps {
  onClose?: () => void;
  session?: any;
  isOnboarded?: boolean;
  initialDetails?: any;
  setInitialDetails?: (details: any) => void;
  onComplete?: () => void;
  user?: User;
  setIsLoading: (loading: boolean) => void;
}

export function UserDetailsModal({
  onClose,
  session,
  isOnboarded,
  initialDetails,
  setInitialDetails,
  onComplete,
  user,
  setIsLoading
}: UserDetailsModalProps) {
  return (
    <div className="onboarding-modal">
      <OnboardingForm
        session={session}
        isOnboarded={isOnboarded}
        initialDetails={initialDetails}
        setInitialDetails={setInitialDetails}
        user={user}
        setIsLoading={setIsLoading}
        onComplete={() => {
          onComplete?.();
          onClose?.();
        }}
      />
    </div>
  );
}

export default UserDetailsModal;