import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AppBar,
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Container,
  createTheme,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  styled,
  ThemeProvider,
  Toolbar,
  Typography,
  TypographyProps
} from "@mui/material";
import {
  ArrowBack,
  Facebook,
  Favorite,
  FavoriteBorderOutlined,
  Link as LinkIcon,
  RemoveRedEyeOutlined,
  Share as ShareIcon,
  ShareOutlined,
  Twitter,
  WhatsApp
} from "@mui/icons-material";
import { AuthenticatedUser, convertAuthenticatedUserToUser } from "../../helper/authHelper";
import {
  getConversationTitle,
  getEngagementStats,
  handleApiFetch,
  handleApiSave,
  incrementLike,
  incrementShare
} from "../../api/apiClient";
import { toast } from "react-toastify";
import { getWithoutExpiry } from "../../helper/storageUtils";
import * as Sentry from "@sentry/react";
import { css, Global } from "@emotion/react";
import { Movie } from "../../types/MovieType";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";
import html2canvas from "html2canvas";
import log from "loglevel";
import Waveform from "./Waveform";
import ShareContent from "./ShareContent";
import useIsMobile from "../hooks/useIsMobile";
import LockIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";
import CheckoutModal from "../payments/CheckoutModal";
import { useAuthStore } from "../../stores/useAuthStore";


gsap.registerPlugin(ScrollTrigger);

interface ChatExportProps {
  user?: AuthenticatedUser;
}

interface ChatMessageProps extends BoxProps {
  isUser?: boolean;
}

// Add this type near your other interfaces
interface CTAButtonProps {
  href: string;
  children: React.ReactNode;
}

// Add these at the top of the file
interface Message {
  sender: "user" | "character";
  content: string;
  profileImage?: string;
  prefixed_audio_filename?: string;
}

export interface ExportData {
  character: string;
  profileImage?: string;
  messages: Message[];
  movie?: Movie;
  backgroundImage?: string;
  user_name?: string;
  title?: string;
  movieTitle?: string;
}

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#000000"
    },
    text: {
      primary: "#ffffff"
    }
  },
  typography: {
    fontFamily: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Oxygen, Ubuntu, Cantarell, \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif"
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "black"
        }
      }
    }
  }
});

const ChatMessagesContainer = styled(Box)(({ theme }) => ({
  accentColor: "auto",
  alignContent: "normal",
  alignItems: "normal",
  alignSelf: "auto",
  backgroundAttachment: "scroll",
  backgroundColor: "rgba(0, 0, 0, 0)",
  backgroundImage: "none",
  backgroundPosition: "0% 0%",
  backgroundRepeat: "repeat",
  backgroundSize: "auto",
  blockSize: "calc(100vh - 200px)",
  boxSizing: "border-box",
  caretColor: "rgb(255, 255, 255)",
  color: "rgb(255, 255, 255)",
  columnGap: "normal",
  display: "block",
  fontFamily: theme.typography.fontFamily,
  fontSize: "16px",
  fontWeight: 400,
  height: "calc(100vh - 200px)",
  inlineSize: "100%",
  lineHeight: "normal",
  overflowX: "hidden",
  overflowY: "auto",
  paddingBlockStart: "16.84px",
  paddingInlineStart: "8.42px",
  paddingLeft: "8.42px",
  paddingTop: "16.84px",
  position: "relative",
  rowGap: "normal",
  tabSize: 8,
  textAlign: "center",
  width: "100%",
  zIndex: "auto"
}));

const ChatMessage = React.forwardRef<HTMLDivElement, ChatMessageProps>(
  function ChatMessage({ isUser, children, ...props }, ref) {
    return (
      <StyledChatMessage isUser={isUser} ref={ref} {...props}>
        <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
          {children}
        </Typography>
      </StyledChatMessage>
    );
  }
);

type MessageType = {
  sender: string;
  content: string;
  audio?: string;
  transcript?: string;
  profileImage?: string;
  prefixed_audio_filename?: string;
};

const AudioMessageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column", // Change to column layout
  maxWidth: "450px",
  margin: "0 auto",
  padding: "0.5rem 0"
});

const AudioControlsWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  width: "100%"
});


const TranscriptButton = styled(IconButton)({
  padding: "4px 8px",
  minWidth: "auto",
  height: "36px",
  color: "inherit",
  border: "1px solid #ccc",
  borderRadius: "4px",
  marginLeft: "8px",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)"
  }
});

const Transcript = styled(Typography)({
  margin: "0.5rem 0",
  padding: "0.5rem",
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  borderRadius: "4px",
  width: "100%"
});

const StyledChatMessage = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isUser"
})<ChatMessageProps>(({ theme, isUser }) => ({
  accentColor: "auto",
  alignItems: "flex-start",
  alignSelf: isUser ? "flex-end" : "flex-start",
  animation: "slideInFromBottom 0.5s forwards",
  backgroundColor: isUser ? "rgba(220, 247, 197, 0.9)" : "rgba(250, 250, 250, 0.953)",
  borderRadius: "12.63px",
  boxShadow: isUser ? "rgba(0, 0, 0, 0.1) 0px 4px 6px 0px" : "rgba(0, 0, 0, 0.075) 0px 0px 21.892px 0px",
  boxSizing: "border-box",
  caretColor: "rgb(51, 51, 51)",
  color: "rgb(51, 51, 51)",
  display: "flex",
  flexDirection: "column",
  fontFamily: "Inter-Medium, sans-serif",
  fontSize: "15.156px",
  fontWeight: 400,
  margin: "0 0 0px 0",
  maxWidth: "70%", // Limit the width of the message
  marginLeft: isUser ? "0" : "0",
  marginRight: isUser ? "10px" : "0",
  padding: theme.spacing(1, 1.5),
  position: "relative",
  transform: "matrix(1, 0, 0, 1, 0, 0)",

  "& .MuiTypography-root": {
    fontSize: "0.75rem",
    lineHeight: 1.4
  }
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: "transparent", // Remove background
  boxShadow: "none" // Remove default shadow
  // ... other existing styles
}));

const ProfileImage = styled("img")({
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  marginRight: "10px",
  marginLeft: "10px",
  objectFit: "cover",
  alignSelf: "flex-end"
});

function getProfileImageUrl(character: { profile_path?: string, name: string }) {
  if (!character.profile_path) {
    return `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`;
  }

  if (character.profile_path.includes("/static/media/")) {
    return character.profile_path;
  }

  if (character.profile_path.startsWith("https")) {
    return character.profile_path;
  }

  return `https://image.tmdb.org/t/p/w500${character.profile_path}`;
}

const globalStyles = css`
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    * {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
    }

    .glow-filter {
        position: relative;
        display: inline-block;
        scale: 1;
        animation: onloadscale 1s ease-out forwards;
    }

    .glow-filter::before {
        content: attr(data-text);
        position: absolute;
        pointer-events: none;
        color: #fffaf6;
        background: linear-gradient(0deg, #dfe5ee 0%, #fffaf6 50%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: url(#glow-4);
        -webkit-filter: url(#glow-4);
        opacity: 0;
        animation: onloadopacity 1s ease-out forwards;
        text-shadow: 0 0 8px rgba(255, 255, 255, 0.6), /* Existing glow */ 0 1px 3px rgba(0, 0, 0, 0.5); /* Subtle dark drop shadow */
    }

    @keyframes onloadscale {
        24% {
            scale: 1;
        }
        100% {
            scale: 1.02;
        }
    }

    @keyframes onloadopacity {
        24% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const IlluminatedText = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  textShadow: `
    0 0 10px rgba(255, 255, 255, 0.8),
    0 1px 3px rgba(0, 0, 0, 0.5)
  `,
  opacity: 0.90,
  fontSize: "1.3em",
  textAlign: "center",
  lineHeight: 1.0625,
  fontWeight: 600,
  letterSpacing: "-0.009em",
  paddingTop: "10px",
  paddingBottom: "20px",
  position: "relative",
  top: "-10px",
  "& .glow-filter": {
    fontSize: "0.75em", // Made slightly smaller to accommodate the extra word
    color: theme.palette.text.disabled,
    textShadow: "0 1px 3px rgba(0, 0, 0, 0.4)"
  },
  mb: 0, // Changed from marginBottom to mb
  pb: 0  // Changed from paddingBottom to pb
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  background: "transparent" // Remove background
  // ... other existing styles
}));


const HeaderText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: "bold",
  textShadow: "0 1px 3px rgba(0, 0, 0, 0.5)"
}));

const IconButtonWithShadow = styled(IconButton)(({ theme }) => ({
  filter: "drop-shadow(0 1px 3px rgba(0, 0, 0, 0.5))" // Add drop shadow to buttons
}));

// Add this with the other styled components
const ChatMessageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isUser"
})<ChatMessageProps>(({ isUser }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  marginBottom: "8px",
  justifyContent: isUser ? "flex-end" : "flex-start",
  width: "100%",
  transform: "translateZ(0)",
  willChange: "transform"
}));

const MainContainer = styled("div")<{ backgroundUrl?: string }>(({ backgroundUrl }) => ({
  minHeight: "100vh",
  width: "100%",
  position: "relative",
  backgroundColor: "rgba(0, 0, 0, 0.1)", // Semi-transparent background
  zIndex: 20,
  overflow: "hidden",
  "&::before": {
    content: "\"\"",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
    filter: "blur(2px)", // Apply blur effect
    opacity: 0.3,
    zIndex: 0,
    pointerEvents: "none"
  }
}));

// First, let's modify the ContentContainer styling to ensure proper layout
const ContentContainer = styled(Box)({
  position: "relative",
  zIndex: 2,  // Ensure it's above the background
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
});

function getBackdropUrl(movie: { backdrop_path?: string, poster_path?: string }) {
  if (!movie.backdrop_path && !movie.poster_path) {
    return null;
  }

  const path = movie.backdrop_path || movie.poster_path;
  return `https://image.tmdb.org/t/p/original${path}`;
}

function formatMessageContent(content: string): JSX.Element {
  const parts = content.split(/(\*.*?\*)/g); // Split by action lines
  return (
    <>
      {parts.map((part, index) => {
        if (part.startsWith("*") && part.endsWith("*")) {
          return (
            <div key={index} style={{ fontStyle: "italic", fontWeight: "bold" }}>
              {part.slice(1, -1)}
            </div>
          );
        }
        return <span key={index}>{part}</span>;
      })}
    </>
  );
}

// Update the CTAButton definition
const CTAButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "href"
})<CTAButtonProps>(({ theme }) => ({
  position: "fixed",
  bottom: "5vh",
  left: "50%",
  transform: "translateX(-50%)",
  backgroundColor: "rgba(250, 250, 250, 0.953)",
  color: "#333",
  padding: "8px 16px",
  borderRadius: "100px",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "1.5",
  whiteSpace: "nowrap",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15), 0 0 8px rgba(0, 0, 0, 0.1)", // Enhanced shadow
  zIndex: 9999,
  transition: "all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(250, 250, 250, 0.98)",
    transform: "translateX(-50%) scale(1.02)",
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2), 0 0 12px rgba(0, 0, 0, 0.15)" // Enhanced hover shadow
  },
  "&:active": {
    transform: "translateX(-50%) scale(0.98)",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.1)" // Reduced shadow on press
  }
}));

interface EngagementMetricsProps {
  metrics: {
    views: number;
    shares: number;
    upvotes: number;
  };
}


interface EngagementMetricsProps {
  metrics: {
    views: number;
    shares: number;
    upvotes: number;
  };
  exportId: string;
  exportData: ExportData;
}

interface EngagementResponse {
  success: boolean;
  stats: {
    views: number;
    shares: number;
    likes: number;
  };
  message: string;
}

// Storage constants
const LIKED_EXPORTS_KEY = "likedExports";
const STORAGE_VERSION = "v1";

interface LikeData {
  timestamp: number;
  version: string;
}

const isExportLiked = (exportId: string): boolean => {
  try {
    const likes = JSON.parse(localStorage.getItem(LIKED_EXPORTS_KEY) || "{}");
    return !!likes[exportId];
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
};

const storeLike = (exportId: string) => {
  try {
    const likes = JSON.parse(localStorage.getItem(LIKED_EXPORTS_KEY) || "{}");
    likes[exportId] = {
      timestamp: Date.now(),
      version: STORAGE_VERSION
    };
    localStorage.setItem(LIKED_EXPORTS_KEY, JSON.stringify(likes));
  } catch (error) {
    Sentry.captureException(error);
    log.error("[Engagement] Failed to store like:", error);
  }
};


export const EngagementMetrics: React.FC<EngagementMetricsProps> = ({ metrics, exportId, exportData }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [localMetrics, setLocalMetrics] = useState(metrics);
  const [isLiked, setIsLiked] = useState(() => isExportLiked(exportId));
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (!containerRef.current) return;

    // Initial animation
    gsap.fromTo(containerRef.current,
      { opacity: 0, y: 10 },
      { opacity: 1, y: 0, duration: 0.6, ease: "power2.out" }
    );

    // Fetch initial metrics
    const handleInitialView = async () => {
      try {
        const response = await getEngagementStats(exportId);
        if (response?.success) {
          updateLocalMetrics(response);
        }
      } catch (error) {
        Sentry.captureException(error);
        log.error("[Engagement] Failed to fetch initial stats:", error);
      }
    };

    handleInitialView();
  }, [exportId]);

  const updateLocalMetrics = (response: EngagementResponse) => {
    if (response.success) {
      setLocalMetrics({
        views: response.stats.views,
        shares: response.stats.shares,
        upvotes: response.stats.likes
      });
    }
  };

  const MetricBox = ({ icon, count, onClick, className }: {
    icon: React.ReactNode;
    count: number;
    onClick?: () => void;
    className?: string;
  }) => (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        color: "rgba(255,255,255,0.6)",
        fontSize: "0.75rem",
        cursor: onClick ? "pointer" : "default",
        position: "relative",
        zIndex: 11,
        transition: "all 0.2s ease",
        "&:hover": onClick ? {
          color: "rgba(255,255,255,0.9)",
          transform: "scale(1.05)"
        } : {},
        pointerEvents: isUpdating ? "none" : "auto"
      }}
      className={className}
    >
      {icon}
      <span className="metric-number">{count}</span>
    </Box>
  );

  const convertImageToFile = async (imageUrl: string): Promise<File | null> => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new File([blob], "share-image.png", { type: "image/png" });
    } catch (error) {
      log.error("[Share] Failed to convert image:", error);
      return null;
    }
  };

  const handleShare = async (exportData: ExportData) => {
    if (isUpdating) return;
    setIsUpdating(true);
    log.debug("[Engagement] Processing share for:", exportId);

    try {
      const response = await incrementShare(exportId);
      if (response?.success) {
        updateLocalMetrics(response);

        // Convert image to file
        const imageFile = await convertImageToFile("https://framerusercontent.com/images/UmquxddrKGqzssYdUuImxMvBvk.png");

        // Prepare share data with image
        const shareData: ShareData & { files?: File[] } = {
          title: `Check out my chat with ${exportData?.character}!`,
          url: window.location.href,
          files: imageFile ? [imageFile] : []
        };

        // Check if we can share with files
        if (navigator.share &&
          navigator.canShare &&
          navigator.canShare(shareData)) {
          await navigator.share(shareData);
        } else {
          // Fallback to basic share if file sharing is not supported
          const basicShareData = {
            title: shareData.title,
            text: shareData.text,
            url: shareData.url
          };

          if (navigator.share) {
            await navigator.share(basicShareData);
          } else {
            await navigator.clipboard.writeText(window.location.href);
            toast.success("Link copied to clipboard!");
          }
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      log.error("[Engagement] Share failed:", error);
      toast.error("Failed to share. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleLike = async () => {
    if (isUpdating || isExportLiked(exportId)) return;
    setIsUpdating(true);
    log.debug("[Engagement] Processing like for:", exportId);

    try {
      const response = await incrementLike(exportId);
      if (response?.success) {
        updateLocalMetrics(response);
        storeLike(exportId);
        setIsLiked(true);

        if (containerRef.current) {
          gsap.to(containerRef.current.querySelector(".like-icon"), {
            scale: 1.2,
            duration: 0.2,
            yoyo: true,
            repeat: 1
          });
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      log.error("[Engagement] Like failed:", error);
      toast.error("Failed to like. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: "2rem",
        py: 0.5,
        mt: -1.5,
        position: "relative",
        zIndex: 10,
        backgroundColor: "transparent",
        width: "100%",
        maxWidth: "500px",
        margin: "0 auto",
        fontSize: "0.75rem",
        opacity: 0.8,
        "&::before, &::after": {
          content: "\"\"",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          width: "80%",
          height: "1px",
          background: "linear-gradient(90deg, transparent, rgba(255,255,255,0.1), transparent)"
        },
        "&::before": { top: 0 },
        "&::after": { bottom: 0 }
      }}
    >
      <MetricBox
        icon={<RemoveRedEyeOutlined sx={{ fontSize: "1rem" }} />}
        count={localMetrics.views}
      />
      <MetricBox
        icon={<ShareOutlined sx={{ fontSize: "1rem" }} />}
        count={localMetrics.shares}
        onClick={() => handleShare(exportData)}
      />
      <MetricBox
        icon={isLiked ?
          <Favorite sx={{ fontSize: "1rem" }} className="like-icon" /> :
          <FavoriteBorderOutlined sx={{ fontSize: "1rem" }} className="like-icon" />
        }
        count={localMetrics.upvotes}
        onClick={handleLike}
        className="like-container"
      />
    </Box>
  );
};


// Add this interface near other interfaces
interface MetaPreviewProps {
  character: string;
  profileImage: string;
  firstMessage: string;
  origin: string;
}

// Add this pure function near other utility functions
function generateMetaPreview({
                               character,
                               profileImage,
                               firstMessage,
                               origin
                             }: MetaPreviewProps) {
  const title = `${character} on sceneXtras AI Fanfiction Chat`;
  const description = firstMessage.length > 160
    ? `${firstMessage.substring(0, 157)}...`
    : firstMessage;

  return {
    title,
    description,
    image: profileImage,
    url: origin,
    siteName: "sceneXtras Inc.",
    handle: "@sceneXtras"
  };
}

// Add haptic feedback utility
function triggerHapticFeedback(style: "light" | "medium" | "heavy" | "soft" | "rigid") {
  // Check if the device supports haptic feedback
  if ("vibrate" in navigator) {
    // iOS-specific haptic feedback
    if ((window as any)?.navigator?.vibrate) {
      switch (style) {
        case "light":
          navigator.vibrate(10);
          break;
        case "medium":
          navigator.vibrate([10, 20]);
          break;
        case "heavy":
          navigator.vibrate([20, 40]);
          break;
        case "soft":
          navigator.vibrate([5, 10]);
          break;
        case "rigid":
          navigator.vibrate([15, 30]);
          break;
      }
    }
  }
}

function ChatExport() {
  const { user, session, signOut, isLoading, setUser } = useAuthStore();

  const [shareType, setShareType] = useState<"secret" | "public">("secret");
  const isMobile = useIsMobile();
  const { exportId } = useParams<{ exportId?: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const chatContentRef = useRef<HTMLDivElement>(null);
  const [exportData, setExportData] = useState<ExportData | null>(null);
  const [loading, setLoading] = useState(true);
  const [shareUrl, setShareUrl] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [title, setTitle] = useState("Words between Worlds");
  const [movieBackdrop, setMovieBackdrop] = useState<string | null>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const messagesContainerRef = useRef<HTMLUListElement>(null);
  const [transcriptVisibility, setTranscriptVisibility] = useState<{ [key: number]: boolean }>({});
  const toggleTranscriptVisibility = (index: number) => {
    setTranscriptVisibility(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };
  const [innerUser, setInnerUser] = useState<AuthenticatedUser | null>(user);
  const handleShareTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShareType(event.target.value as "secret" | "public");
  };


  // Add the save function
  async function saveAndGetShareUrl(): Promise<string> {
    if (shareUrl) return shareUrl;

    if (!exportData) {
      throw new Error("No export data available");
    }

    setIsSaving(true);

    let backgroundImage = "";
    if (exportData.movie) {
      backgroundImage = getImageSrc(exportData.movie);
    }
    let movieTitle = "";
    if (exportData.movie) {
      movieTitle = exportData.movie.title;
    }

    try {
      const dataToSave = {
        title: title || "Words between Worlds",
        backgroundImage: backgroundImage,
        character: exportData.character,
        ...(exportData.profileImage && { profileImage: exportData.profileImage }),
        user_name: getUserName(),
        messages: exportData.messages,
        is_public: shareType === "public" ? "True" : "False",
        movieTitle: movieTitle
      };

      const newExportId = await handleApiSave(dataToSave);
      const newShareUrl = `${window.location.origin}/export/${newExportId}`;
      setShareUrl(newShareUrl);
      navigate(`/export/${newExportId}`, { replace: true });
      return newShareUrl;
    } catch (error: any) {
      Sentry.captureException(error);
      console.error("Failed to save export:", error.response?.data || error);

      if (error.response?.status === 401) {
        toast.error("Please log in to share chats");
      } else {
        toast.error("Failed to generate share link. Please try again.");
      }

      throw error;
    } finally {
      setIsSaving(false);
    }
  }


  useEffect(() => {
    async function fetchOrCreateExport() {
      if (exportId) {
        try {
          const data = await handleApiFetch(exportId);
          setExportData(data);
          setShareUrl(`${window.location.origin}/export/${exportId}`);
        } catch (error) {
          Sentry.captureException(error);
          console.error("Failed to fetch export data:", error);
          toast.error("Failed to load chat export");
        }
      } else if (location.state?.exportData) {
        setExportData(location.state.exportData);
      }
      setLoading(false);
    }

    try {
      if (location.state.origin !== "onboarding") {
        const token = getWithoutExpiry(`X-Session-Token-${location.state.exportData?.character}`);
        const title = getConversationTitle({
          sessionToken: token,
          characterName: location.state.exportData?.character ? location.state.exportData?.character : ""
        }).then((response) => {
          setTitle(response);
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }

    fetchOrCreateExport();
  }, [exportId, location.state]);

  useEffect(() => {
    if (exportData?.movie) {
      const backdropUrl = getBackdropUrl(exportData.movie);
      setMovieBackdrop(backdropUrl);
    }
  }, [exportData]);

  useEffect(() => {
    if (!location.state) return;

    if (location.state.origin === "onboarding") {

      if (!user) {
        setInnerUser(user);
        // console.log("Inner user:", location.state.user);
      }
    }
  }, [location.state]);


  // Update the animation useEffect to properly check refs and elements
  useEffect(() => {
    // Only run animations if all required elements exist
    const backgroundElement = backgroundRef.current;
    const titleElement = titleRef.current;
    const messagesElement = messagesContainerRef.current;
    const glowElements = document.querySelectorAll(".glow-filter");
    const headerElements = document.querySelectorAll(".header-element");
    const ctaButton = document.querySelector(".MuiButton-root");

    if (!backgroundElement || !titleElement || !messagesElement) return;

    const tl = gsap.timeline({
      defaults: {
        ease: "power2.out",
        duration: 0.8
      }
    });

    // Background fade in with subtle haptic
    if (backgroundElement) {
      tl.fromTo(backgroundElement,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1.2,
          onStart: () => triggerHapticFeedback("soft")
        }
      );
    }

    // Title sequence with stronger haptic
    if (titleElement) {
      tl.fromTo(titleElement,
        {
          opacity: 0,
          clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)"
        },
        {
          opacity: 1,
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          duration: 0.8,
          onStart: () => triggerHapticFeedback("medium"),
          onComplete: () => triggerHapticFeedback("light")
        },
        "-=0.4"
      );
    }

    // Glow filter animation with light haptic
    if (glowElements.length) {
      tl.fromTo(glowElements,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.6,
          onStart: () => triggerHapticFeedback("soft")
        },
        "-=0.3"
      );
    }

    // Messages animation with subtle haptics for each message
    const messages = messagesElement.querySelectorAll(".message-item");
    if (messages.length) {
      messages.forEach((message, index) => {
        tl.fromTo(message,
          {
            opacity: 0,
            y: 20,
            scale: 0.95
          },
          {
            opacity: 1,
            y: 0,
            scale: 1,
            duration: 0.4,
            clearProps: "transform",
            onStart: () => triggerHapticFeedback("rigid")
          },
          "-=0.2"
        );
      });
    }

    // Header elements animation with light haptics
    if (headerElements.length) {
      tl.fromTo(headerElements,
        {
          opacity: 0,
          y: -10
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.4,
          stagger: 0.1,
          onStart: () => triggerHapticFeedback("soft")
        },
        "-=0.2"
      );
    }

    // CTA Button animation with medium haptic
    if (ctaButton) {
      tl.fromTo(ctaButton,
        {
          opacity: 0,
          y: 20,
          scale: 0.9
        },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 0.5,
          ease: "back.out(1.7)",
          onStart: () => triggerHapticFeedback("medium")
        },
        "-=0.2"
      );
    }

    return () => {
      tl.kill();
    };
  }, [exportData]);

  async function generateShareImage(): Promise<string | null> {
    if (chatContentRef.current) {
      try {
        const originalStyle = chatContentRef.current.style.cssText;
        const originalHeight = chatContentRef.current.style.height;
        const originalOverflow = chatContentRef.current.style.overflow;

        chatContentRef.current.style.height = "auto";
        chatContentRef.current.style.overflow = "visible";

        const canvas = await html2canvas(chatContentRef.current, {
          backgroundColor: "#000000",
          scale: 2,
          useCORS: true,
          logging: false,
          windowWidth: chatContentRef.current.scrollWidth,
          windowHeight: chatContentRef.current.scrollHeight
        });

        chatContentRef.current.style.cssText = originalStyle;
        chatContentRef.current.style.height = originalHeight;
        chatContentRef.current.style.overflow = originalOverflow;

        return canvas.toDataURL("image/png");
      } catch (error) {
        Sentry.captureException(error);
        console.error("Failed to generate share image:", error);
        toast.error("Failed to generate share image");
        return null;
      }
    }
    return null;
  }

  function handleTextDownload() {
    if (!exportData) return;
    const exportContent = `Chat between ${user?.name.split(" ")[0] || "Anonymous"} and ${exportData.character}\n\n${exportData.messages
      .map((msg) => `${msg.sender === "user" ? (user?.name.split(" ")[0] || "Anonymous") : exportData.character}: ${msg.content}`)
      .join("\n\n")}`;

    const blob = new Blob([exportContent], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `chat_with_${exportData.character.replace(/\s+/g, "_")}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  async function handleImageDownload() {
    if (!chatContentRef.current) return;

    try {
      const canvas = await html2canvas(chatContentRef.current, {
        scale: 2, // Higher quality
        useCORS: true, // For images from other domains
        backgroundColor: "#000000", // Or any color you want
        logging: false // Disable logs
      });

      const link = document.createElement("a");
      link.download = `chat-with-${exportData?.character || "character"}.png`;
      link.href = canvas.toDataURL("image/png");
      link.click();
      toast.success("Image downloaded successfully!");
    } catch (error) {
      Sentry.captureException(error);
      console.error("Failed to download image:", error);
      toast.error("Failed to download image. Please try again.");
    }
  }

  async function shareOnPlatform(platformUrl: (url: string, imageUrl: string) => string) {
    try {
      const url = await saveAndGetShareUrl();
      const imageUrl = await generateShareImage();
      window.open(platformUrl(url, imageUrl || ""), "_blank");
    } catch (error) {
      Sentry.captureException(error);
      console.error("Failed to share:", error);
      toast.error("Failed to share");
    }
  }

  async function shareOnTwitter() {
    try {
      const url = await saveAndGetShareUrl();
      const imageDataUrl = await generateShareImage();
      const text = `Check out my chat with ${exportData?.character}!`;

      if (imageDataUrl) {
        const link = document.createElement("a");
        link.href = imageDataUrl;
        link.download = `chat_with_${exportData?.character.replace(/\s+/g, "_")}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.info("Image downloaded. Please attach it manually when sharing on Twitter.");
      }

      window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`,
        "_blank"
      );
    } catch (error) {
      Sentry.captureException(error);
      console.error("Failed to share on Twitter:", error);
      toast.error("Failed to share on Twitter");
    }
  }

  function shareOnFacebook() {
    shareOnPlatform((url) => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`);
  }

  function shareOnWhatsApp() {
    const text = `Check out my chat with ${exportData?.character}!`;
    shareOnPlatform((url) => `https://api.whatsapp.com/send?text=${encodeURIComponent(text + " " + url)}`);
  }

  function shareOnTelegram() {
    const text = `Check out my chat with ${exportData?.character}!`;
    shareOnPlatform((url) => `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`);
  }

  async function copyLinkToClipboard() {
    try {
      const url = await saveAndGetShareUrl();
      await navigator.clipboard.writeText(url);
      if (location.state && location.state.origin == "onboarding") {
        setIsModalOpen(true);
      }
      toast.success("Link copied to clipboard!");
      setOpenShareModal(false);
    } catch (error) {
      Sentry.captureException(error);
      console.error("Failed to copy link:", error);
      toast.error("Failed to copy link. Please try again.");
    }
  }

  function handleOpenShareModal() {
    setOpenShareModal(true);
  }

  function handleCloseShareModal() {
    setOpenShareModal(false);
  }

  const getImageSrc = (movie: Movie): string => {
    if (!movie) return "";
    const path = movie.backdrop_path || movie.poster_path || "";

    return movie.source === "anime"
      ? path
      : path ? `https://image.tmdb.org/t/p/original${path}` : "";
  };

  const getUserName = () => {
    return user?.name?.split(" ")[0] || exportData?.user_name || "Anonymous";
  };

  const getTitle = () => {
    return exportData?.title || title;
  };

  // Inside ChatExport component, add this before the return statement
  const metaPreview = exportData ? generateMetaPreview({
    character: exportData.character,
    profileImage: getProfileImageUrl({
      profile_path: exportData.profileImage,
      name: exportData.character
    }),
    firstMessage: exportData.messages[0]?.content || "",
    origin: window.location.origin
  }) : null;

  const getBackgroundUrl = () => {
    if (exportData?.movie) {
      return getImageSrc(exportData.movie);
    }
    return exportData?.backgroundImage || undefined;
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!exportData) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Typography>No export data found. Please try reloading the page.</Typography>
      </Box>
    );
  }
  const handleNavigation = () => {
    const canGoBack = window.history.state && window.history.state.idx > 0;
    if (canGoBack) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      <MainContainer
        ref={backgroundRef}
        style={{ opacity: 0 }}
        backgroundUrl={getBackgroundUrl()}
      >
        <ContentContainer>
          <Box sx={{ flexGrow: 1 }}>
            <StyledAppBar position="fixed">
              <Toolbar>
                {location.state?.origin !== "onboarding" && (
                  <IconButton
                    className="header-element"
                    edge="start"
                    color="inherit"
                    onClick={handleNavigation}
                    aria-label="back"
                  >
                    <ArrowBack />
                  </IconButton>
                )}
                <Typography
                  className="header-element"
                  variant="caption"
                  component="div"
                  sx={{ flexGrow: 1, textAlign: "center", fontWeight: "bold" }}
                >
                  Scenario Portal by sceneXtras
                </Typography>
                <IconButton
                  className="header-element"
                  color="inherit"
                  onClick={handleOpenShareModal}
                  aria-label="share"
                  sx={{ marginLeft: "auto" }}
                >
                  <ShareIcon />
                </IconButton>
                {/*<IconButton*/}
                {/*  className="header-element"*/}
                {/*  color="inherit"*/}
                {/*  onClick={handleOpenShareModal}*/}
                {/*  aria-label="share"*/}
                {/*  sx={{ marginLeft: "auto" }}*/}
                {/*>*/}
                {/*  <ShareIcon />*/}
                {/*</IconButton>*/}
              </Toolbar>
            </StyledAppBar>
            <Toolbar />
            <Container maxWidth="md" sx={{ mt: 4, mb: 4, marginTop: "1vh" }}>
              {
                innerUser &&
                (
                  <CheckoutModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    user={innerUser}
                    displaySource="export"
                  />
                )}
              <Box ref={titleRef} sx={{ overflow: "visible" }}>
                <IlluminatedText>
                  {getTitle()}
                  <br />
                  <span className="glow-filter"
                        data-text={`by ${getUserName()} and ${exportData.character}`}>
                    by {getUserName()} and {exportData.character}
                  </span>
                </IlluminatedText>

                {exportId && (
                  <Box sx={{ mt: -1.5 }}>
                    <EngagementMetrics
                      metrics={{
                        views: 0,
                        shares: 0,
                        upvotes: 0
                      }}
                      exportId={exportId}
                      exportData={exportData}
                    />
                  </Box>
                )}
              </Box>

              <List
                ref={messagesContainerRef}
                disablePadding
                component="ul"
                sx={{
                  overflowY: "auto", // Enable scrolling for the message list
                  maxHeight: "calc(100vh - 200px)" // Adjust height to fit within the viewport
                }}
              >
                <ChatMessagesContainer sx={{ display: "block" }}>
                  {exportData.messages.map((msg: MessageType, index: number) => (
                    <ListItem
                      key={index}
                      disableGutters
                      className="message-item"
                      data-id={index}
                    >
                      <ChatMessageContainer isUser={msg.sender === "user"}>
                        {msg.sender !== "user" && (
                          <ProfileImage
                            loading="lazy"
                            src={msg.profileImage || getProfileImageUrl({
                              profile_path: exportData.profileImage,
                              name: exportData.character
                            })}
                            alt={exportData.character}
                          />
                        )}
                        <ChatMessage isUser={msg.sender === "user"}>
                          <Box
                          >

                            {!msg.audio && (
                              <Typography variant="body1">
                                {formatMessageContent(msg.content)}
                              </Typography>
                            )}

                            {msg.audio && (
                              <AudioMessageContainer>
                                <AudioControlsWrapper
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                    width: "100%"
                                  }}
                                >
                                  <Box
                                    sx={{
                                      minWidth: "100px",
                                      width: "80%",
                                      borderRadius: "10px",
                                      padding: "0rem 1rem",
                                      flexGrow: 1
                                    }}
                                  >
                                    <Waveform
                                      audioUrl={msg.audio}
                                      noAuth={!!shareUrl}
                                      complete_path={msg.prefixed_audio_filename}
                                    />
                                  </Box>
                                  <TranscriptButton
                                    onClick={() => toggleTranscriptVisibility(index)}
                                    size="small"
                                    aria-label="Show transcript"
                                    sx={{
                                      marginLeft: "auto",
                                      flexShrink: 0
                                    }}
                                  >
                                    ☰
                                  </TranscriptButton>
                                </AudioControlsWrapper>
                                {transcriptVisibility[index] && msg.transcript && (
                                  <Transcript variant="body2">
                                    {msg.transcript}
                                  </Transcript>
                                )}
                              </AudioMessageContainer>
                            )}
                          </Box>
                        </ChatMessage>
                      </ChatMessageContainer>
                    </ListItem>
                  ))}
                </ChatMessagesContainer>
              </List>
            </Container>
            {shareUrl && location.state?.origin === "onboarding" ? (
              <CTAButton
                href="https://scenextras.com"
                onClick={(e) => {
                  e.preventDefault();
                  gsap.to(e.currentTarget, {
                    scale: 0.95,
                    duration: 0.1,
                    ease: "power2.out",
                    yoyo: true,
                    repeat: 1,
                    onComplete: () => {
                      handleOpenShareModal();
                    }
                  });
                }}
              >
                LAST STEP: SHARE TO CHAT
              </CTAButton>
            ) : (
              <CTAButton
                href="https://scenextras.com"
                onClick={(e) => {
                  e.preventDefault();
                  gsap.to(e.currentTarget, {
                    scale: 0.95,
                    duration: 0.1,
                    ease: "power2.out",
                    yoyo: true,
                    repeat: 1,
                    onComplete: () => {
                      window.location.href = "https://scenextras.com";
                    }
                  });
                }}
              >
                Create Your Story →
              </CTAButton>
            )}
          </Box>
        </ContentContainer>
      </MainContainer>

      <svg className="filters" width="0" height="0" style={{ position: "absolute", zIndex: -1 }}>
        <defs>
          <filter id="glow-4" colorInterpolationFilters="sRGB" x="-50%" y="-200%" width="200%" height="500%">
          </filter>
        </defs>
      </svg>

      <Modal
        open={openShareModal}
        onClose={handleCloseShareModal}
        closeAfterTransition
      >
        <Fade in={openShareModal}>
          <Paper
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 300,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2
            }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              Share
            </Typography>

            <FormControl component="fieldset" sx={{ mb: 2, width: "100%" }}>
              <RadioGroup
                value={shareType}
                onChange={handleShareTypeChange}
                sx={{
                  "& .MuiFormControlLabel-root": {
                    margin: "4px 0"
                  }
                }}
              >
                <FormControlLabel
                  value="secret"
                  control={<Radio color="primary" />}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <LockIcon sx={{ mr: 1, fontSize: 20 }} />
                      <Box>
                        <Typography variant="body1">Secret</Typography>
                        <Typography variant="caption" color="text.secondary">
                          Only people with the link can view
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="public"
                  control={<Radio color="primary" />}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PublicIcon sx={{ mr: 1, fontSize: 20 }} />
                      <Box>
                        <Typography variant="body1">Public</Typography>
                        <Typography variant="caption" color="text.secondary">
                          Anyone can find and view
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>

            <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
              {isMobile ? (
                <ShareContent
                  copyLinkToClipboard={copyLinkToClipboard}
                  isSaving={isSaving}
                  exportData={exportData}
                  saveAndGetShareUrl={saveAndGetShareUrl}
                  generateShareImage={generateShareImage}
                  shareType={shareType}
                  modalCommand={() => location.state && location.state?.origin === "onboarding" ? setIsModalOpen(true) : null}
                />
              ) : (
                <>
                  <IconButton
                    onClick={copyLinkToClipboard}
                    color="primary"
                    disabled={isSaving}
                  >
                    {isSaving ? <CircularProgress size={24} /> : <LinkIcon />}
                  </IconButton>
                  <IconButton onClick={shareOnTwitter} color="primary">
                    <Twitter />
                  </IconButton>
                  <IconButton onClick={shareOnFacebook} color="primary">
                    <Facebook />
                  </IconButton>
                  <IconButton onClick={shareOnWhatsApp} color="primary">
                    <WhatsApp />
                  </IconButton>
                </>
              )}
            </Box>
          </Paper>
        </Fade>
      </Modal>

      {/* Add this inside the first <ThemeProvider> element, before <Global> */}
      {metaPreview && (
        <Helmet>
          {/* Essential Meta Tags */}
          <title>{metaPreview.title}</title>
          <meta name="description" content={metaPreview.description} />

          {/* OpenGraph Meta Tags */}
          <meta property="og:title" content={metaPreview.title} />
          <meta property="og:description" content={metaPreview.description} />
          <meta property="og:image" content={metaPreview.image} />
          <meta property="og:url" content={metaPreview.url} />
          <meta property="og:site_name" content={metaPreview.siteName} />
          <meta property="og:type" content="website" />

          {/* Twitter Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={metaPreview.handle} />
          <meta name="twitter:title" content={metaPreview.title} />
          <meta name="twitter:description" content={metaPreview.description} />
          <meta name="twitter:image" content={metaPreview.image} />
        </Helmet>
      )}
    </ThemeProvider>
  );
}

export default ChatExport;
