import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Typography, Link } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import ChatIcon from '@mui/icons-material/Chat';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

const FooterRoot = styled('footer')(({ theme }) => ({
  backgroundColor: 'rgba(28, 28, 28, 0.95)',
  backdropFilter: 'blur(20px)',
  WebkitBackdropFilter: 'blur(20px)',
  borderTop: '1px solid rgba(255, 255, 255, 0.1)',
  padding: theme.spacing(4, 0),
  width: '100%',
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.7)',
  textDecoration: 'none',
  fontSize: '14px',
  '&:hover': {
    color: 'white',
    textDecoration: 'none',
  },
}));

const SocialIcon = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  marginRight: theme.spacing(2),
  '& svg': {
    width: 24,
    height: 24,
    color: 'rgba(255, 255, 255, 0.7)',
    transition: 'color 0.2s',
    '&:hover': {
      color: 'white',
    },
  },
}));

const FooterSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

export const Footer: React.FC = () => {
  const handleSupportClick = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.href = 'mailto:support@scenextras.com';
  };

  return (
    <FooterRoot>
      <Container maxWidth="lg">
        <FooterSection>
          <FooterLink href="/privacy-policy">Privacy Policy</FooterLink>
          <FooterLink href="/faq">FAQ</FooterLink>
          <FooterLink href="/impressum">Impressum</FooterLink>
          <FooterLink href="#" onClick={handleSupportClick}>Support</FooterLink>
        </FooterSection>

        <FooterSection>
          <FooterLink href="https://www.instagram.com/scenextrasai/" target="_blank">
            <SocialIcon>
              <InstagramIcon />
            </SocialIcon>
          </FooterLink>
          <FooterLink href="https://discord.com/invite/AscUxE2NEC" target="_blank">
            <SocialIcon>
              <ChatIcon />
            </SocialIcon>
          </FooterLink>
          <FooterLink href="https://www.tiktok.com/@scenextras" target="_blank">
            <SocialIcon>
              <MusicNoteIcon />
            </SocialIcon>
          </FooterLink>
        </FooterSection>

        <Box textAlign="center">
          <Typography variant="body2" color="rgba(255, 255, 255, 0.5)">
            © 2024 Scenextras Inc. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </FooterRoot>
  );
}; 