import { getObjectFromLocal } from "./storageUtils";
import { getUserForSupabase, getUserForSupabaseSession } from "../api/authClient";
import log from "loglevel";
import { toast } from "react-toastify";
import { User as FirebaseUser } from "@firebase/auth";
import { User } from "../types/UserType";
import posthog from "posthog-js";
import * as Sentry from "@sentry/react";
import { Session } from "@supabase/supabase-js";

export type AuthenticatedUser = {
  firebaseUser?: FirebaseUser;
  email: string;
  premium_expiration?: string;
  remainingQuota?: number;
  isPremium?: string;
  history?: string[];
  profileImage?: string;
  name: string;
  quota_earned?: number;
  referral_code?: string;
  referral_id?: string;
  unsubscribed?: boolean;
  streak?: number;
  access_token?: string;
  token_type?: string;
  status?: string;
  confirmed?: number;
  premium?: number;
  temporary_account?: number;
  referral_unique_id?: string;
  has_received_trial?: number;
};


export function convertUserToAuthenticatedUser(user: User): AuthenticatedUser {
  if (!user) {
    throw new Error("INCOMPATIBLE USER");
  }
  return {
    premium_expiration: user.premium_expiration,
    firebaseUser: undefined,
    email: user.email,
    remainingQuota: user.remainingQuota,
    isPremium: user.isPremium,
    history: user.history,
    profileImage: user.profileImage,
    name: user.name,
    quota_earned: user.quota_earned,
    referral_code: user.referral_code,
    referral_id: user.referral_id,
    unsubscribed: user.unsubscribed,
    streak: user.streak,
    access_token: user.access_token,
    token_type: user.token_type,
    status: user.status,
    confirmed: user.confirmed,
    premium: user.premium,
    temporary_account: user.temporary_account,
    referral_unique_id: user.referral_unique_id,
    has_received_trial: user.has_received_trial,
  };
}

export function convertAuthenticatedUserToUser(authenticatedUser: AuthenticatedUser): User {
  if (!authenticatedUser) {
    throw new Error("INCOMPATIBLE USER");
  }

  return {
    email: authenticatedUser.email,
    remainingQuota: authenticatedUser.remainingQuota,
    isPremium: authenticatedUser.isPremium,
    premium_expiration: authenticatedUser.premium_expiration,
    history: authenticatedUser.history,
    profileImage: authenticatedUser.profileImage,
    name: authenticatedUser.name,
    quota_earned: authenticatedUser.quota_earned,
    referral_code: authenticatedUser.referral_code,
    referral_id: authenticatedUser.referral_id,
    unsubscribed: authenticatedUser.unsubscribed,
    streak: authenticatedUser.streak,
    access_token: authenticatedUser.access_token,
    token_type: authenticatedUser.token_type,
    status: authenticatedUser.status,
    confirmed: authenticatedUser.confirmed,
    premium: authenticatedUser.premium,
    temporary_account: authenticatedUser.temporary_account,
    referral_unique_id: authenticatedUser.referral_unique_id,
    has_received_trial: authenticatedUser.has_received_trial,
  };
}

export const fetchUser = async (setIsLoading: any, setAuthenticatedUser: any, setUser: any, setLoggedInUser: any) => {
  setIsLoading(true);

  const existing_token_supabase = getObjectFromLocal(
    "sb-vcsqhuxpigrhqgausqit-auth-token"
  );
  if (existing_token_supabase) {
    try {
      const response = await getUserForSupabase();
      log.info("response: " + response);
      if (!response.email?.startsWith("on-the-fly-user")) {
        const authenticatedUser = setAuthenticatedUser(response);
        setUser(authenticatedUser);
        if (setLoggedInUser) {
          setLoggedInUser(authenticatedUser);
        }
      }
    } catch (err) {
      Sentry.captureException(err);
      const error = err as AxiosError;
      toast.error("Error fetching user.");
      log.info("Error fetching user:", error.message || "Unknown error");
    }
  }

  setIsLoading(false);
};

export const fetchUserForSupabaseNew = async (
  session: Session | null,
  convertUserToAuthenticatedUser: (user: User) => AuthenticatedUser,
  setUser: (user: AuthenticatedUser | null) => void
) => {
  try {
    const user = await getUserForSupabaseSession(session);
    const authenticatedUser = convertUserToAuthenticatedUser(user);
    console.log("Authenticated User: ", authenticatedUser);

    setUser(authenticatedUser);

    console.log("User: ", user);
    posthog.identify(
      authenticatedUser.email || "unknown",
      { authenticatedUser }
    );
  } catch (error) {
    Sentry.captureException(error);
    console.error("Error fetching authenticated user:", error);
    setUser(null);
  }
};
