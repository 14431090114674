import { create } from 'zustand';
import { Session } from '@supabase/supabase-js';
import { supabase } from '../helper/supabase_client';
import {
  AuthenticatedUser,
  convertUserToAuthenticatedUser,
  fetchUserForSupabaseNew
} from "../helper/authHelper";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { setWithoutExpiry } from '../helper/storageUtils';

interface AuthState {
  user: AuthenticatedUser | null;
  session: Session | null;
  isLoading: boolean;
  setUser: (user: AuthenticatedUser | null) => void;
  setSession: (session: Session | null) => void;
  signOut: () => Promise<void>;
  initializeAuth: () => Promise<void>;
  refreshUser: () => Promise<void>;
}

export const useAuthStore = create<AuthState>((set, get) => {
  // Store the auth subscription
  let authSubscription: { unsubscribe: () => void } | null = null;

  return {
    user: null,
    session: null,
    isLoading: true,

    setUser: (user) => {
      set({ user });
      if (user) {
        posthog.identify(user.email || "unknown", {
          email: user.email,
          isPremium: user.isPremium,
          name: user.name,
          quota: user.remainingQuota,
          streak: user.streak
        });
      }
    },

    setSession: (session) => set({ session }),

    signOut: async () => {
      try {
        // Cleanup subscription before signing out
        if (authSubscription) {
          authSubscription.unsubscribe();
          authSubscription = null;
        }

        await supabase.auth.signOut();
        set({ user: null, session: null });
        setWithoutExpiry("logged_in", false);
        posthog.reset();
        sessionStorage.clear();
        localStorage.clear();
      } catch (error) {
        Sentry.captureException(error);
        console.error('Error signing out:', error);
      }
    },

    refreshUser: async () => {
      const { session } = get();
      if (!session) return;

      try {
        await fetchUserForSupabaseNew(
          session,
          convertUserToAuthenticatedUser,
          (user: AuthenticatedUser | null) => set({ user })
        );
      } catch (error) {
        Sentry.captureException(error);
        console.error('Error refreshing user:', error);
      }
    },

    initializeAuth: async () => {
      try {
        set({ isLoading: true });

        // Clean up existing subscription if any
        if (authSubscription) {
          authSubscription.unsubscribe();
          authSubscription = null;
        }

        const { data: { session } } = await supabase.auth.getSession();
        set({ session });

        if (session) {
          await fetchUserForSupabaseNew(
            session,
            convertUserToAuthenticatedUser,
            (user: AuthenticatedUser | null) => set({ user })
          );
        }

        // Set up new auth subscription
        const { data: { subscription } } = supabase.auth.onAuthStateChange(
          async (event, session) => {
            set({ session });

            if (session) {
              await fetchUserForSupabaseNew(
                session,
                convertUserToAuthenticatedUser,
                (user: AuthenticatedUser | null) => set({ user })
              );
            }

            if (event === 'SIGNED_OUT') {
              set({ user: null });
              setWithoutExpiry("logged_in", false);
              posthog.reset();
            }
          }
        );

        // Store subscription for cleanup
        authSubscription = subscription;

      } catch (error) {
        Sentry.captureException(error);
        console.error('Error initializing auth:', error);
      } finally {
        set({ isLoading: false });
      }
    }
  };
});