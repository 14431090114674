import React, { useState } from "react";
import { unsubscribe } from "../../api/apiClient";
import { User } from "../../types/UserType";
import * as Sentry from "@sentry/react";
import log from "loglevel";
import { toast } from "react-toastify";
import UnsubscribeModal from "../modals/UnsubscribeModal";
import posthog from "posthog-js";

type UnsubscribeButtonProps = {
  user: User;
};

const UnsubscribeButton: React.FC<UnsubscribeButtonProps> = ({ user }) => {
  const [showModal, setShowModal] = useState(false);

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleRedirectToStripe = () => {
    try {
      posthog.capture("redirect_to_stripe_portal", {
        email: user.email
      });
      window.location.href = "https://billing.stripe.com/p/login/dR603i9Jv0HI0r6000";
    } catch (error) {
      Sentry.captureException(error);
      log.error("Error redirecting to Stripe portal:", error);
    }
  };


  const handleUnsubscribe = async () => {
    try {
      posthog.capture("user unsubscribed");
      const status = await unsubscribe(user.email);
      if (status) {
        toast.success("Successfully unsubscribed, your subscription will be cancelled at the end of the subscription period.");
      }
      setShowModal(false);
      setTimeout(handleRefresh, 2000);
    } catch (error) {
      Sentry.captureException(error);
      log.error(error);
    }
  };

  return (
    <>
      <span
        onClick={() => setShowModal(true)}
        style={{
          display: "inline",
          fontSize: "1em",
          color: "#999",
          cursor: "pointer",
          textDecoration: "underline"
        }}
      >
        ✦ Premium Member
      </span>
      {showModal && (
        <UnsubscribeModal
          setVisibility={setShowModal}
          onConfirm={handleRedirectToStripe}
        />
      )}
    </>
  );
};

export default UnsubscribeButton;