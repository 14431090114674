import { useState, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

export function useSidebar() {
  const [isOpen, setIsOpen] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    // Close sidebar on mobile
    if (isMobile) {
      setIsOpen(false);
    }
  }, [isMobile]);

  const toggleSidebar = () => {
    if (!isMobile) {
      setIsOpen(!isOpen);
    }
  };

  return {
    isOpen,
    isMobile,
    toggleSidebar,
  };
} 