import React from 'react';
import { Box, styled, useTheme, useMediaQuery } from '@mui/material';
import { Sidebar } from './sidebar/Sidebar';
import BottomMenuBar from '../template/BottomMenuBar';
import { AuthenticatedUser } from '../../helper/authHelper';
import { useAuthStore } from "../../stores/useAuthStore";
import useIsMobile from "../hooks/useIsMobile";
import { useSidebar } from '../hooks/useSidebar';

const drawerWidth = 0;
const collapsedWidth = 0;

const LayoutRoot = styled(Box)({
  display: 'flex',
  minHeight: '100vh',
  backgroundColor: '#1a1a1a',
  overflow: 'hidden',
  isolation: 'isolate',
  WebkitFontSmoothing: 'antialiased',
  backfaceVisibility: 'hidden',
});

const MainContent = styled(Box)<{ open: boolean }>(({ theme, open }) => ({
  flexGrow: 1,
  minHeight: '100vh',
  paddingLeft: collapsedWidth,
  willChange: 'transform',
  backfaceVisibility: 'hidden',
  WebkitFontSmoothing: 'antialiased',
  transform: open ? `translate3d(${drawerWidth - collapsedWidth}px, 0, 0)` : 'translate3d(0, 0, 0)',
  transition: 'transform 225ms cubic-bezier(0.4, 0, 0.6, 1)',
  '& > *': {
    willChange: 'contents',
    isolation: 'isolate',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    transform: 'translate3d(0, 0, 0)',
  },
}));

interface LayoutProps {
  children: React.ReactNode;
  user?: AuthenticatedUser;
  onUpgradeClick: () => void;
}

export const Layout: React.FC<LayoutProps> = ({ children, onUpgradeClick }) => {
  const { isOpen } = useSidebar();
  const isMobile = useIsMobile();

  return (
    <LayoutRoot>
      {!isMobile && <Sidebar onUpgradeClick={onUpgradeClick} />}
      <MainContent open={isOpen}>
        {children}
      </MainContent>
      {/*<BottomMenuBar />*/}
    </LayoutRoot>
  );
}; 