import React, { useState, forwardRef } from "react";
import { createCheckoutSession } from "../../api/apiClient";
import log from "loglevel";
import { User } from "../../types/UserType";
import { loadStripe } from "@stripe/stripe-js";
import posthog from "posthog-js";
import { PlanType, ProductType, Products } from "./CheckoutModal";
import { toast } from "react-toastify";

type CheckoutButtonProps = {
  user: User;
  className?: string;
  children?: React.ReactNode;
  plan: PlanType;
  onButtonClick: () => void;
  displaySource?: string;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

const CheckoutButton = forwardRef<HTMLButtonElement, CheckoutButtonProps>(
  ({ user, className, children, plan, onButtonClick, displaySource }, ref) => {
    const [isLoading, setIsLoading] = useState(false);

    const isCreditPurchase = (plan: PlanType): boolean => {
      return [Products.CREDITS_20, Products.CREDITS_50, Products.CREDITS_100, Products.CREDITS_200].includes(plan as Products);
    };

    const handleClick = async () => {
      if (isCreditPurchase(plan)) {
        toast.info("Credits purchase is currently disabled. Please choose a subscription plan.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      try {
        setIsLoading(true);
        posthog.capture("user clicked upgrade button");
        const rootUrl = window.location.origin;
        const session = await createCheckoutSession(rootUrl, user.email, plan, displaySource);
        const stripe = await stripePromise;
        if (!stripe) {
          log.error("Stripe.js has not been loaded");
          throw new Error("Stripe.js failed to load");
        }
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.id
        });

        if (error) {
          posthog.capture("error upgrading", { error });
          log.error("Error:", error);
        }
      } catch (e) {
        posthog.capture("error upgrading", { error: e });
        log.error("Error:", e);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <button
        ref={ref}
        onClick={handleClick}
        disabled={isLoading}
        className={`upgrade-button2 ${className || ""}`}
      >
        <div className="text">
          {isLoading ? "Processing..." : children}
        </div>
        <div className="shimmer"></div>
      </button>
    );
  }
);

CheckoutButton.displayName = "CheckoutButton";

export default CheckoutButton;