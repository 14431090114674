import React, { FC, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import posthog from "posthog-js";
import "../../assets/css/chatmodelbtn.scss";
import CornerMaxButton from "../miscs/CornerMaxButton";
import { ModelChoice } from "../chat/InnerChat";

interface ModelOption {
  id: ModelChoice;
  label: string;
}

interface ModelChoiceProps {
  models: readonly ModelOption[];
  modelChoice: ModelChoice;
  onModelChange: (model: ModelChoice) => void;
}

const ModelChoiceButton: FC<ModelChoiceProps> = ({ models, modelChoice, onModelChange }) => {
  const [isActive, setIsActive] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);

  const handleModelClick = (modelId: ModelChoice) => {
    onModelChange(modelId);
    posthog.capture("model_selected", { model: modelId });
    setTimeout(() => setIsActive(false), 300);
  };

  return (
    <div className={`model-list-container ${isActive ? "active" : ""} side-options-buttons`}>
      <CornerMaxButton blockIsActive={false} location="options-menu">
        <button
          type="button"
          className="model-more-button"
          aria-label="Model Selection"
          onClick={() => setIsActive(!isActive)}
        >
          <FontAwesomeIcon
            icon={faBrain}
            className="icon-shadow"
            style={{ color: "#f4f7fa" }}
          />
        </button>
      </CornerMaxButton>
      <ul className="model-more-button-list" ref={listRef}>
        {models.map((model) => (
          <CornerMaxButton blockIsActive={true} key={model.id} location="options-item">
            <li
              className={`model-more-button-list-item ${model.id === modelChoice ? "selected" : ""}`}
              onClick={() => handleModelClick(model.id)}
            >
              <span className={`model-icon ${model.id}`}></span>
              <span>{model.label}</span>
            </li>
          </CornerMaxButton>
        ))}
      </ul>
    </div>
  );
};

export default ModelChoiceButton;