import React from 'react';
import styled from '@emotion/styled';

interface BlurredBackgroundProps {
  imageUrl?: string;
  opacity?: number;
}

const BackgroundContainer = styled.div<BlurredBackgroundProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${props => props.imageUrl ? `url(${props.imageUrl})` : 'none'};
    background-size: cover;
    background-position: center;
    opacity: ${props => props.opacity || 0.25};
    filter: blur(35px) saturate(120%);
    transform: scale(1.1); // Prevent blur edges
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.4) 100%
    );
    backdrop-filter: blur(8px);
  }
`;

const BlurredBackground: React.FC<BlurredBackgroundProps> = ({ imageUrl, opacity }) => {
  return <BackgroundContainer imageUrl={imageUrl} opacity={opacity} />;
};

export default BlurredBackground; 