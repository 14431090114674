import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getPopularContent,
  searchActorsFilmography,
  searchAnimesWithCast,
  searchCartoonsWithCast,
  searchMoviesWithCast,
  searchSeriesWithCast
} from "../../api/apiClient";
import MovieDisplay from "./MovieDisplay";
import { ActorFilmography, Movie, SeriesWithCast } from "../../types/MovieType";
import { Cast } from "../../types/CastType";
import CharacterCarousel from "../miscs/CharacterCarousel";
import "../../css/searchList.scss";
import { getWithExpiry, getWithoutExpiry, setWithExpiry } from "../../helper/storageUtils";
import GeneralChat from "../chat/GeneralChat";
import Loading from "../miscs/Loading";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import Seo from "../../helper/Seo";
import log from "loglevel";
import gsap from "gsap";
import { getPopularKey, getSearchResultsKey, POPULAR_MOVIE_CAST_LIMIT, POPULAR_MOVIE_LIMIT } from "../../config";
import CameraCaptureSearch from "../miscs/CameraCaptureSearch";
import "../../../src/assets/css/upgradebtn.css";
import { ModalContext } from "../modals/ModalContext";
import { keyframes } from "@emotion/react";
import { Skeleton, Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import { Footer } from "../navigation/Footer";
import { useAuthStore } from "../../stores/useAuthStore";

export type ContentType = "movies" | "series" | "animes" | "cartoons" | "actors";
export type TabName = Capitalize<ContentType>;

export type ContentResults = {
  [K in ContentType]: Movie[];
};

interface SlugCacheItem {
  title: string;
  type: ContentType;
  id?: string | number; // Assuming id can be either a string or a number
}


const CACHE_EXPIRY = 24 * 60 * 60 * 5000; // 5 days in milliseconds
const ITEMS_PER_PAGE = 6;

const MAX_CACHED_SEARCHES = 10;

function getCachedSearches(category: ContentType): { [query: string]: Movie[] } {
  return JSON.parse(getWithExpiry(getSearchResultsKey(category)) || "{}");
}

function setCachedSearches(category: ContentType, searches: { [query: string]: Movie[] }) {
  setWithExpiry(getSearchResultsKey(category), JSON.stringify(searches), CACHE_EXPIRY);
}

function updateSlugCache(items: (Movie | ActorFilmography)[], sourceType: ContentType) {
  const slugCache = JSON.parse(getWithExpiry("slugCache") || "{}");
  items.forEach(item => {
    const slug = processSlug(item);
    if (slug) {
      slugCache[slug] = {
        title: "title" in item ? item.title : item.name,
        type: sourceType,
        id: "id" in item ? item.id : undefined
      } as SlugCacheItem;
    }
  });
  setWithExpiry("slugCache", JSON.stringify(slugCache), CACHE_EXPIRY);
}

function addCachedSearch(category: ContentType, query: string, results: Movie[]) {
  const processedResults = results.map(movie => ({
    ...movie,
    slug: generateSlug(movie.title || movie.name || ""),
    cast: movie.cast?.map(castMember => ({
      ...castMember,
      slug: generateSlug(castMember.name)
    })) || []
  }));
  const cachedSearches = getCachedSearches(category);
  cachedSearches[query] = processedResults;
  const queries = Object.keys(cachedSearches);
  if (queries.length > MAX_CACHED_SEARCHES) {
    delete cachedSearches[queries[0]];
  }
  setCachedSearches(category, cachedSearches);
  updateSlugCache(processedResults, category);
}


export const generateSlug = (str?: string): string => {
  if (!str) return "";

  return str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");
};


export function findContentBySlug(slugs: string[]) {
  const [mainSlug] = slugs.map(slug => slug.toLowerCase());

  // Check cache first
  const slugCache = JSON.parse(getWithExpiry("slugCache") || "{}");

  console.log("slugCache", slugCache);

  console.log(slugs);
  const slugInfo = Object.entries(slugCache)
    .find(([key]) => key.toLowerCase() === mainSlug)?.[1];
  log.debug("slugInfo:", slugInfo);

  if (!slugInfo) {
    return { content: null, character: null, type: null };
  }

  log.debug("slugInfo:", slugInfo);
  const contentType = (slugInfo as SlugCacheItem).type;

  // Retrieve popular content
  const popularContent = JSON.parse(getWithExpiry(getPopularKey(contentType)) || "[]");

  // Retrieve cached searches for the given contentType
  const cachedSearches = JSON.parse(getWithExpiry(getSearchResultsKey(contentType)) || "{}");

  // Flatten all search results into a single array
  const searchContent = Object.values(cachedSearches).flat() as Movie[];

  log.debug("popularContent:", popularContent);
  log.debug("searchContent:", searchContent);

  // Find content with case-insensitive match
  const findContent = (items: any[]) => items.find(item => {
    const itemSlug = generateSlug(item.title || item.name).toLowerCase();
    return itemSlug === mainSlug;
  });

  log.debug("searchContent:", searchContent);
  const content = findContent(popularContent) || findContent(searchContent);
  log.info("content:", content);

  if (!content) {
    return { content: null, character: null, type: null };
  }

  log.debug("content:", content);

  // Find character if specified
  const characterSlug = slugs[1]?.toLowerCase();
  const character = characterSlug && content.cast?.find((c: Cast) =>
    generateSlug(c.character).toLowerCase() === characterSlug
  );

  return {
    content,
    character: character || null,
    type: contentType
  };
}


export const processSlug = (item: Movie | ActorFilmography): string => {
  const title = "title" in item ? item.title :
    "name" in item ? item.name : "";
  return generateSlug(title);
};


const shimmer = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
`;

export default function Search() {
  const { user } = useAuthStore();
  const [isResourceLoading, setResourceIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [popularContent, setPopularContent] = useState<ContentResults>({
    movies: [],
    series: [],
    animes: [],
    cartoons: [],
    actors: []
  });
  const [searchResults, setSearchResults] = useState<ContentResults>({
    movies: [],
    series: [],
    animes: [],
    cartoons: [],
    actors: []
  });
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
  const [activeTab, setActiveTab] = useState<TabName>("Movies");
  const [tabOffset, setTabOffset] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);
  const [isMacBook, setIsMacBook] = useState(false);
  const [searchMade, setSearchMade] = useState(false);
  const [characterCarousel, setCharacterCarousel] = useState<Cast[]>();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [searchHistory, setSearchHistory] = useState<string[]>([]);

  let start = 0;
  let startScroll = 0;
  let isDragging = false;
  const isVertical = true;

  const normalizeSearchTerm = (term: string): string => {
    return term.trim().toLowerCase();
  };

  const fetchPopularContent = useCallback(async (contentType: ContentType) => {
    setResourceIsLoading(true);
    try {
      const response = await getPopularContent(contentType, POPULAR_MOVIE_LIMIT, POPULAR_MOVIE_CAST_LIMIT);
      const processedResponse = response.map(item => {
        // Process main item slug
        const slug = processSlug(item);

        // Process cast for movies/series/animes/cartoons
        if ("cast" in item && item.cast) {
          const processedCast = item.cast.map(castMember => ({
            ...castMember,
            slug: generateSlug(castMember.character)
          }));
          return { ...item, slug, cast: processedCast };
        }

        // Process filmography for actors (preserving existing type structure)
        if ("filmography" in item && item.filmography) {
          // Process filmography characters
          const processedFilmography = {
            ...item.filmography,
            characters: item.filmography.characters.map((character: Cast) => ({
              ...character,
              slug: generateSlug(character.character)
            }))
          };
          return { ...item, slug, filmography: processedFilmography };
        }

        return { ...item, slug };
      });

      setPopularContent(prevContent => ({
        ...prevContent,
        [contentType]: processedResponse
      }));
      setWithExpiry(getPopularKey(contentType), JSON.stringify(processedResponse), CACHE_EXPIRY);
      updateSlugCache(processedResponse, contentType);
    } catch (error) {
      Sentry.captureException(error);
      log.error(`Error fetching popular ${contentType}:`, error);
      toast.error(`Error fetching popular ${contentType}.`);
    } finally {
      setResourceIsLoading(false);
    }
  }, []);

  const loadPopularContent = useCallback((contentType: ContentType) => {
    const cachedContent = getWithExpiry(getPopularKey(contentType));

    console.log("type", contentType);
    console.log(getPopularKey(contentType));
    console.log("cachedContent", cachedContent);

    if (cachedContent) {
      try {
        const parsedContent = JSON.parse(cachedContent);

        // Check if parsedContent is empty
        if (!parsedContent || parsedContent.length === 0) {
          log.info(`Cached ${contentType} content is empty, fetching new data`);
          fetchPopularContent(contentType);
        } else {
          setPopularContent(prevContent => ({
            ...prevContent,
            [contentType]: parsedContent
          }));
          log.info(`Loaded cached ${contentType} content`);
        }

      } catch (error) {
        Sentry.captureException(error);
        log.error(`Error parsing cached ${contentType} content:`, error);
        fetchPopularContent(contentType);
      }
    } else {
      log.info(`No cached ${contentType} content, fetching new data`);
      fetchPopularContent(contentType);
    }
  }, [fetchPopularContent]);

  useEffect(() => {
    const contentTypes: ContentType[] = ["movies", "series", "animes", "actors", "cartoons"];
    contentTypes.forEach(loadPopularContent);
  }, [loadPopularContent]);

  const toTabName = (contentType: ContentType): TabName => {
    return contentType.charAt(0).toUpperCase() + contentType.slice(1) as TabName;
  };

  useEffect(() => {
    const getTopCharactersPerItem = (items: Movie[], countPerItem: number): Cast[] => {
      return items.flatMap(item =>
        (item.cast || [])
          .filter(character =>
            character.name &&
            character.profile_path &&
            character.character
          )
          .sort((a, b) => (b.popularity || 0) - (a.popularity || 0))
          .slice(0, countPerItem)
          .map(character => ({
            ...character,
            from_movie: item.title || item.original_title || item.name || ""
          }))
      );
    };

    const shuffleArray = (array: any[]) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    const hashContent = (content: ContentResults): string => {
      const contentString = JSON.stringify(content);
      let hash = 0;
      for (let i = 0; i < contentString.length; i++) {
        const char = contentString.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
      }
      return hash.toString();
    };

    const currentHash = hashContent(popularContent);
    const cachedCarousel = localStorage.getItem("characterCarousel");
    const cachedHash = localStorage.getItem("characterCarouselHash");

    if (cachedCarousel && cachedHash && cachedHash === currentHash) {
      setCharacterCarousel(JSON.parse(cachedCarousel));
    } else {
      const topMovieCharacters = getTopCharactersPerItem(popularContent.movies, 10);
      const topSeriesCharacters = getTopCharactersPerItem(popularContent.series, 10);
      const topAnimeCharacters = getTopCharactersPerItem(popularContent.animes, 10);
      const topCartoonCharacters = getTopCharactersPerItem(popularContent.cartoons, 10);

      const allTopCharacters = [
        ...topMovieCharacters,
        ...topSeriesCharacters,
        ...topAnimeCharacters,
        ...topCartoonCharacters
      ];

      const messagesJson = getWithoutExpiry("messages");
      const messageCharacters: Cast[] = [];
      if (messagesJson) {
        try {
          const parsedData = JSON.parse(messagesJson); // parsedData is an object
          for (const characterName in parsedData) {
            const messagesArray = parsedData[characterName];
            // Extract profileImage from the first message
            const profileImage = messagesArray[0]?.profileImage || null;
            if (!profileImage) {
              continue;
            }
            messageCharacters.push({
              name: characterName,
              profile_path: profileImage
                ? profileImage
                : `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`,
              character: characterName,
              from_movie: "", // If you have movie info, you can include it here
              isFromMessages: true
            });
          }
        } catch (error) {
          Sentry.captureException(error);
          console.error("Error parsing messages JSON:", error);
        }
      }

      log.info("messageCharacters:", messageCharacters);
      const shuffledCharacters = shuffleArray(allTopCharacters);

      const allCharacters = [
        ...shuffledCharacters,
        ...messageCharacters
      ];

      const isPlaceholderOrQuestionmark = (imagePath: string): boolean => {
        return imagePath.includes("Placeholder-Portrait.jpg") ||
          imagePath.includes("questionmark") ||
          imagePath === `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`;
      };

      const uniqueCharacters = allCharacters.reduce((acc: Cast[], current) => {
        const isDuplicate = acc.find(item => item.name === current.name);
        if (!isDuplicate && (!isPlaceholderOrQuestionmark(current.profile_path) || current.isFromMessages)) {
          acc.push(current);
        }
        return acc;
      }, []);

      const combinedShuffledCharacters = shuffleArray(uniqueCharacters);

      setCharacterCarousel(combinedShuffledCharacters);
      localStorage.setItem("characterCarousel", JSON.stringify(combinedShuffledCharacters));
      localStorage.setItem("characterCarouselHash", currentHash);
    }
  }, [popularContent]);

  useEffect(() => {
    const storedSearchTerm = sessionStorage.getItem("searchTerm");
    const storedActiveTab = sessionStorage.getItem("activeTab") as TabName | null;
    const storedSearchResults = sessionStorage.getItem(getSearchResultsKey(storedActiveTab?.toLowerCase() as ContentType));
    if (storedSearchTerm && storedActiveTab) {
      setSearchTerm(storedSearchTerm);
      setActiveTab(storedActiveTab);
      if (storedSearchResults) {
        setSearchResults(JSON.parse(storedSearchResults));
        setSearchMade(true);
      } else {
        fetchSearchResults(storedSearchTerm, storedActiveTab);
      }
    }
  }, []);


  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];

    if (macosPlatforms.indexOf(platform) !== -1 && userAgent.indexOf("Chrome") !== -1) {
      setIsMacBook(true);
    }
  }, []);

  useEffect(() => {
    // Load search history from localStorage
    const loadSearchHistory = () => {
      const history = localStorage.getItem("searchHistory");
      if (history) {
        try {
          const parsedHistory = JSON.parse(history);
          setSearchHistory(parsedHistory);
        } catch (error) {
          console.error("Error parsing search history:", error);
        }
      }
    };
    loadSearchHistory();
  }, []);

  const updateSearchHistory = (term: string) => {
    if (term.trim().length < 3) return;

    setSearchHistory(prevHistory => {
      const newHistory = [term, ...prevHistory.filter(item => item !== term)].slice(0, 10);
      localStorage.setItem("searchHistory", JSON.stringify(newHistory));
      return newHistory;
    });
  };

  const onDragStart = (e: React.MouseEvent) => {
    if (e.button !== 0) return; // Ensure left click only
    start = isVertical ? e.clientY : e.clientX;
    if (containerRef.current) {
      startScroll = isVertical
        ? containerRef.current.scrollTop
        : containerRef.current.scrollLeft;
    }
    isDragging = true;
    document.addEventListener("mousemove", onDragging);
    document.addEventListener("mouseup", onDragEnd);
    e.preventDefault(); // Prevent text selection or other default actions
  };

  const onDragging = (e: MouseEvent) => {
    if (!isDragging || !containerRef.current) return;
    const diff = isVertical ? e.clientY - start : e.clientX - start;
    if (isVertical) {
      containerRef.current.scrollTop = startScroll - diff;
    } else {
      containerRef.current.scrollLeft = startScroll - diff;
    }
  };

  const onDragEnd = () => {
    isDragging = false;
    document.removeEventListener("mousemove", onDragging);
    document.removeEventListener("mouseup", onDragEnd);
  };


  const topMovies = [
    "Avatar",
    "Avengers: Endgame",
    "Titanic",
    "Star Wars: The Force Awakens",
    "Avengers: Infinity War",
    "Jurassic World",
    "The Lion King",
    "The Avengers",
    "Furious 7",
    "Frozen II"
  ];

  const clearSearch = () => {
    setSearchTerm("");
    setSearchResults({ movies: [], series: [], animes: [], cartoons: [], actors: [] });
    setSearchMade(false);
    sessionStorage.removeItem("searchTerm");
    sessionStorage.removeItem("activeTab");
    sessionStorage.removeItem("searchResults");

    // Use the activeTab to determine the category
    const category = activeTab.toLowerCase() as ContentType;
    sessionStorage.removeItem(getSearchResultsKey(category));
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const normalizedValue = normalizeSearchTerm(value);

    if (normalizedValue.length > 2) {
      const newTimeout = setTimeout(() => {
        fetchSearchResults(normalizedValue, activeTab);
      }, 1500);
      setSearchTimeout(newTimeout);
    } else if (value.length === 0) {
      clearSearch();
    }
  };

  const animationTimeline = useRef<GSAPTimeline>();

  const animateSearchResults = useCallback(() => {
    gsap.fromTo(".movies.scroll-container",
      { opacity: 0 },
      {
        opacity: 1,
        duration: 0.2,
        clearProps: "all"
      }
    );
  }, []);

  const fetchSearchResults = async (term: string, contentType: TabName) => {
    const normalizedTerm = normalizeSearchTerm(term);

    // Early return if the search term is too short
    if (normalizedTerm.length < 3) return;

    updateSearchHistory(term);

    setResourceIsLoading(true);
    setSearchMade(true);

    // Normalize the category and cache related logic
    const category = contentType.toLowerCase() as ContentType;
    const cachedSearches = getCachedSearches(category);

    // Store search term and active tab in session storage
    sessionStorage.setItem("searchTerm", normalizedTerm);
    sessionStorage.setItem("activeTab", contentType);

    try {
      // Check if the search results are already cached
      if (cachedSearches[normalizedTerm]) {
        const newSearchResults = { ...searchResults, [category]: cachedSearches[normalizedTerm] };
        setSearchResults(newSearchResults);
        sessionStorage.setItem(getSearchResultsKey(category), JSON.stringify(newSearchResults));
        animateSearchResults();
        return;
      }

      // Fetch results based on the content type
      let results: any;

      switch (contentType) {
        case "Movies":
          results = await searchMoviesWithCast(normalizedTerm);
          results = (results as Movie[]).map((movie: Movie) => ({
            ...movie,
            cast: movie.cast?.map((castMember: Cast) => ({
              ...castMember,
              slug: generateSlug(castMember.name)
            }))
          }));
          break;

        case "Series":
          results = await searchSeriesWithCast(normalizedTerm);
          results = (results as SeriesWithCast[]).map((series: SeriesWithCast) => ({
            ...series,
            cast: series.cast?.map((castMember: Cast) => ({
              ...castMember,
              slug: generateSlug(castMember.name)
            }))
          }));
          break;

        case "Animes":
          results = await searchAnimesWithCast(normalizedTerm);
          results = (results as SeriesWithCast[]).map((anime: SeriesWithCast) => ({
            ...anime,
            cast: anime.cast?.map((castMember: Cast) => ({
              ...castMember,
              slug: generateSlug(castMember.name)
            }))
          }));
          break;

        case "Cartoons":
          results = await searchCartoonsWithCast(normalizedTerm);
          results = (results as SeriesWithCast[]).map((cartoon: SeriesWithCast) => ({
            ...cartoon,
            cast: cartoon.cast?.map((castMember: Cast) => ({
              ...castMember,
              slug: generateSlug(castMember.name)
            }))
          }));
          break;

        case "Actors":
          results = await searchActorsFilmography(normalizedTerm);
          results = (results as ActorFilmography[]).map((actor) => ({
            ...actor,
            filmography: actor.filmography?.characters?.map((cast: Cast) => ({
              ...cast,
              slug: generateSlug(cast.character)
            }))
          }));
          break;

        default:
          throw new Error(`Unsupported content type: ${contentType}`);
      }

      // Update search results and cache
      const newSearchResults = { ...searchResults, [category]: results };
      setSearchResults(newSearchResults);
      sessionStorage.setItem(getSearchResultsKey(category), JSON.stringify(newSearchResults));
      addCachedSearch(category, normalizedTerm, results);

      // Animate the search results
      animateSearchResults();
    } catch (error) {
      // Handle errors
      Sentry.captureException(error);
      toast.error(`Error searching for ${contentType.toLowerCase()}`);
    } finally {
      setResourceIsLoading(false);
    }
  };

  const handleTabClick = (tab: TabName) => {
    setActiveTab(tab);
    sessionStorage.setItem("activeTab", tab);
    if (searchTerm) {
      fetchSearchResults(searchTerm, tab);
    }
  };


  const renderContent = () => {
    const content = searchMade ? searchResults[activeTab.toLowerCase() as keyof ContentResults] : popularContent[activeTab.toLowerCase() as keyof ContentResults];

    if (content.length === 0 && !searchMade) {
      return (
        <Grid
          container
          spacing={1}
          className="skeleton-container"
          sx={{
            padding: "8px",
            margin: 0,
            width: "100%",
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(2, 1fr)", // 2 columns on mobile
              sm: "repeat(3, 1fr)", // 3 columns on tablet
              md: "repeat(4, 1fr)", // 4 columns on desktop
              lg: "repeat(5, 1fr)"  // 5 columns on large screens
            },
            gap: 2
          }}
        >
          {[...Array(10)].map((_, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
                padding: "8px"
              }}
            >
              <Skeleton
                variant="rounded"
                width="100%"
                height={0}
                sx={{
                  paddingTop: "150%",
                  borderRadius: "12px",
                  bgcolor: "rgba(255, 255, 255, 0.08)",
                  transform: "none",
                  "&::after": {
                    content: "\"\"",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.04), transparent)",
                    animation: `${shimmer} 2s infinite`,
                    transform: "translateX(-100%)"
                  }
                }}
              />
              <Skeleton
                variant="text"
                width="70%"
                height={24}
                sx={{
                  bgcolor: "rgba(255, 255, 255, 0.08)",
                  borderRadius: "4px"
                }}
              />
            </Box>
          ))}
        </Grid>
      );
    }

    if (content.length === 0 && searchMade) {
      return <p style={{ color: "white" }}>No results found</p>;
    }

    if (activeTab.toLowerCase() == "actors") {
      return <MovieDisplay items={content} displayMode={searchMade ? "search" : "trending"} type="actor" />;
    } else {
      return <MovieDisplay items={content} displayMode={searchMade ? "search" : "trending"} type="movie" />;
    }
  };

  const labelText = useMemo(() => {
    const totalResults = Object.values(searchResults).flat().length;
    if (totalResults === 0) {
      switch (activeTab) {
        case "Movies":
          return "Trending Movies";
        case "Series":
          return "Trending Series";
        case "Animes":
          return "Trending Anime";
        default:
          return "Trending";
      }
    } else {
      return `${activeTab} Results`;
    }
  }, [searchResults, activeTab]);

  const modalContext = useContext(ModalContext);

  const handleModalOpen = () => {
    if (modalContext && modalContext.openModal) {
      modalContext.openModal();
    } else {
      console.error("Modal context or openModal function is not available");
    }
  };

  // Optimize initial render sequence
  useEffect(() => {
    const tl = gsap.timeline({
      defaults: {
        ease: "power2.out",
        duration: 0.4
      }
    });

    if (isMobile) {
      // Logo should be visible for brand recognition (1s)
      tl.fromTo(".logo-container",
        { opacity: 0 },
        { opacity: 1, duration: 0.3 })
        .to(".logo-container", { delay: 0.7 }) // Hold for visual recall
        // Subtle fade-in for main UI elements
        .fromTo(".search-bar",
          { opacity: 0, y: 10 },
          { opacity: 1, y: 0 },
          "-=0.1")
        .fromTo(".tab-container",
          { opacity: 0 },
          { opacity: 1 },
          "-=0.2");

      // Proper cleanup function
      return () => {
        tl.kill();
        return void 0;
      };
    }
  }, []);

  useEffect(() => {
    const initializeSearch = async () => {
      // Wait for popular content to be loaded
      if (Object.values(popularContent).some(arr => arr.length > 0)) {
        sessionStorage.setItem("searchComponentReady", "true");
      }
    };

    initializeSearch();

    return () => {
      sessionStorage.removeItem("searchComponentReady");
    };
  }, [popularContent]);

  useEffect(() => {
    let currentIndex = 0;
    const searchInput = document.querySelector(".search-input");

    const typePlaceholder = () => {
      if (searchInput) {
        const searchTerms = searchHistory.length > 0 ? searchHistory : topMovies;
        const term = searchTerms[currentIndex];
        let charIndex = 0;
        const typeChar = () => {
          if (charIndex < term.length) {
            searchInput.setAttribute(
              "placeholder",
              term.slice(0, charIndex + 1)
            );
            charIndex++;
            setTimeout(typeChar, 100);
          } else {
            currentIndex = (currentIndex + 1) % searchTerms.length;
            setTimeout(typePlaceholder, 5000);
          }
        };
        typeChar();
      }
    };

    typePlaceholder();

    const lightsweep = () => {
      gsap.fromTo(
        ".search-input",
        { backgroundPosition: "200% 0" },
        {
          backgroundPosition: "-200% 0",
          duration: 2,
          ease: "none",
          repeat: -1
        }
      );
    };

    lightsweep();

    return () => {
      gsap.killTweensOf(".search-input");
    };
  }, [searchHistory]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflow: "auto",
        position: "relative"
      }}
    >
      <Box
        sx={{
          flex: "1 0 auto",
          display: "flex",
          flexDirection: "column",
          paddingBottom: "200px" // Increased padding to ensure content is not cut off
        }}
      >
        <Seo
          title={"sceneXtras | AI Chats with your favorite movie characters"}
          description={"Search your favourite movies and characters."}
          url={`https://scenextras.com/`}
          image={"https://framerusercontent.com/images/mvyirly4lm7ajnERA4s7OwV59Nw.png"}
        />

        {isResourceLoading && <Loading />}

        <div className="header-container">
          {isMobile && (
            <div className="logo-container">
              <img
                alt="SceneXtras Logo"
                className="full-logo-inline"
                src={require("../../assets/img/scenextras-logo-1@2x.png")}
                style={{
                  willChange: "opacity",
                  height: "45px",
                  width: "auto"
                }}
              />
            </div>
          )}
          {user && user.isPremium !== "premium" && (
            <div className="upgrade-container">
              <button className="upgrade-button" onClick={handleModalOpen}>
                <span className="upgrade-button-text">Unlock features</span>
              </button>
            </div>
          )}
        </div>

        {characterCarousel && <CharacterCarousel cast={characterCarousel} />}

        <div ref={searchBarRef} className="search-bar" style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          borderRadius: "30px",
          willChange: "opacity, transform"
        }}>
          <input
            type="text"
            placeholder=""
            className="search-input lightsweep-effect"
            value={searchTerm}
            ref={searchInputRef}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                fetchSearchResults(searchTerm, activeTab);
              }
            }}
            onChange={handleSearchInputChange}
            style={{
              boxShadow: "none",
              outline: "none",
              filter: "none",
              WebkitAppearance: "none",
              backgroundColor: "transparent",
              border: "none",
              color: "white",
              flex: 1,
              marginLeft: "10px"
            }}
          />
          <CameraCaptureSearch />
          <div className="input-buttons">
            <button
              className={`search-button ${isMacBook ? "search-button-mac" : ""}`}
              onClick={() => fetchSearchResults(normalizeSearchTerm(searchTerm), activeTab)}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
            <GeneralChat />
            {searchTerm && (
              <button
                className="clear-search-button"
                onClick={clearSearch}
                title="Clear search"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
          </div>
        </div>

        <div className="tab-container" style={{
          transform: `translateX(${tabOffset}%)`,
          display: "flex",
          flexDirection: "row"
        }} ref={tabsRef}>
          <div
            className={`tab ${activeTab === "Movies" ? "active" : ""}`}
            onClick={() => handleTabClick("Movies")}
          >
            Movies
          </div>
          <div
            className={`tab ${activeTab === "Series" ? "active" : ""}`}
            onClick={() => handleTabClick("Series")}
          >
            Series
          </div>
          <div
            className={`tab ${activeTab === "Animes" ? "active" : ""}`}
            onClick={() => handleTabClick("Animes")}
          >
            Anime
          </div>
          <div className={`tab ${activeTab === "Cartoons" ? "active" : ""}`}
               onClick={() => handleTabClick("Cartoons")}
          >
            Cartoons
          </div>
          <div className={`tab ${activeTab === "Actors" ? "active" : ""}`}
               onClick={() => handleTabClick("Actors")}
          >
            Actors
          </div>
        </div>

        <label className="trending mb-0">
          {labelText}
        </label>

        <div className="movies scroll-container" style={{ flex: 1 }}>
          <div
            ref={containerRef}
            onMouseDown={onDragStart}
            className="search-movies item"
            style={{ marginBottom: "24px" }}
          >
            {renderContent()}
          </div>
        </div>
      </Box>

      <Box
        sx={{
          position: "relative",
          marginTop: "auto",
          width: "100%",
          backgroundColor: "rgba(28, 28, 28, 0.95)"
        }}
      >
        <Footer />
      </Box>
    </Box>
  );
}
