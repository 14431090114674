import React, { useEffect, useRef, useState } from "react";
import { getPopularMoviesWithCast, getRandomMovieCharacters } from "../../api/apiClient";
import "../../assets/css/chat.css";
import { gsap } from "gsap";
import scenegptEatingPopcorn from "../../assets/lotties/sceneGPT.jpg";
import { Movie, SeriesWithCast } from "../../types/MovieType";
import { Cast } from "../../types/CastType";
import TopMenuBar from "../template/TopMenuBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/img/scenextras_logo.svg";
import {
  getWithExpiry,
  getWithoutExpiry,
  RECENT_CHARACTERS_KEY,
  setWithExpiry,
  setWithoutExpiry
} from "../../helper/storageUtils";
import "../../assets/css/chatmenubtn.scss";
import Loading from "../miscs/Loading";
import InnerChat from "./InnerChat";
import posthog from "posthog-js";
import AdConsentModal from "../modals/AdConsentModal";
import { toast } from "react-toastify";
import log from "loglevel";
import SurveyModal from "../modals/SurveyModal";
import { AuthenticatedUser, convertAuthenticatedUserToUser } from "../../helper/authHelper";
import Seo from "../../helper/Seo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImdb, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faArrowUpFromBracket, faComments, faGlobe, faLock } from "@fortawesome/free-solid-svg-icons";
import CheckoutModal from "../payments/CheckoutModal";
import { getPopularKey } from "../../config";
import { Session } from "@supabase/supabase-js";
import * as Sentry from "@sentry/react";
import { findContentBySlug, generateSlug, processSlug } from "../search/Search";
import useIsMobile from "../hooks/useIsMobile";


interface Message {
  sender: "user" | "character";
  content: string;
  audio?: string;
  chunks?: string[];
  chunkTypes?: ("text" | "action" | "pause" | "emphasis")[];
}

interface TranscriptCallResponseType {
  transcript: string;
  sent_audio_file: string;
  audio_file: string;
  message: string;
  remaining_quota: number;
  session_token: string;
}

export interface ChatProps {
  movie?: Movie;
  chat_type?: string;
  character?: Cast;
  cast?: Cast[];
  user?: AuthenticatedUser;
  session?: Session | null;
}

interface LocationState {
  movie: Movie;
}

const sceneGPTCharacter: Cast = {
  adult: false,
  gender: 0,
  id: 99999,
  known_for_department: "Suggestions",
  name: ["Streaming Companion, AMA!", "pov: you've been scrolling for recs for 30 mins. i got u bestie", "your friendly neighborhood binge enabler"][Math.floor(Math.random() * 3)],
  original_name: "Scenester",
  popularity: 10,
  profile_path: scenegptEatingPopcorn,
  cast_id: 123,
  character: "Scenester",
  credit_id: "abc123",
  order: 1,
  from_movie: "",
  alt: "SceneGPT eating popcorn"
};


const Chat: React.FC<ChatProps> = (props) => {
  const { "*": token } = useParams<{ "*": string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const consolidatedProps = {
    ...props,
    ...location.state,
    token
  };


  const {
    movie,
    character,
    chat_type,
    animate,
    cast,
    user
  } = consolidatedProps;

  console.log(consolidatedProps);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCast, setFilteredCast] = useState<Cast[]>([]);
  const [isResourceLoading, setResourceIsLoading] = useState(false);
  const [castState, setCastState] = useState<Cast[]>([]);
  const [gender, setGender] = useState<number | undefined>(undefined);
  const [ratingModalVisible, setRatingModalVisible] = useState(false);
  const [signUpModalVisible, setSignUpModalVisible] = useState(false);
  const [limitModalVisible, setLimitModalVisible] = useState(false);
  const [adConsentModal, setAdConsentModal] = useState(false);
  const [subscribeModal, setSubscribeModal] = useState(false);
  const [surveyModal, setSurveyModal] = useState(false);
  const [chatType, setChatType] = useState<string>(chat_type);
  const [selectedCharacterObject, setSelectedCharacterObject] = useState<Cast | undefined>(location.state?.character);
  const [movieObject, setMovieObject] = useState<Movie | SeriesWithCast>(movie);
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const isMobile = useIsMobile();

  console.log(selectedCharacterObject);

  const chatContainerStyle = animate
    ? { animation: "slideInFromRight 0.5s forwards" }
    : {};

  const chatContainerClass = animate ? "slide-in-right" : "";

  useEffect(() => {
    try {
      setResourceIsLoading(true);
      if (chatType == "popular") {
        const storedPopularMovies: Movie[] = JSON.parse(getWithExpiry(getPopularKey("movies")));
        if (storedPopularMovies) {
          const firstCastFromEachMovie = storedPopularMovies
            .map((movie) => movie.cast && movie.cast.length > 0 ? {
              ...movie.cast[0],
              from_movie: movie.title || movie.original_title
            } : null)
            .filter((cast) => cast !== null);
          setMovieObject({ ...movieObject, cast: firstCastFromEachMovie as Cast[] });
        } else {
          getPopularMoviesWithCast(props.session).then((response) => {
            // Get the first cast from each movie
            const firstCastFromEachMovie = response
              .map((movie) => movie.cast && movie.cast.length > 0 ? {
                ...movie.cast[0],
                from_movie: movie.title || movie.original_title
              } : null)
              .filter((cast) => cast !== null);
            setMovieObject({ ...movieObject, cast: firstCastFromEachMovie as Cast[] });
            setWithExpiry(
              getPopularKey("movies"),
              JSON.stringify(response),
              3 * 60 * 1000
            );
          });
        }
        setResourceIsLoading(false);
      } else if (chatType === "routing") {
        setMovieObject(movie);
        if (character) {
          setSelectedCharacterObject(character);
        }
      } else if (chatType === "Scenester") {
        setGender(0);
        setSelectedCharacterObject({
          character: "Scenester",
          profile_path: `${window.location.origin}/scenester.jpg`,
          name: "Scenester",
          from_movie: ""
        });
      } else if (chatType === "carousel") {
        setSelectedCharacterObject(character);
        setMovieObject({ ...movieObject, cast: cast as Cast[] });
      }
      setResourceIsLoading(false);
    } catch (e) {
      log.error(e);
      Sentry.captureException(e);
      toast.error("Error fetching cast data");
    }
  }, []);

  useEffect(() => {
    if (selectedCharacterObject) {
      setGender(selectedCharacterObject.gender || 0);
    } else {
      setGender(0);
    }
  }, [selectedCharacterObject]);

  useEffect(() => {
    if (searchTerm.trim()) {
      const lowercasedTerm = searchTerm.toLowerCase();
      if (chatType != "actor") {
        setFilteredCast(
          [sceneGPTCharacter, ...movieObject.cast].filter((char) =>
            char.name.toLowerCase().includes(lowercasedTerm) ||
            char.character.toLowerCase().includes(lowercasedTerm) ||
            (char.from_movie && char.from_movie.toLowerCase().includes(lowercasedTerm))
          )
        );
      } else {
        setFilteredCast(movieObject.cast.filter(char =>
          char.name.toLowerCase().includes(lowercasedTerm) ||
          char.character.toLowerCase().includes(lowercasedTerm) ||
          (char.from_movie && char.from_movie.toLowerCase().includes(lowercasedTerm))
        ));
      }
    } else if (movieObject) {

      if (chatType != "actor") {
        setFilteredCast([sceneGPTCharacter, ...movieObject.cast]);
      } else {
        setFilteredCast(movieObject.cast);
      }


    }
  }, [searchTerm, movieObject]);

  useEffect(() => {
    if (movieObject) {
      const sceneGPTIndex = movieObject.cast.findIndex(char => char.id === 99999);
      if (sceneGPTIndex === -1) {
        if (chatType != "actor") {
          setFilteredCast([sceneGPTCharacter, ...movieObject.cast]);
        } else {
          setFilteredCast(movieObject.cast);
        }
      } else {
        setFilteredCast(movieObject.cast);
      }
    }
  }, [movieObject]);

  const chatContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContentRef.current) {
      gsap.from(chatContentRef.current, {
        duration: 1,
        opacity: 0,
        y: -20,
        ease: "power2.out"
      });
    }
  }, [chatContentRef, location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      window.scrollTo(0, 0);
      // Force re-render
      setChatMessages([...chatMessages]);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, [chatMessages]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const saveRecentCharacter = (character: Cast, movieObject: Movie | SeriesWithCast) => {
    // Get existing recent characters from localStorage
    if (!character.from_movie) {
      character.from_movie = movieObject.title;
    }

    let recentCharacters: Cast[] = getWithoutExpiry(RECENT_CHARACTERS_KEY) || [];

    // Remove the character if it already exists in the list
    recentCharacters = recentCharacters.filter(c => c.id !== character.id);

    // Add the new character to the beginning of the list
    recentCharacters.unshift(character);

    // Save the updated list back to localStorage
    setWithoutExpiry(RECENT_CHARACTERS_KEY, recentCharacters);
  };

  const handleCharacterSelect = (character: Cast) => {
    log.info(character);
    log.info("handleCharacterSelect: " + character.name);
    log.info(movieObject);

    if (!movieObject) {
      setMovieObject({ title: character.from_movie } as Movie);
    }
    if (chat_type != "movie") {
      setMovieObject({ ...movieObject, title: character.from_movie });
    }

    setSelectedCharacterObject(character);

    if (!token) {
      log.error("[ChatRouter] No path provided");
      return;
    }

    const slugs = token.split("/");

    const { content, character: slug_character, type } = findContentBySlug(slugs);

    console.log(content);
    console.log(character);

    const newPath = `/chat/${generateSlug(movie.title)}/${generateSlug(character.character)}`;
    window.history.replaceState(
      { ...window.history.state, characterId: character.id },
      "",
      newPath
    );

    setTimeout(() => {
      saveRecentCharacter(character, movieObject);
    }, 5000);
  };

  const changeCharacterInfiniteScroll = async () => {
    setResourceIsLoading(true);
    try {
      const response: Movie | SeriesWithCast = await getRandomMovieCharacters();
      if (response && response.cast) {
        setMovieObject(response);
        const randomIndex = Math.floor(Math.random() * response.cast.length);
        setMovieObject(response);
        setSelectedCharacterObject(response.cast[randomIndex]);


        posthog.capture("infinite_scroll", {
          movie: response.title,
          character: response.cast[randomIndex].character
        });
      }
    } catch (e) {
      log.error(e);
      Sentry.captureException(e);
      toast.error("Error fetching cast data");
    }
    setResourceIsLoading(false);
  };

  const setNextCharacter = () => {
    if (movieObject && movieObject.cast) {
      const currentIndex = movieObject.cast.findIndex(
        (c: Cast) => c.id === selectedCharacterObject?.id
      );
      const nextIndex = (currentIndex + 1) % movieObject.cast.length;
      const nextCharacter = movieObject.cast[nextIndex];
      posthog.capture("next_character", { character: nextCharacter.character });
      setSelectedCharacterObject(nextCharacter);
    }
  };

  const setPreviousCharacter = () => {
    if (movieObject && movieObject.cast) {
      const currentIndex = movieObject.cast.findIndex(
        (c: Cast) => c.id === selectedCharacterObject?.id
      );
      const prevIndex = (currentIndex - 1 + movieObject.cast.length) % movieObject.cast.length;
      const prevCharacter = movieObject.cast[prevIndex];
      posthog.capture("previous_character", { character: prevCharacter.character });
      setSelectedCharacterObject(prevCharacter);
    }
  };

  const svgBackground = {
    backgroundImage: `url(${process.env.PUBLIC_URL}background.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    height: "100%"
  };
  const hasConsented = getWithoutExpiry("adConsent");

  function getCharacterAvatarSrc(profilePath: string | null): string {
    if (!profilePath) {
      return `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`;
    }

    if (profilePath.includes("/static/media/")) {
      return profilePath;
    }

    return profilePath.startsWith("https")
      ? profilePath
      : `https://image.tmdb.org/t/p/w500${profilePath}`;
  }

  const splitMessageIntoChunks = (message: string): {
    chunks: string[],
    types: ("text" | "action" | "pause" | "emphasis")[]
  } => {
    // Split on natural breaks like periods followed by newlines, or multiple newlines
    const rawChunks = message.split(/(?<=\.)\n|\n{2,}/g);

    const chunks: string[] = [];
    const types: ("text" | "action" | "pause" | "emphasis")[] = [];

    rawChunks.forEach(chunk => {
      const trimmedChunk = chunk.trim();
      if (!trimmedChunk) {
        chunks.push("");
        types.push("pause");
        return;
      }

      // Detect action lines (text between asterisks or starting with actions like "thinks" or "pauses")
      if (trimmedChunk.match(/^\*.*\*$/) || trimmedChunk.match(/^(thinks|pauses|smiles|laughs|nods)/i)) {
        chunks.push(trimmedChunk);
        types.push("action");
        return;
      }

      // Detect emphasis (text between underscores or in all caps)
      if (trimmedChunk.match(/^_.*_$/) || trimmedChunk === trimmedChunk.toUpperCase()) {
        chunks.push(trimmedChunk);
        types.push("emphasis");
        return;
      }

      // Regular text
      chunks.push(trimmedChunk);
      types.push("text");
    });

    return { chunks, types };
  };

  const processNewMessage = (message: string, sender: "user" | "character") => {
    const { chunks, types } = splitMessageIntoChunks(message);
    const newMessage: Message = {
      sender,
      content: message,
      chunks,
      chunkTypes: types
    };
    setChatMessages(prev => [...prev, newMessage]);
  };

  const renderMessageChunks = (message: Message) => {
    if (!message.chunks) {
      return <div className="message-content">{message.content}</div>;
    }

    return (
      <div className="chunk-group">
        {message.chunks.map((chunk, index) => (
          <div
            key={index}
            className={`message-chunk ${message.chunkTypes?.[index] || "text"}`}
            style={{
              animationDelay: `${index * 150}ms`
            }}
          >
            {chunk}
            {message.chunkTypes?.[index] === "pause" && (
              <div className="typing-between-chunks">...</div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const handleShare = async () => {
    const currentUrl = window.location.href;

    try {
      if (isMobile && navigator.share && chatType != "popular") {
        // Mobile native share
        await navigator.share({
          title: movieObject.title,
          text: `${movie.overview}\n\n`,
          url: currentUrl
        });
      } else {
        // Desktop clipboard copy
        await navigator.clipboard.writeText(currentUrl);
        toast.success("Profile link copied to clipboard!");
      }
    } catch (err) {
      if (err instanceof Error && err.name === "AbortError") return;

      console.error("Sharing failed:", err);
      await navigator.clipboard.writeText(currentUrl);
      toast.success("Profile link copied to clipboard!");
    }
  };

  useEffect(() => {
    console.log("useEffect");
    if (selectedCharacterObject?.character && typeof window !== "undefined" && movie && movie.title) {
      console.log("useEffect = 1");
      const movieSlug = movieObject ? generateSlug(movieObject.title ? movieObject.title : "") : generateSlug(movie.title);
      const characterSlug = `${generateSlug(selectedCharacterObject.character)}`;
      window.history.replaceState(null, "", `/chat/${movieSlug}/${characterSlug}`);
      return;
    }


    if (selectedCharacterObject != undefined) {
      console.log("useEffect = 2");
      const movieSlug = generateSlug(selectedCharacterObject.from_movie);
      const characterSlug = `${generateSlug(selectedCharacterObject.character)}`;
      window.history.replaceState(null, "", `/chat/${movieSlug}/${characterSlug}`);
    }
  }, [selectedCharacterObject, movie]);

  return (
    <>
      <Seo
        title={`Chat with ${token ? (() => {
          const segments = token.split("/");
          // If we have both movie and character, show only character
          if (segments.length > 1) {
            return segments[1].split("-")
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");
          }
          // If we only have movie, show movie
          return segments[0].split("-")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        })() : ""} | sceneXtras | Experience AI Fanfiction Chats with characters from your favorite stories`}
        description={`Chat with ${token ? token.split("/").map(slug =>
          slug.split("-")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        ).join(" - ") : "your favourite characters"} - sceneXtras | Experience AI Fanfiction Chats with characters from your favorite stories`}
        url={`https://scenextras.com/chat/${token || ""}`}
        image={"https://framerusercontent.com/images/mvyirly4lm7ajnERA4s7OwV59Nw.png"}
      />

      {isResourceLoading && <Loading />}
      <div style={svgBackground}>
        <div
          className="top-bar"
          onClick={() => {
            navigate("/");
          }}
        >
        </div>
        {!selectedCharacterObject && (
          <>
            <TopMenuBar />
            <div className="movie-header">
              {chatType === "actor" ? (
                <div className="actor-header-container">
                  <div className="actor-poster-wrapper">
                    <div className="actor-poster-container">
                      <img
                        src={movieObject.poster_path}
                        alt={movieObject.title}
                        className="movie-poster"
                        loading="eager"
                      />
                    </div>
                  </div>
                  <div className="actor-info-container">
                    <h1 className="actor-name">{movieObject.title}</h1>
                    {movieObject.actorInfo?.external_ids && (
                      <div className="actor-social-links">
                        {movieObject.actorInfo.external_ids.imdb_id && (
                          <a
                            href={`https://www.imdb.com/name/${movieObject.actorInfo.external_ids.imdb_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="actor-social-link"
                          >
                            <FontAwesomeIcon icon={faImdb} />
                          </a>
                        )}
                        {movieObject.actorInfo.external_ids.twitter_id && (
                          <a
                            href={`https://twitter.com/${movieObject.actorInfo.external_ids.twitter_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="actor-social-link"
                          >
                            <FontAwesomeIcon icon={faTwitter} />
                          </a>
                        )}
                        {movieObject.actorInfo.external_ids.wikidata_id && (
                          <a
                            href={`https://www.wikidata.org/wiki/${movieObject.actorInfo.external_ids.wikidata_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="actor-social-link"
                          >
                            <FontAwesomeIcon icon={faGlobe} />
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <div className="movie-header-content">
                      <p data-id={movieObject ? movieObject.title : ""} className="cast-headline">
                        {chatType == "popular" ? "Recent Chats" : chatType == "Scenester" ? "SceneGPT" : movieObject.title}
                      </p>

                      <button
                        className="action-button share-button"
                        onClick={handleShare}
                        aria-label="Share"
                      >
                        <FontAwesomeIcon icon={faArrowUpFromBracket} />
                      </button>
                    </div>

                  </div>
                  {movieObject && "overview" in movieObject && chatType !== "popular" && chatType !== "Scenester" && (
                    <div className="movie-meta-info">
                      <div className="movie-meta-row">
                        <>
                          {"vote_average" in movieObject && (
                            <span className="movie-rating">{Number(movieObject.vote_average).toFixed(1)}</span>
                          )}
                          {"release_date" in movieObject && (
                            <span className="movie-year">{movieObject.release_date?.split("-")[0]}</span>
                          )}
                          {"runtime" in movieObject && movieObject.runtime && (
                            <span className="movie-runtime">{`${movieObject.runtime}m`}</span>
                          )}
                        </>
                      </div>
                      <p className="movie-overview">
                        {String(movieObject.overview).length > 120
                          ? `${String(movieObject.overview).substring(0, 120)}...`
                          : String(movieObject.overview)}
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}

        {/*### TODO: WHEN EMPTY, MESSAGE = RECENT CHAT => SEARCH RECENT CHARACTERS, otherwise SEARCH CHARACTERS */}
        {!selectedCharacterObject && (
          <div className="character-search-bar">
            <input
              type="text"
              placeholder="Search characters..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
          </div>
        )}
        {selectedCharacterObject ? (
          <InnerChat
            chat_type={chatType}
            setSelectedCharacterObject={setSelectedCharacterObject}
            selectedCharacterObject={selectedCharacterObject}
            ratingModalVisible={ratingModalVisible}
            subscribeModalVisible={subscribeModal}
            changeCharacterInfiniteScroll={changeCharacterInfiniteScroll}
            setPreviousCharacter={setPreviousCharacter}
            setNextCharacter={setNextCharacter}
            signUpModalVisible={signUpModalVisible}
            setSignUpModalVisible={setSignUpModalVisible}
            setRatingModalVisible={setRatingModalVisible}
            movie={movieObject}
            setLimitModalVisible={setLimitModalVisible}
            setAdConsentModal={setAdConsentModal}
            setSubscribeModal={setSubscribeModal}
            setSurveyModal={setSurveyModal}
            user={user}
            session={props.session}
          />
        ) : (
          <>
            {chatType == "actor" ? (
              // AUTOR FLOW
              <div
                className={`chat-container chat-container-not-in-chat ${chatContainerClass}`}
                style={chatContainerStyle}
              >
                <div className="character-list">
                  {filteredCast &&
                    filteredCast.length > 0 &&
                    filteredCast.map((inner_char, index) => (
                      <div key={index}>
                        <div
                          className={`character-list-item character-list-item-${index}`}
                          onClick={() => handleCharacterSelect(inner_char)}
                        >
                          <div className="character-container">
                            <img
                              className="character-avatar"
                              src={getCharacterAvatarSrc(inner_char.profile_path)}
                              alt={inner_char.id === 99999 && inner_char.alt ? inner_char.alt : `${inner_char.name} Avatar`}
                            />
                            <div className="character-info">
                              <span className="character-name">{inner_char.name.replace("(voice)", "")}</span>
                              {inner_char.from_movie && (
                                <span className="character-movie">{inner_char.from_movie}</span>
                              )}
                            </div>
                          </div>
                          <div>
                            <img
                              className="chat-icon"
                              src={`${process.env.PUBLIC_URL}/chat_icon.svg`}
                              alt="chat-icon"
                            />
                          </div>
                        </div>
                        {inner_char.id === 99999 && (
                          <div className="scenegpt-delimiter">
                            <hr className="scenegpt-hr" />
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div
                className={`chat-container chat-container-not-in-chat ${chatContainerClass}`}
                style={chatContainerStyle}
              >
                {props.user && (
                  <CheckoutModal
                    isOpen={subscribeModal}
                    onClose={() => setSubscribeModal(false)}
                    user={convertAuthenticatedUserToUser(props.user)}
                    displaySource={"account"}
                  />

                )}
                <div className="character-list">
                  {filteredCast &&
                  filteredCast.length > 0 &&
                  filteredCast.filter(char => char.id !== 99999).length > 0 ? (
                    filteredCast.map((inner_char, index) => {
                      const isLocked = index > 4 && props.user && props.user.isPremium != "premium";
                      return (
                        <div key={index}>
                          <div
                            className={`character-list-item ${inner_char.id === 99999 ? "scenegpt-character" : ""} ${isLocked ? "locked" : ""}`}
                            onClick={isLocked ? () => setSubscribeModal(true) : () => handleCharacterSelect(inner_char)}
                          >
                            <div className="character-container">
                              <div className="character-avatar-wrapper">
                                <img
                                  className="character-avatar"
                                  src={getCharacterAvatarSrc(inner_char.profile_path)}
                                  alt={
                                    inner_char.id === 99999 && inner_char.alt
                                      ? inner_char.alt
                                      : `${inner_char.name} Avatar`
                                  }
                                />
                                {isLocked && (
                                  <div className="lock-overlay">
                                    <FontAwesomeIcon icon={faLock} />
                                    <div className="max-label-lock">MAX</div>
                                  </div>
                                )}
                              </div>
                              <div className="character-name">
                                <span>{inner_char.character.replace("(voice)", "")}</span>
                                <br />
                                <span className="character-name-small">{inner_char.name}</span>
                              </div>
                            </div>
                            {chatType !== "history" ? (
                              <div>
                                <FontAwesomeIcon icon={faComments} size="1x" className="chat-icon" />
                              </div>
                            ) : (
                              inner_char.lastMessageTime && (
                                <div className="time-ago-container">
                                  <div className="time-ago">{inner_char.lastMessageTime}</div>
                                  {index === 1 && <div className="notification-count">1</div>}
                                </div>
                              )
                            )}
                          </div>
                          {inner_char.id === 99999 && (
                            <div className="scenegpt-delimiter">
                              <hr className="scenegpt-hr" />
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className="empty-chat-state"
                         onClick={() => navigate("/")}
                         style={{
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "center",
                           justifyContent: "center",
                           padding: "2rem",
                           margin: "2rem auto",
                           maxWidth: "300px",
                           textAlign: "center",
                           background: "rgba(255, 255, 255, 0.05)",
                           borderRadius: "12px",
                           backdropFilter: "blur(10px)",
                           cursor: "pointer"
                         }}>
                      <FontAwesomeIcon
                        icon={faComments}
                        style={{
                          fontSize: "2.5rem",
                          marginBottom: "1rem",
                          opacity: 0.6,
                          color: "var(--text-color-secondary)"
                        }}
                      />
                      <h3 style={{
                        margin: "0 0 0.5rem 0",
                        color: "var(--text-color-primary)",
                        fontWeight: "500"
                      }}>
                        {chatType === "history" ? "No Recent Chats" : "No Characters Found"}
                      </h3>
                      <p style={{
                        margin: 0,
                        fontSize: "0.9rem",
                        color: "var(--text-color-secondary)",
                        lineHeight: "1.4"
                      }}>
                        {chatType === "history" ? (
                          <>
                            <p style={{
                              margin: 0,
                              fontWeight: "bold",
                              cursor: "pointer"
                            }} onClick={() => navigate("/")}>
                              Browse characters on the home screen to start chatting
                            </p>
                          </>
                        ) : (
                          "Try adjusting your search or explore different characters"
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {ratingModalVisible && (
        // <RatingModal onClose={() => setRatingModalVisible(false)}/>
        <></>
      )}

      {/*{signUpModalVisible && <SignUpModal />}*/}

      {/* LIMIT MODAL DISABLED FOR NOW*/}
      {/*{limitModalVisible && <LimitModal setVisibility={setLimitModalVisible} />}*/}
      {adConsentModal && (
        <AdConsentModal setVisibility={setAdConsentModal} />
      )}


      {
        surveyModal && <SurveyModal setVisibility={setSurveyModal} />
      }
    </>
  );
};

export default Chat;