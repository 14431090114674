import React from "react";
import "../../css/AdConsentModal.scss";
import { getWithoutExpiry } from "../../helper/storageUtils";

interface AdConsentModalProps {
  setVisibility: (e: any) => void;
}

const SurveyModal: React.FC<AdConsentModalProps> = ({ setVisibility }) => {
  let text = "  Interested in joining us for a quick chat? We value your feedback,\n" +
    "            it's vital to our growth. Let's chat!";

  if (getWithoutExpiry("messages_count") == 15) {
    text = "YOOOO, you're on a roll, want even more credits? Book a call with us and we'll throw some more your way!";
  }

  const handleSchedule = () => {
    window.open("https://calendly.com/jr-a-schmalz/30min", "_blank");
  };

  return (
    <div className="ad-consent-modal">
      <div className="ad-consent-content">
        <div className="ad-consent-header">
          <img src="/full_logo.svg" alt="Logo" className="ad-consent-logo" />
          <button className="ad-consent-close" onClick={() => setVisibility(false)}>
            &times;
          </button>
          <h2 className="ad-consent-title">Shape the Future with Us</h2>
        </div>
        <div className="ad-consent-body">
          <p className="ad-consent-description">
            {text}
          </p>
        </div>
        <div className="ad-consent-footer">
          <button onClick={handleSchedule} className="ad-consent-accept">
            Schedule
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveyModal;
