import React, { useEffect, useRef, useState } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { toast } from "react-toastify";
import log from "loglevel";
import * as Sentry from "@sentry/react";
import { supabase } from "../../helper/supabase_client";
import { shallow } from 'zustand/shallow';
import { useAuthStore } from '../../stores/useAuthStore';
import Account from "./Account";
import Seo from "../../helper/Seo";
import "../../assets/css/auth.css";
import LoadingScreen from "../miscs/LoadingScreen";
import scenextrasHeroBg from "../../assets/img/scenextras_hero_bg.png";
import scenextrasLogo from "../../assets/img/scenextras-logo-1@2x.png";

export default function Profile() {
  const { user, session, signOut } = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const toastShownRef = useRef(false);
  const DISCORD_LINK = process.env.REACT_APP_DISCORD_URL || "https://discord.com/invite/AscUxE2NEC";

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // useEffect(() => {
  //   const checkWhitelist = async () => {
  //     if (!session?.user?.email || toastShownRef.current) return;
  //
  //     try {
  //       const { data, error } = await supabase
  //         .from("allowed_users")
  //         .select("confirmed")
  //         .eq("email", session.user.email.toLowerCase())
  //         .maybeSingle();
  //
  //       if (error || !data?.confirmed) {
  //         await signOut();
  //         toastShownRef.current = true;
  //         toast.error(
  //           <div>
  //             Your account is not whitelisted. Request access on Discord:
  //             <a href={DISCORD_LINK} target="_blank" rel="noopener noreferrer"> here</a>
  //           </div>,
  //           { autoClose: false, closeOnClick: false }
  //         );
  //       }
  //     } catch (error) {
  //       await signOut();
  //     }
  //   };
  //
  //   if (session?.user) {
  //     checkWhitelist();
  //   }
  // }, [session?.user?.email, signOut]);

  if (isLoading) return <LoadingScreen />;

  console.log(user);

  return (
    <>
      <Seo
        title="Profile - Scenextras"
        description="Profile - Scenextras - manage your account."
        url="https://scenextras.com/profile"
        image="https://framerusercontent.com/images/mvyirly4lm7ajnERA4s7OwV59Nw.png"
      />

      {!session ? (
        <div className={`auth-container ${isMobile ? 'mobile' : ''}`}>
          <div className="auth-background" style={{
            backgroundImage: `url(${scenextrasHeroBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.5,
            zIndex: -1,
            borderRadius: '20px',
          }} />
          <div className="auth-content" style={{
            padding: '20px',
            backgroundColor: 'rgba(26, 26, 26, 0.95)',
            borderRadius: '20px',
            margin: '20px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
          }}>
            <div className="auth-logo">
              <img src={scenextrasLogo} alt="Scenextras Logo" />
            </div>
            <p style={{
              fontSize: '3rem',
              fontFamily: "'SF Pro Display', system-ui, -apple-system",
              fontWeight: '600',
              color: '#FF8A00',
              background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, #FF8A00 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text',
              letterSpacing: '-0.02em',
              lineHeight: '1.2',
              margin: '2rem 0 3rem',
              textAlign: 'center'
            }}>
              Your Characters<br/>
              <span style={{
                fontSize: '3rem',
                fontWeight: '600',
                letterSpacing: '-0.03em',
                display: 'block'
              }}>
                are Waiting
              </span>
            </p>
            <Auth
              supabaseClient={supabase}
              appearance={{
                theme: ThemeSupa,
                style: {
                  button: {
                    background: "white",
                    color: "#333",
                    borderRadius: "20px",
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "12px",
                    marginBottom: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                  },
                  input: {
                    background: "rgba(255, 255, 255, 0.1)",
                    color: "white",
                    borderRadius: "10px",
                    border: "none",
                    padding: "12px",
                    marginBottom: "12px"
                  },
                  label: {
                    color: "rgba(255, 255, 255, 0.7)",
                    fontSize: "14px",
                    marginBottom: "4px"
                  },
                  anchor: {
                    color: "#ff8c00",
                    fontSize: "14px"
                  }
                }
              }}
              providers={["google"]}
              redirectTo={process.env.REACT_APP_AUTH_REDIRECT_URL}
              view="magic_link"
              showLinks={false}
            />
          </div>
        </div>
      ) : user && (
        <Account/>
      )}
    </>
  );
}
