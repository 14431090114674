import Cookies from "js-cookie";
import axios, { AxiosError } from "axios";
import { ActorFilmography, Movie, SeriesWithCast } from "../types/MovieType";
import { Cast } from "../types/CastType";
import { API_URL, CARTOONS_CAST_LIMIT, CAST_LIMIT, EXCLUDED_TITLES, MOVIE_LIMIT } from "../config";
import { getObjectFromLocal, getWithoutExpiry, setWithoutExpiry } from "../helper/storageUtils";
import log from "loglevel";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { Message } from "../components/chat/InnerChat";
import { UserDetailsType } from "../types/UserDetailsType";
import { ExportData } from "../components/chat/ChatExport";
import { NewMessagesResponse, UserTokensResponse } from "../types/NewMessageResponse";
import { WinStreakData } from "../components/user/HybridSystem";
import { supabase } from "../helper/supabase_client";
import { Session } from "@supabase/supabase-js";
import { CREDIT_PACKAGES, PlanType, Products } from "../components/payments/CheckoutModal";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

// Function to get both Supabase and custom authorization headers
async function getHeaders(customHeaders = {}) {
  const headers: Record<string, string> = {
    ...customHeaders,
    "ngrok-skip-browser-warning": "True"
  };

  // Get Supabase session
  const { data: { session } } = await supabase.auth.getSession();

  if (session?.access_token) {
    headers["Authorization"] = `Bearer ${session.access_token}`;
  } else {
    // Fallback to legacy auth if no Supabase session
    const supabaseObject = JSON.parse(
      getObjectFromLocal("sb-vcsqhuxpigrhqgausqit-auth-token") || "{}"
    );
    const access_token = supabaseObject.access_token || getWithoutExpiry("access_token") || "";
    if (access_token) {
      headers["Authorization"] = `Bearer ${access_token}`;
    }
  }

  const userId = getWithoutExpiry("User_identifier");
  if (userId) {
    headers["User_identifier"] = userId;
  }

  return headers;
}

interface SendMessageParams {
  message: string;
  character: string;
  series?: string;
  config?: {
    tone?: string;
    theme?: string;
    length?: string;
    language?: string;
    followup?: boolean;
    worldsim?: boolean;
    model_choice?: string;
  };
  userDetails?: UserDetailsType;
  roleplayDetails?: {
    name: string;
    age: number;
    skills_abilities: string;
    scenario: string;
  };
}


interface TranscriptCallResponseType {
  transcript: string;
  audio_file: string;
  message: string;
  remaining_quota: number;
  session_token: string;
}

async function getAuthorizationHeader() {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (session?.access_token) {
      return `Bearer ${session.access_token}`;
    }

    const supabaseObject = JSON.parse(
      getObjectFromLocal("sb-vcsqhuxpigrhqgausqit-auth-token") || "{}"
    );

    const access_token = supabaseObject.access_token || getWithoutExpiry("access_token") || "";
    return `Bearer ${access_token}`;
  } catch (error) {
    console.error("Error getting authorization header:", error);
    Sentry.captureException(error);
    return "Bearer ";
  }
}

const getAuthHeader = (session: Session | null |undefined) => {
  return session?.access_token ? `Bearer ${session.access_token}` : "";
};

const api = axios.create({
  baseURL: `${API_URL}/api`,
  withCredentials: true
});

api.defaults.withCredentials = true;

api.interceptors.request.use(
  config => {
    const userId = getWithoutExpiry("User_identifier");
    if (userId) {
      config.headers["User_identifier"] = userId;
    }
    config.headers["ngrok-skip-browser-warning"] = "True";
    return config;
  },
  error => Promise.reject(error)
);


export async function getAudio(filename: string): Promise<string> {
  try {
    const response = await api.get(`/audio/${filename}`, {
      responseType: "blob",
      headers: {
        "Content-Type": "audio/mpeg",
        Authorization: await getAuthorizationHeader()
      }
    });

    const blob = new Blob([response.data], { type: "audio/mpeg" });
    const url = URL.createObjectURL(blob);

    return url;
  } catch (error) {
    Sentry.captureException(error);
    log.error(error);
    throw error;
  }
}

export async function sendTranscribeMessage(session: Session | null, formData: FormData) {
  try {
    const character = formData.get("character");
    const token = getWithoutExpiry(`X-Session-Token-${character}`);
    const response = await api.post(
      `/transcribe_and_chat`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Session-Token": token || "",
          Authorization: getAuthHeader(session)
        }
      }
    );

    setWithoutExpiry(`X-Session-Token-${character}`, response.data.session_token);
    log.info(response.data);
    setWithoutExpiry("remaining_quota", response.data.remaining_quota);
    return response;
  } catch (error) {
    Sentry.captureException(error);
    if (axios.isAxiosError(error)) {
      log.error("Axios error:", error.message);
      log.error("Response:", error.response);
    } else {
      log.error("Unexpected error:", error);
    }
    throw error;
  }
}

export async function clickAd(session: Session | null, ad_id: string) {
  try {
    const response = await api.get(
      `/ads/record_click?ad_id=${ad_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthHeader(session)
        }
      }
    );

    if (response.status !== 200) {
      throw new Error(`Failed to record click: ${response.status}`);
    }

    setWithoutExpiry("remaining_quota", response.data.remaining_quota);
  } catch (e) {
    log.error("Error recording click:", e);
    Sentry.captureException(e);
    throw e;
  }
}


export async function fetchAdNew(session: Session | null) {
  try {
    const response = await api.get(`/ads/fetch_ad_new`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthHeader(session)
      }
    });

    if (response.status !== 200) {
      throw new Error(`Failed to fetch ad: ${response.status}`);
    }

    const adData = response.data;
    setWithoutExpiry("remaining_quota", adData.remaining_quota);

    return {
      highlight: adData.highlight,
      link: adData.link,
      logo: adData.logo,
      text: adData.text,
      remainingQuota: adData.remaining_quota
    };
  } catch (error) {
    log.error(error);
    Sentry.captureException(error);
    throw error;
  }
}

export async function fetchAd(session: Session | null) {
  try {
    const response = await api.get(`/ads/fetch_ad`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthHeader(session)
      }
    });

    if (response.status !== 200) {
      throw new Error(`Failed to fetch ad: ${response.status}`);
    }

    const adData = response.data;
    setWithoutExpiry("remaining_quota", response.data.remaining_quota);

    const id = adData.id;
    let text = "";
    let linkText = "";
    let linkUrl = "";

    const pattern = /\[(.*?)\]\((.*?)\)/;
    const matches = adData.text.match(pattern);

    if (matches) {
      linkText = matches[1];
      linkUrl = matches[2];
      text = adData.text.replace(pattern, "").trim();
    } else {
      text = adData.text;
    }

    return {
      id: id,
      text: text,
      linkText: linkText,
      linkUrl: linkUrl
    };
  } catch (error) {
    log.error(error);
    Sentry.captureException(error);
    throw error;
  }
}

export async function sendMessage(
  session: Session | null,
  payload: SendMessageParams,
  character: string,
  introduction: boolean | null
) {
  const token = getWithoutExpiry(`X-Session-Token-${character}`);
  const endpoint = character === "Scenester" ? "/talk_with_new" : "/talk_with";

  try {
    const response = await api.post(
      `${endpoint}${introduction ? "?introduction=true" : ""}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Session-Token": token || "",
          Authorization: getAuthHeader(session)
        }
      }
    );

    setWithoutExpiry(`X-Session-Token-${character}`, response.data.session_token);
    setWithoutExpiry("remaining_quota", response.data.remaining_quota);
    return response.data;
  } catch (error) {
    log.error("Error sending message:", error);
    Sentry.captureException(error);
    throw error;
  }
}

export const submitRating = async (
  selectedFace_rating: number,
  textComment: string
) => {
  try {
    const response = await api.post(
      `/rating`,
      {
        face_rating: selectedFace_rating,
        comment: textComment
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: await getAuthorizationHeader()
        }
      }
    );

    log.info("Rating saved with ID:", response.data.id);
  } catch (error) {
    Sentry.captureException(error);
    log.error(error);
    return false;
  }
};

export function setToken(token: string, character: string): void {
  Cookies.set(`X-Session-Token-${character}`, token);
}

export async function getTokenFromApi(): Promise<string | undefined> {
  const response = await api.post(
    `/api_entrypoint/`);

  return response.data.access_token;
}

export async function getCharacters(id: number): Promise<Cast[]> {
  const response = await api.get(`/movie-cast/${id}?limit=5`, {
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.data.statusText) {
    throw new Error(`API request failed: ${response.status}`);
  }

  return response.data;
}

export async function getMovie(title: string): Promise<Movie> {
  const encodedString = encodeURIComponent(title);
  const response = await api.get(`/movie/${encodedString}`);

  if (!response.data.statusText) {
    throw new Error(`API request failed: ${response.status}`);
  }

  return response.data.results[0];
}

export async function getExampleQuestions(): Promise<string[]> {
  const response = await api.get(`/example-questions`);

  return response.data.results;
}

export async function getExampleQuestionsGpt(): Promise<string[]> {
  const response = await api.get(`/example-questions-gpt`);

  return response.data.results;
}

export async function getPopularMovies(): Promise<Movie[]> {
  const response = await api.get(`/movie-popular?limit=6`, {
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.data.statusText) {
    throw new Error(`API request failed: ${response.status}`);
  }

  if (response.data.results) {
    return response.data.results;
  } else {
    return response.data;
  }
}

export async function getMoviesByTitle(title: string): Promise<Movie[]> {
  const response = await api.get(`/movies/${title}?limit=6`, {
    headers: {
      "Content-Type": "application/json"
    }
  });
  if (!response.data.statusText) {
    throw new Error(`API request failed: ${response.status}`);
  }

  if (response.data.results) {
    return response.data.results;
  } else {
    return response.data;
  }
}

export async function getPopularMoviesWithCast(session: Session | null | undefined,
                                               limit = MOVIE_LIMIT,
                                               castLimit = CAST_LIMIT
): Promise<Movie[]> {
  try {
    const response = await api.get(`/movie-popular-with-cast`, {
      headers: {
        "Content-Type": "application/json"
      },
      params: {
        limit: limit,
        cast_limit: castLimit
      }
    });

    if (!response.data.statusText) {
      throw new Error(`API request failed: ${response.status}`);
    }


    if (response.data.results) {
      const secureApiUrl = API_URL.replace(/^http:/, "https:");
      const movies = response.data.results;
      const availableImages = await getAvailableImages();

      for (const movie of movies) {
        if (movie.cast) {
          for (const castMember of movie.cast) {
            const localImageName = `${movie.id}_${castMember.id}.jpg`;

            const characterBasedImage = get_character_images(castMember, availableImages);
            const actionBasedImage = get_actor_images(castMember, availableImages);

            if (availableImages.includes(localImageName)) {
              castMember.profile_path = `${secureApiUrl}/images/${localImageName}`;
            } else if (characterBasedImage) {
              castMember.profile_path = `${secureApiUrl}/images/${characterBasedImage}`;
            } else if (actionBasedImage) {
              castMember.profile_path = `${secureApiUrl}/images/${actionBasedImage}`;
            }
          }
        }
      }

      return movies;
    } else {
      return response.data;
    }
  } catch (e) {
    log.error(e);
    Sentry.captureException(e);
    return [];
  }
}

async function getAvailableImages(): Promise<string[]> {
  try {
    const response = await api.get("/images");
    return response.data.images.map((image: { name: string }) => image.name);
  } catch (e) {
    log.error("Failed to fetch available images:", e);
    Sentry.captureException(e);
    return [];
  }
}

export async function getRandomMovieCharacters(): Promise<
  Movie | SeriesWithCast
> {
  const response = await api.get(`/getRandomCharacter`, {
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (response.data.results) {
    return response.data.results;
  } else {
    return response.data;
  }
}

export async function getMoviesWithCastAutoDetect(
  movieTitle: string,
  castLimit = CAST_LIMIT,
  limit = MOVIE_LIMIT
): Promise<Movie[]> {
  try {
    log.info("getting movie with cast");

    const response = await api.get(
      `/detect/movie-with-cast/${movieTitle}`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          cast_limit: castLimit,
          limit: limit
        }
      }
    );

    if (response.data && response.data.results && response.data.results[0]) {
      log.info("returning child elements");
      return response.data.results;
    } else {
      log.info("returning parent element");
      return response.data;
    }
  } catch (e) {
    log.error(e);
    Sentry.captureException(e);
    return [];
  }
}

function get_actor_images(castMember: Cast, availableImages: string[]) {
  if (castMember.original_name) {
    const characters = castMember.original_name
      .split("/")
      .map((char: string) =>
        char
          .replace(/[^a-zA-Z0-9]/g, "_")
          .toLowerCase()
          .trim()
          .replace(/^_+|_+$/g, "")
      );

    let actorBasedImage = null;

    for (const character of characters) {
      actorBasedImage = `actor_specific_image_${character}.jpg`;
      if (availableImages.includes(actorBasedImage)) {
        break;
      }
      actorBasedImage = null;
    }
    return actorBasedImage;
  }
}

function get_character_images(castMember: Cast, availableImages: string[]) {
  const characters = castMember.character
    .split("/")
    .map((char: string) =>
      char
        .replace(/[^a-zA-Z0-9]/g, "_") // Replace non-alphanumeric characters with underscores
        .toLowerCase() // Convert to lowercase
        .trim() // Trim whitespace
        .replace(/^_+|_+$/g, "") // Remove leading and trailing underscores
    );

  let characterBasedImage = null;

  for (const character of characters) {
    characterBasedImage = `character_specific_image_${character}.jpg`;
    if (availableImages.includes(characterBasedImage)) {
      break;
    }
    characterBasedImage = null;
  }
  return characterBasedImage;
}

export async function searchMoviesWithCast(
  movieTitle: string,
  castLimit = CAST_LIMIT,
  limit = MOVIE_LIMIT
): Promise<Movie[]> {
  try {
    log.info("getting movie with cast");

    const response = await api.get(
      `/movie-with-cast-new/${movieTitle}`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          cast_limit: castLimit,
          limit: limit
        }
      }
    );

    log.info(response);

    if (response.data.results) {
      const secureApiUrl = API_URL.replace(/^http:/, "https:");
      const availableImages = await getAvailableImages();

      const content = response.data.results;
      for (const movie of content) {
        if (!movie.title || movie.title == "") {
          movie.title = movie.name;
        }
        if (movie.cast) {
          for (const castMember of movie.cast) {
            const localImageName = `${movie.id}_${castMember.id}.jpg`;

            // Clean up the character name
            const characterName = castMember.character
              .split("/")[0]
              .replace(/\(voice\)/i, "")
              .replace(/\s+/g, " ")
              .trim();

            castMember.character = characterName;

            const characterBasedImage = get_character_images(castMember, availableImages);
            const actionBasedImage = get_actor_images(castMember, availableImages);

            const characterImage = movie.characters?.find((char: any) => {
              const cleanCharName = char.name
                .replace(/\s+/g, " ")
                .replace(/\w\.\s?/, "") // Remove middle initials
                .trim()
                .toLowerCase();
              const cleanCastCharName = characterName
                .replace(/\s+/g, " ")
                .replace(/\w\.\s?/, "") // Remove middle initials
                .trim()
                .toLowerCase();
              return cleanCharName.includes(cleanCastCharName) || cleanCastCharName.includes(cleanCharName);
            })?.image;

            if (availableImages.includes(localImageName)) {
              castMember.profile_path = `${secureApiUrl}/images/${localImageName}`;
            } else if (characterBasedImage) {
              castMember.profile_path = `${secureApiUrl}/images/${characterBasedImage}`;
            } else if (actionBasedImage) {
              castMember.profile_path = `${secureApiUrl}/images/${actionBasedImage}`;
            } else if (characterImage) {
              castMember.profile_path = characterImage;
            }
          }
        }
      }
      return content;
    } else {
      return response.data;
    }
  } catch (e) {
    log.error(e);
    Sentry.captureException(e);
    return [];
  }
}

export async function searchSeriesWithCast(
  movieTitle: string,
  castLimit = CAST_LIMIT,
  limit = MOVIE_LIMIT
): Promise<Movie[]> {
  try {
    log.info("getting movie with cast");

    const response = await api.get(
      `/series-with-cast/${movieTitle}`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          cast_limit: castLimit,
          limit: limit
        }
      }
    );
    const secureApiUrl = API_URL.replace(/^http:/, "https:");
    const availableImages = await getAvailableImages();

    if (response.data.results) {
      const secureApiUrl = API_URL.replace(/^http:/, "https:");
      const availableImages = await getAvailableImages();

      const content = response.data.results;
      for (const movie of content) {
        if (!movie.title || movie.title == "") {
          movie.title = movie.name;
        }
        if (movie.cast) {
          for (const castMember of movie.cast) {
            const localImageName = `${movie.id}_${castMember.id}.jpg`;

            // Clean up the character name
            const characterName = castMember.character
              .split("/")[0]
              .replace(/\(voice\)/i, "")
              .replace(/\s+/g, " ")
              .trim();

            castMember.character = characterName;

            const characterBasedImage = get_character_images(castMember, availableImages);
            const actionBasedImage = get_actor_images(castMember, availableImages);

            const characterImage = movie.characters?.find((char: any) => {
              const cleanCharName = char.name
                .replace(/\s+/g, " ")
                .replace(/\w\.\s?/, "") // Remove middle initials
                .trim()
                .toLowerCase();
              const cleanCastCharName = characterName
                .replace(/\s+/g, " ")
                .replace(/\w\.\s?/, "") // Remove middle initials
                .trim()
                .toLowerCase();
              return cleanCharName.includes(cleanCastCharName) || cleanCastCharName.includes(cleanCharName);
            })?.image;

            if (availableImages.includes(localImageName)) {
              castMember.profile_path = `${secureApiUrl}/images/${localImageName}`;
            } else if (characterBasedImage) {
              castMember.profile_path = `${secureApiUrl}/images/${characterBasedImage}`;
            } else if (actionBasedImage) {
              castMember.profile_path = `${secureApiUrl}/images/${actionBasedImage}`;
            } else if (characterImage) {
              castMember.profile_path = characterImage;
            }
          }
        }
      }
      return content;
    } else {
      return response.data;
    }
  } catch (e) {
    log.error(e);
    Sentry.captureException(e);
    return [];
  }
}

export async function searchAnimesWithCast(
  movieTitle: string,
  castLimit = CAST_LIMIT,
  limit = MOVIE_LIMIT
): Promise<Movie[]> {
  log.info("getting movie with cast");

  const response = await api.get(
    `/anime-with-cast/${movieTitle}`,
    {
      headers: {
        "Content-Type": "application/json"
      },
      params: {
        cast_limit: castLimit,
        limit: limit
      }
    }
  );


  if (response.data.results) {
    const secureApiUrl = API_URL.replace(/^http:/, "https:");
    const availableImages = await getAvailableImages();

    const content = response.data.results;
    for (const movie of content) {
      if (movie.cast) {
        for (const castMember of movie.cast) {
          const localImageName = `${movie.id}_${castMember.id}.jpg`;

          // Clean up the character name
          const characterName = castMember.character
            .split("/")[0]
            .replace(/\(voice\)/i, "")
            .replace(/\s+/g, " ")
            .trim();

          castMember.character = characterName;

          const characterBasedImage = get_character_images(castMember, availableImages);
          const actionBasedImage = get_actor_images(castMember, availableImages);

          const characterImage = movie.characters?.find((char: any) => {
            const cleanCharName = char.name
              .replace(/\s+/g, " ")
              .replace(/\w\.\s?/, "") // Remove middle initials
              .trim()
              .toLowerCase();
            const cleanCastCharName = characterName
              .replace(/\s+/g, " ")
              .replace(/\w\.\s?/, "") // Remove middle initials
              .trim()
              .toLowerCase();
            return cleanCharName.includes(cleanCastCharName) || cleanCastCharName.includes(cleanCharName);
          })?.image;

          if (availableImages.includes(localImageName)) {
            castMember.profile_path = `${secureApiUrl}/images/${localImageName}`;
          } else if (characterBasedImage) {
            castMember.profile_path = `${secureApiUrl}/images/${characterBasedImage}`;
          } else if (actionBasedImage) {
            castMember.profile_path = `${secureApiUrl}/images/${actionBasedImage}`;
          } else if (characterImage) {
            castMember.profile_path = characterImage;
          }
        }
      }
    }
    return content;
  } else {
    return response.data;
  }
}


export async function getSeriesWithCast(
  movieTitle: string,
  episodeTitle: string,
  castLimit = CAST_LIMIT,
  limit = MOVIE_LIMIT
): Promise<SeriesWithCast> {
  const response = await api.get(
    `/search-tv-series-episode/${movieTitle}/${episodeTitle}`,
    {
      headers: {
        "Content-Type": "application/json"
      },
      params: {
        cast_limit: castLimit,
        limit: limit
      }
    }
  );

  if (response.data.results) {
    return response.data.results;
  } else {
    return response.data;
  }
}

export async function getAnimeWithCast(
  animeTitle: string,
  castLimit = CAST_LIMIT,
  limit = MOVIE_LIMIT
): Promise<Movie[]> {
  try {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      params: {
        cast_limit: castLimit,
        limit: limit
      }
    };

    const response = await api.get(
      `/anime-with-cast/${animeTitle}`,
      options
    );

    if (response.data.results) {
      return response.data.results;
    } else {
      return response.data;
    }
  } catch (e) {
    log.error(e);
    Sentry.captureException(e);
    return [];
  }
}


const VERSION_CACHE_KEY = "cachedVersion";
const VERSION_CACHE_EXPIRY = 60 * 1000;


export const fetchVersion = async () => {
  const cachedVersionString = sessionStorage.getItem(VERSION_CACHE_KEY);
  const cachedVersion = cachedVersionString ? JSON.parse(cachedVersionString) : null;

  if (cachedVersion && Date.now() - cachedVersion.timestamp < VERSION_CACHE_EXPIRY) {
    // Use cached version
    compareVersions(cachedVersion.backendVersion);
  } else {
    try {
      const response = await api.get(`/version`);
      if (!response.data) {
        throw new Error("Failed to fetch version");
      }
      const backendVersion = response.data.version;

      // Update cache
      sessionStorage.setItem(VERSION_CACHE_KEY, JSON.stringify({
        backendVersion,
        timestamp: Date.now()
      }));

      compareVersions(backendVersion);
    } catch (error) {
      Sentry.captureException(error);
      log.error("Error fetching version:", error);
    }
  }
};

function compareVersions(backendVersion: string) {
  const frontendStorageVersion = getWithoutExpiry("frontendVersion");
  const frontendVersion = frontendStorageVersion || "0.0.0";

  if (backendVersion !== frontendVersion) {
    clearAllData();
    setWithoutExpiry("frontendVersion", backendVersion);
  }
}

function clearAllData() {
  // Clear cookies
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  // Clear localStorage
  localStorage.clear();

  // Clear sessionStorage
  sessionStorage.clear();
}


export const getReferralCode = async () => {
  try {
    const response = await api.get(`/get-referral-code`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthorizationHeader()
      }
    });
    setWithoutExpiry("referral_code", response.data.referral_code);
    return response.data.referral_code;
  } catch (error) {
    Sentry.captureException(error);
    log.error(error);
  }
};

export const redirectToSocialMedia = async (platform: string) => {
  try {
    const referralCode = getWithoutExpiry("referral_code") || "";

    const response = await api.get(
      `${API_URL}/api/generate_referral_url/${platform}`,
      {
        params: {
          referral_code: referralCode
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: await getAuthorizationHeader()
        }
      }
    );

    log.info("Response from API:", response); // Debugging line

    // Now we expect the URL to be part of the response data, not a redirect
    if (response.status === 200 && response.data.url) {
      window.open(
        response.data.url,
        "_blank",
        "width=600,height=400,noopener,noreferrer"
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    log.error(error);
  }
};
export const sendReferralCode = async (emailList: string): Promise<any> => {
  const origin = window.location.origin;
  log.info("emailList", emailList);
  log.info(process.env.PUBLIC_URL);
  try {
    const response = await api.get(`/send-referral-code`, {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getAuthorizationHeader()
      },
      params: { email_list: emailList, redirect_url: origin + "/profile" }
    });
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    if (axios.isAxiosError(err)) {
      const error = err as AxiosError<{ error?: string }>;
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error("Unauthorized");
        } else if (error.response.status === 429) {
          throw new Error("Too many requests. Please try again later.");
        } else {
          throw new Error(error.response.data?.error || "An error occurred");
        }
      } else {
        throw new Error("Network error");
      }
    } else {
      throw new Error("An unexpected error occurred");
    }
  }
};

export const getCurrentTime = async (): Promise<any> => {
  try {
    const response = await api.get(`${API_URL}/current-time`, {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    Sentry.captureException(error);
    throw error;
  }
};

export const getTimeUntilRefresh = async (): Promise<any> => {
  try {
    const response = await api.get(`/get_time_until_refresh_unix`, {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    log.error(err);
  }
};

const getReferralId = (): Promise<string | null> => {
  return new Promise((resolve) => {
    window.rewardful("ready", function() {
      if (window.Rewardful?.referral) {
        resolve(window.Rewardful.referral);
      } else {
        resolve(null);
      }
    });

    // Timeout after 5 seconds
    setTimeout(() => {
      resolve(null);
    }, 5000);
  });
};

export const createCheckoutSession = async (rootUrl: string, email: string, plan: PlanType, displaySource="") => {
  let productType: Products;
  let subscriptionType: "monthly" | "annual" | undefined;

  if (plan === "daily_subscription") {
    productType = Products.DAILY_SUBSCRIPTION;
  } else if (CREDIT_PACKAGES.some(pkg => pkg.product === plan)) {
    productType = plan as Products;
  } else {
    productType = Products.MAX;
    subscriptionType = plan as "monthly" | "annual";
  }

  const referralId = await getReferralId();
  // console.log("Referral ID:", referralId);

  const response = await api.post(
    `/create-checkout-session`,
    {
      root_url: rootUrl,
      user_email: email,
      product_type: productType,
      subscription_type: subscriptionType,
      referral_id: referralId,
      // displaySource: displaySource
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthorizationHeader()
      }
    }
  );

  if (response.data.id) {
    return response.data;
  }
  throw new Error("Failed to create checkout session");
};

export const fetchReferralCredits = async (email: string) => {
  return "";
};

export async function unsubscribe(user_email?: string): Promise<boolean> {

  const response = await api.post(
    `/cancel-subscription`,
    { user_email: user_email },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthorizationHeader()
      }
    });

  return !!response.data.status;
}

export async function handleLike(session: Session | null, message: Message) {
  try {
    const response = await api.get(`/like_message/${message.message_id}`, {
      headers: {
        Authorization: getAuthHeader(session)
      }
    });
    return !!response.data;
  } catch (error) {
    log.error("Error liking the message:", error);
    Sentry.captureException(error);
    return false;
  }
}

export async function handleDislike(session: Session | null, message: Message) {
  try {
    const response = await api.get(`/dislike_message/${message.message_id}`, {
      headers: {
        Authorization: getAuthHeader(session)
      }
    });
    return !!response.data;
  } catch (error) {
    Sentry.captureException(error);
    log.error("Error disliking the message:", error);
    return false;
  }
}

export async function incrementView(exportId: string) {
  try {
    const response = await api.post(`/export/${exportId}/view`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    log.error("Error incrementing view:", error);
    return null;
  }
}

export async function incrementShare(exportId: string) {
  try {
    const response = await api.post(`/export/${exportId}/share`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    log.error("Error incrementing share:", error);
    return null;
  }
}

export async function incrementLike(exportId: string) {
  try {
    const response = await api.post(`/export/${exportId}/like`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    log.error("Error incrementing like:", error);
    return null;
  }
}

export async function getEngagementStats(exportId: string) {
  try {
    const response = await api.get(`/export/${exportId}/stats`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    log.error("Error fetching engagement stats:", error);
    return null;
  }
}


export async function handleApiFetch(exportId: string): Promise<ExportData> {
  const response = await api.get(`/export/${exportId}`, {});
  return response.data;
}

export async function handleApiSave(exportData: ExportData): Promise<string> {
  const response = await api.post("/export", exportData, {
    headers: {
      Authorization: await getAuthorizationHeader()
    }
  });
  return response.data.export_id;
}

export async function getPopularContent(
  contentType: "movies" | "series" | "animes" | "cartoons" | "actors",
  limit = MOVIE_LIMIT,
  castLimit = CAST_LIMIT
): Promise<Movie[] | ActorFilmography[]> {
  try {
    const adjustedCastLimit = Math.min(castLimit, 10);

    const response = await api.get(`/popular/${contentType}`, {
      params: {
        limit: limit,
        cast_limit: adjustedCastLimit
      }
    });

    if (response.data.results) {
      const secureApiUrl = API_URL.replace(/^http:/, "https:");
      const availableImages = await getAvailableImages();

      if (contentType === "actors") {
        return response.data.results
          .filter((actorData: any) => actorData && actorData.name && actorData.profile_path)
          .map((actorData: any): ActorFilmography => {
            const externalIds = actorData?.external_ids || {};
            return {
              name: actorData.name,
              profile_path: actorData.profile_path,
              known_for: actorData.known_for || [],
              filmography: {
                characters: (actorData.filmography?.characters || [])
                  .filter((character: any) => character && character.name)
                  .map((character: any): Cast => {
                    const movieName = character.movie?.name || "Unknown Movie";
                    return {
                      adult: actorData.known_for?.[0]?.adult ?? false,
                      gender: 0, // Assuming gender is not provided
                      id: character.id || 0,
                      known_for_department: "Acting",
                      name: character.name,
                      original_name: actorData.name,
                      popularity: actorData.popularity ?? 0,
                      profile_path: character.image || character.personImgURL || null,
                      cast_id: character.id || 0,
                      character: character.name,
                      credit_id: (character.id || "0").toString(),
                      order: character.sort ?? 0,
                      from_movie: movieName,
                      isFromMessages: false,
                      alt: character.name
                    };
                  })
              },
              external_ids: {
                imdb_id: externalIds.imdb_id || null,
                facebook_id: externalIds.facebook_id || null,
                instagram_id: externalIds.instagram_id || null,
                twitter_id: externalIds.twitter_id || null,
                tiktok_id: externalIds.tiktok_id || null,
                youtube_id: externalIds.youtube_id || null,
                wikidata_id: externalIds.wikidata_id || null
              }
            };
          });
      } else {
        const content = response.data.results;
        // console.log(content);
        const filteredContent = content.filter((movie: Movie) =>
          !EXCLUDED_TITLES.includes(movie.title)
        );
        // console.log(filteredContent);

        for (const movie of filteredContent) {
          if (!movie.title || movie.title == "") {
            movie.title = movie.name;
          }
          if (movie.cast) {
            for (const castMember of movie.cast) {
              const localImageName = `${movie.id}_${castMember.id}.jpg`;

              // Clean up the character name
              const characterName = castMember.character
                .split("/")[0]
                .replace(/\(voice\)/i, "")
                .replace(/\s+/g, " ")
                .trim();

              castMember.character = characterName;

              const characterBasedImage = get_character_images(castMember, availableImages);
              const actionBasedImage = get_actor_images(castMember, availableImages);

              const characterImage = movie.characters?.find((char: any) => {
                const cleanCharName = char.name
                  .replace(/\s+/g, " ")
                  .replace(/\w\.\s?/, "") // Remove middle initials
                  .trim()
                  .toLowerCase();
                const cleanCastCharName = characterName
                  .replace(/\s+/g, " ")
                  .replace(/\w\.\s?/, "") // Remove middle initials
                  .trim()
                  .toLowerCase();
                return cleanCharName.includes(cleanCastCharName) || cleanCastCharName.includes(cleanCharName);
              })?.image;

              if (availableImages.includes(localImageName)) {
                castMember.profile_path = `${secureApiUrl}/images/${localImageName}`;
              } else if (characterBasedImage) {
                castMember.profile_path = `${secureApiUrl}/images/${characterBasedImage}`;
              } else if (actionBasedImage) {
                castMember.profile_path = `${secureApiUrl}/images/${actionBasedImage}`;
              } else if (characterImage) {
                castMember.profile_path = characterImage;
              }

              // if (movie.id == 533535 && castMember.id == 6968) {
              //   console.log(movie);
              //   console.log(castMember);
              //   console.log(characterBasedImage);
              //   console.log(actionBasedImage);
              //   console.log(characterImage);
              // }
            }
          }
        }
        return filteredContent;
      }
    } else {
      throw new Error("No results found");
    }
  } catch (e) {
    log.error(e);
    log.info(e);
    console.error(e);
    Sentry.captureException(e);
    return [];
  }
}

export async function searchCartoonsWithCast(
  cartoonTitle: string,
  castLimit = CARTOONS_CAST_LIMIT,
  limit = MOVIE_LIMIT
): Promise<Movie[]> {
  log.info("Searching for cartoons with cast");

  try {
    const response = await api.get(
      `/cartoons-with-cast/${encodeURIComponent(cartoonTitle)}`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          cast_limit: castLimit,
          limit: limit
        }
      }
    );

    if (response.data.results) {
      const secureApiUrl = API_URL.replace(/^http:/, "https:");
      const availableImages = await getAvailableImages();

      const content = response.data.results;
      for (const movie of content) {
        if (!movie.title || movie.title == "") {
          movie.title = movie.name;
        }
        if (movie.cast) {
          for (const castMember of movie.cast) {
            const localImageName = `${movie.id}_${castMember.id}.jpg`;

            // Clean up the character name
            const characterName = castMember.character
              .split("/")[0]
              .replace(/\(voice\)/i, "")
              .replace(/\s+/g, " ")
              .trim();

            castMember.character = characterName;

            const characterBasedImage = get_character_images(castMember, availableImages);
            const actionBasedImage = get_actor_images(castMember, availableImages);

            const characterImage = movie.characters?.find((char: any) => {
              const cleanCharName = char.name
                .replace(/\s+/g, " ")
                .replace(/\w\.\s?/, "") // Remove middle initials
                .trim()
                .toLowerCase();
              const cleanCastCharName = characterName
                .replace(/\s+/g, " ")
                .replace(/\w\.\s?/, "") // Remove middle initials
                .trim()
                .toLowerCase();
              return cleanCharName.includes(cleanCastCharName) || cleanCastCharName.includes(cleanCharName);
            })?.image;

            if (availableImages.includes(localImageName)) {
              castMember.profile_path = `${secureApiUrl}/images/${localImageName}`;
            } else if (characterBasedImage) {
              castMember.profile_path = `${secureApiUrl}/images/${characterBasedImage}`;
            } else if (actionBasedImage) {
              castMember.profile_path = `${secureApiUrl}/images/${actionBasedImage}`;
            } else if (characterImage) {
              castMember.profile_path = characterImage;
            }
          }
        }
      }
      return content;
    } else {
      return response.data;
    }
  } catch (error) {
    Sentry.captureException(error);
    log.error("Error searching for cartoons:", error);
    throw error;
  }
}

export async function fetchNewMessages(
  sessionTokens: Record<string, string>,
  conversationHashes: Record<string, string>
): Promise<NewMessagesResponse> {
  try {
    const response = await api.post("/fetch_new_messages",
      { sessionTokens, conversationHashes },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: await getAuthorizationHeader()
        }
      }
    );

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error("Error fetching new messages:", error);
    throw error;
  }
}

export async function fetchSingleConversation(
  sessionToken: string,
  conversationHash: string
): Promise<NewMessagesResponse> {
  try {
    const response = await api.post("/fetch_single_conversation",
      { session_token: sessionToken, conversation_hash: conversationHash },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: await getAuthorizationHeader()
        }
      }
    );

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error("Error fetching single conversation:", error);
    throw error;
  }
}

export async function fetchUserTokens(): Promise<UserTokensResponse> {
  try {
    const response = await api.get("/user_tokens", {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthorizationHeader()
      }
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error("Error fetching user tokens:", error);
    throw error;
  }
}

export async function unsubscribeEmail(token: string): Promise<{ message: string }> {
  try {
    const response = await api.get(`/email/unsubscribe/${token}`, {
      headers: {
        Authorization: await getAuthorizationHeader()
      }
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    if (axios.isAxiosError(error)) {
      log.error("Axios error:", error.message);
      log.error("Response:", error.response);
      throw error;
    } else {
      log.error("Unexpected error:", error);
      throw new Error("An unexpected error occurred.");
    }
  }
}

export const fetchWinStreakData = async (timezone: string): Promise<WinStreakData> => {
  try {
    log.info(`Fetching win streak data for timezone: ${timezone}`);
    const response = await api.get("/winstreak/", {
      params: { timezone },
      headers: {
        Authorization: await getAuthorizationHeader()
      }
    });
    const data = response.data;
    log.info("Received win streak data:", data);
    return {
      currentStreak: data.current_streak,
      longestStreak: data.longest_streak,
      weeklyProgress: data.weekly_progress
    };
  } catch (error: unknown) {
    Sentry.captureException(error);
    if (axios.isAxiosError(error)) {
      console.error("Error fetching win streak data:", error.response?.data || error.message);
    } else {
      console.error("An unknown error occurred while fetching win streak data");
    }
    throw error;
  }
};

export const updateWinStreak = async (timezone: string): Promise<WinStreakData> => {
  try {
    log.info(`Updating win streak for timezone: ${timezone}`);
    const response = await api.post("/winstreak/update", { timezone }, {
      headers: {
        Authorization: await getAuthorizationHeader()
      }
    });
    const data = response.data;
    log.info("Received updated win streak data:", data);
    return {
      currentStreak: data.current_streak,
      longestStreak: data.longest_streak,
      weeklyProgress: data.weekly_progress
    };
  } catch (error: unknown) {
    Sentry.captureException(error);
    if (axios.isAxiosError(error)) {
      console.error("Error updating win streak:", error.response?.data || error.message);
    } else {
      console.error("An unknown error occurred while updating win streak");
    }
    throw error;
  }
};


export async function searchActorsFilmography(
  actorName: string,
  limit = 90,
  castLimit = 20
): Promise<ActorFilmography[]> {
  log.info("Searching for actors filmography");
  try {
    const response = await api.get(
      `/thetvdb/actors/search/${encodeURIComponent(actorName)}`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          limit: limit,
          cast_limit: castLimit
        }
      }
    );

    if (response.data.actors) {
      const secureApiUrl = API_URL.replace(/^http:/, "https:");
      return response.data.actors
        .filter((actorData: any) =>
          actorData.actor &&
          actorData.actor.name &&
          (actorData.actor.image_url || actorData.actor.id) &&
          actorData.actor.image_url !== "https://artworks.thetvdb.com/banners/images/missing/actor.jpg"
        )
        .map((actorData: any): ActorFilmography => {
          const externalIds = actorData.actor.external_ids || {};
          return {
            name: actorData.actor.name,
            profile_path: actorData.actor.image_url || `${secureApiUrl}/images/${actorData.actor.id}.jpg`,
            filmography: {
              characters: (actorData.filmography?.characters || [])
                .filter((character: any) =>
                  character &&
                  character.name &&
                  character.image &&
                  character.movie &&
                  character.movie.name
                )
                .map((character: any): Cast => ({
                  adult: false,
                  gender: 0,
                  id: character.id || 0,
                  known_for_department: "Acting",
                  name: character.name,
                  original_name: actorData.actor.name,
                  popularity: 0,
                  profile_path: character.image,
                  cast_id: character.id || 0,
                  character: character.name,
                  credit_id: (character.id || "0").toString(),
                  order: character.sort || 0,
                  from_movie: character.movie.name,
                  isFromMessages: false,
                  alt: character.name
                }))
            },
            external_ids: {
              imdb_id: externalIds.imdb_id || null,
              facebook_id: externalIds.facebook_id || null,
              instagram_id: externalIds.instagram_id || null,
              twitter_id: externalIds.twitter_id || null,
              tiktok_id: externalIds.tiktok_id || null,
              youtube_id: externalIds.youtube_id || null,
              wikidata_id: externalIds.wikidata_id || null
            }
          };
        });
    } else {
      throw new Error("No results found");
    }
  } catch (error) {
    Sentry.captureException(error);
    log.error("Error searching for actors filmography:", error);
    throw error;
  }
}

interface ConversationTitleResponse {
  title: string;
}

interface GetConversationTitleParams {
  sessionToken: string;
  characterName: string;
}


export const getConversationTitle = async ({
                                             sessionToken,
                                             characterName
                                           }: GetConversationTitleParams): Promise<string> => {
  try {
    log.info(`Fetching conversation title for session: ${sessionToken}`);

    const authHeader = await getAuthorizationHeader();

    const response = await api.post<ConversationTitleResponse>(
      "/get_conversation_title",
      {
        session_token: sessionToken,
        character_name: characterName
      },
      {
        headers: {
          Authorization: authHeader,
          "Content-Type": "application/json"
        }
      }
    );

    log.info("Received conversation title:", response.data);
    return response.data.title;

  } catch (error: unknown) {
    Sentry.captureException(error);
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.detail || error.message;
      log.error("Error fetching conversation title:", errorMessage);
      if (error.response?.status === 401) {
        log.error("Authorization failed. Token:", await getAuthorizationHeader());
      }
      throw new Error(`Failed to fetch conversation title: ${errorMessage}`);
    }
    log.error("Unknown error occurred while fetching conversation title");
    throw new Error("Failed to fetch conversation title due to an unknown error");
  }
};


export interface ChatExport {
  id: string;
  export_id: string;
  character: string;
  messages: Message_Export[];
  created_at: string;
  user_id: number;
  user_name: string | null;
  updated_at: string | null;
  profileImage: string | null;
  backgroundImage: string | null;
  title: string | null;
  views: number;
  shares: number;
  likes: number;
}

export interface Message_Export {
  content: string;
  sender: string;
  timestamp: string;
  // Add other message properties as needed
}

export interface ChatExportResponse {
  exports: ChatExport[];
  total: number;
  hasMore: boolean;
}

interface FetchExportsParams {
  page?: number;
  limit?: number;
  sortBy?: "created_at" | "views" | "likes" | "shares";
  sortOrder?: "asc" | "desc";
}


export async function fetchAllExports(
  session: Session | null | undefined,
  params: FetchExportsParams = {}
): Promise<ChatExportResponse> {
  try {
    const {
      page = 1,
      limit = 1000,
      sortBy = "created_at",
      sortOrder = "desc"
    } = params;

    const response = await api.get("/admin/chat-exports/", {
      params: {
        page,
        limit,
        sortBy,
        sortOrder
      },
      headers: {
        Authorization: getAuthHeader(session)
      }
    });

    return {
      exports: response.data.exports,
      total: response.data.total,
      hasMore: response.data.hasMore
    };
  } catch (error) {
    log.error("Error fetching user exports:", error);
    Sentry.captureException(error);
    throw new Error("Failed to fetch user exports");
  }
}


export async function fetchUserExports(
  session: Session | null | undefined,
  params: FetchExportsParams = {}
): Promise<ChatExportResponse> {
  try {
    const {
      page = 1,
      limit = 1000,
      sortBy = "created_at",
      sortOrder = "desc"
    } = params;

    const response = await api.get("/chat-exports/", {
      params: {
        page,
        limit,
        sortBy,
        sortOrder
      },
      headers: {
        Authorization: getAuthHeader(session)
      }
    });

    return {
      exports: response.data.exports,
      total: response.data.total,
      hasMore: response.data.hasMore
    };
  } catch (error) {
    log.error("Error fetching user exports:", error);
    Sentry.captureException(error);
    throw new Error("Failed to fetch user exports");
  }
}

export async function fetchExportById(
  session: Session | null,
  exportId: string
): Promise<ChatExport> {
  try {
    const response = await api.get(`/chat-exports/${exportId}`, {
      headers: {
        Authorization: getAuthHeader(session)
      }
    });

    return response.data;
  } catch (error) {
    log.error(`Error fetching export ${exportId}:`, error);
    Sentry.captureException(error);
    throw new Error("Failed to fetch export");
  }
}

export async function deleteExport(
  session: Session | null,
  exportId: string
): Promise<boolean> {
  try {
    const response = await api.delete(`/chat-exports/${exportId}`, {
      headers: {
        Authorization: getAuthHeader(session)
      }
    });

    return response.status === 200;
  } catch (error) {
    log.error(`Error deleting export ${exportId}:`, error);
    Sentry.captureException(error);
    return false;
  }
}

export async function updateExportInteraction(
  session: Session | null | undefined,
  exportId: string,
  interactionType: "like" | "share" | "view"
): Promise<boolean> {
  try {
    const response = await api.post(`/chat-exports/${exportId}/${interactionType}`, null, {
      headers: {
        Authorization: getAuthHeader(session)
      }
    });

    return response.status === 200;
  } catch (error) {
    log.error(`Error updating export ${interactionType}:`, error);
    Sentry.captureException(error);
    return false;
  }
}

export async function sendExportMessage(
  session: Session | null,
  onboard_payload: any
) {

  const newUuid = uuidv4();


  try {
    const response = await api.post("/talk_with_export", onboard_payload, {
      headers: {
        "Content-Type": "application/json",
        "X-Session-Token": newUuid || "",
        Authorization: getAuthHeader(session)
      }
    });

    const character_name = response.data.character_name;

    setWithoutExpiry(`X-Session-Token-${character_name}`, response.data.session_token);
    log.info(response.data);
    return response;
  } catch (error) {
    log.error("Error sending export message:", error);
    Sentry.captureException(error);
    throw error;
  }
}

export interface GenerateScenarioParams {
  title: string;
  characters: string[];
  description: string;
}


export async function generateScenario(session: Session | null, params: GenerateScenarioParams) {
  try {
    const response = await api.post("/generate-scenarios", params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthHeader(session)
      }
    });
    return response.data;
  } catch (error) {
    log.error("Error generating scenario:", error);
    Sentry.captureException(error);
    throw error;
  }
}