import React, { useState, useEffect, useRef } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { FormStep, isFormStepValid } from "./FormStep";
import { WordCloud } from "./WordCloud";
import { AnimatedText } from "./AnimatedText";
import { Mascot } from "./Mascot";
import * as authService from "../../api/authClient";
import { toast } from "react-toastify";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import emojisHero from "../../assets/img/paywall_header.png";
import scenesterLivingroom from "../../assets/img/scenester_livingroom.png";
import dailyMailMaxFeature from "../../assets/img/dailymail_max_feature.png";
import scenextrasGrid from "../../assets/img/scenextras_grid.png";
import { sendExportMessage } from "../../api/apiClient";
import { useNavigate } from "react-router-dom";
import { AuthenticatedUser } from "../../helper/authHelper";
import { User } from "../../types/UserType";
import LoadingScreen from "../miscs/LoadingScreen";
import CaptureLoading from "../miscs/CaptureLoading";
import Loading from "../miscs/Loading";
import { updateUserConfig } from "../../api/authClient";

gsap.registerPlugin(Draggable);

interface FormData {
  name: string;
  age: number;
  gender: string;
  location: string;
  profession: string;
  hobby: string;
  hasTriedOtherApps: boolean;
  interests: string[];
  movies: string;
  anime: string;
  favoriteCharacter: string;
  notifications: {
    daily_notifications: boolean;
  };
  favorites?: {
    movies?: string;
    anime?: string;
    series?: string;
    cartoons?: string;
    favoriteCharacter?: string;
  };
  personal_info?: {
    name: string;
    age: number;
    gender: string;
  };
}

export interface FormStepConfig {
  id: string;
  type: "boolean" | "hero" | "intro" | "personal_info" | "text" | "select" | "promo" |
    "multiSelect" | "radio" | "favorites" | "slider" | "textArea" | "notifications";
  question?: string;
  subheader?: string;
  fields?: Array<{
    id: string;
    label: string;
    type: string;
    placeholder?: string;
    options?: string[];
    min?: number;
    max?: number;
    showWhen?: string;
  }>;
  options?: string[];
  placeholder?: string;
  wordCloud?: string[];
  required?: boolean;
  min?: number;
  max?: number;
  title?: string;
  subtitle?: string;
  messages?: string[];
  skipProgress?: boolean;
}

const formSteps: FormStepConfig[] = [
  {
    id: "prescreen",
    type: "hero",
    title: "Fictional Roleplaying made easy",
    subtitle: "Just snap a quick photo of your screen and we do the rest"
  },
  {
    id: "intro",
    type: "intro",
    messages: [
      "Hi I'm Scenester your AI streaming companion",
      "Just 7 quick questions before you can chat with your fav characters!"
    ]
  },
  {
    id: "personal_info",
    type: "personal_info",
    question: "Tell Us a Bit About You",
    subheader: "Your information helps us personalize your experience by Apex.",
    fields: [
      { id: "name", label: "Name", type: "text", placeholder: "Luke Skywalker" },
      { id: "gender", label: "Gender", type: "select", options: ["Male", "Female", "Other"] },
      { id: "age", label: "Age", type: "number", min: 13, max: 100 }
    ],
    wordCloud: ["Identity", "Personal", "Connection"]
  },
  {
    id: "location",
    type: "text",
    question: "Where are you from?",
    placeholder: "A galaxy far, far away...",
    wordCloud: ["World", "Culture", "Community"]
  },
  {
    id: "hobby",
    type: "text",
    question: "What's your hobby?",
    placeholder: "Building Iron Man suits in my garage",
    wordCloud: ["Passion", "Interest", "Fun"]
  },
  {
    id: "interests",
    type: "multiSelect",
    question: "What would you like to do?",
    subheader: "Select all that apply",
    options: [
      "Texting Characters",
      "Voice Chats",
      "Study with Characters",
      "Get Recommendations",
      "Get explanations"
    ],
    wordCloud: ["Interact", "Learn", "Chat"]
  },
  {
    id: "interests-media",
    type: "radio",
    question: "What's your favorite thing to watch?",
    subheader: "Please choose one",
    required: true,
    options: ["Movie", "Anime", "Series", "Cartoons"],
    wordCloud: ["Movie", "Anime", "Series", "Cartoons"]
  },
  {
    id: "favorites",
    type: "favorites",
    question: "What are your favorites?",
    subheader: "Please separate with commas and check spelling",
    required: true,
    fields: [
      { id: "movies", label: "Favorite movies", type: "text", showWhen: "Movie" },
      { id: "anime", label: "Favorite anime", type: "text", showWhen: "Anime" },
      { id: "series", label: "Favorite series", type: "text", showWhen: "Series" },
      { id: "cartoons", label: "Favorite cartoons", type: "text", showWhen: "Cartoons" }
    ],
    wordCloud: ["Movies", "Anime", "Series"]
  },
  {
    id: "notifications",
    type: "notifications",
    question: "Last one! Ready for daily notes from your favs?",
    subheader: "Personalized words to inspire, motivate, or make you smile.",
    required: false,
    skipProgress: false
  }
];

interface OnboardingFormProps {
  onComplete?: () => void;
  session?: any;
  isOnboarded?: boolean;
  setInitialDetails?: (details: any) => void;
  user?: User;
  setIsLoading: (loading: boolean) => void;
  initialDetails?: {
    name: string;
    age: number;
    gender: string;
    location: string;
    profession: string;
    hobby: string;
    favoriteMedia?: {
      movies: string[];
      anime: string[];
      series: string[];
      cartoons: string[];
      characters: string[];
    };
  };
}

const heroSlides = [
  {
    image: emojisHero,
    title: "Fictional Roleplaying made easy",
    subtitle: "Just snap a quick photo of your screen and we do the rest"
  },
  {
    image: scenesterLivingroom,
    title: "Scenester your streaming companion",
    subtitle: "Built to end choice overload, plot confusion, and endless scrolling to find the right thing to watch"
  },
  {
    image: dailyMailMaxFeature,
    title: "Daily messages from your favs",
    subtitle: "Get daily mail from your favourite characters when you are away"
  }
];

export function OnboardingForm({
                                 onComplete,
                                 session,
                                 initialDetails,
                                 setInitialDetails,
                                 user,
                                 setIsLoading
                               }: OnboardingFormProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideContainerRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  interface HeroSlide {
    image: string;
    title: string;
    subtitle: string;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % heroSlides.length);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!slideContainerRef.current) return;

    const container = slideContainerRef.current;
    gsap.to(container.children, {
      x: `${-currentSlide * 100}%`,
      duration: 0.5,
      ease: "power2.out"
    });
  }, [currentSlide]);

  const autoAdvanceRef = useRef<gsap.core.Tween | null>(null);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    age: 25,
    gender: "",
    location: "",
    profession: "",
    hobby: "",
    hasTriedOtherApps: false,
    interests: [],
    favorites: {
      movies: "",
      anime: "",
      series: "",
      cartoons: "",
      favoriteCharacter: ""
    },
    movies: "",
    anime: "",
    favoriteCharacter: "",
    notifications: {
      daily_notifications: true
    }
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!initialDetails) {
        try {
          const details = await authService.getUserDetails(session);
          if (details) {
            setFormData(prev => ({
              ...prev,
              name: details.name || prev.name,
              age: details.age || prev.age,
              gender: details.gender || prev.gender,
              location: details.location || prev.location,
              profession: details.profession || prev.profession,
              hobby: details.hobby || prev.hobby,
              favorites: {
                ...prev.favorites,
                movies: details.favoriteMedia?.movies?.[0] || "",
                anime: details.favoriteMedia?.anime?.[0] || "",
                series: details.favoriteMedia?.series?.[0] || "",
                cartoons: details.favoriteMedia?.cartoons?.[0] || "",
                favoriteCharacter: details.favoriteMedia?.characters?.[0] || ""
              }
            }));
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
          toast.error("Failed to load user details");
        }
      }
    };

    fetchUserDetails();
  }, [session, initialDetails]);

  const [introMessageIndex, setIntroMessageIndex] = useState(0);

  useEffect(() => {
    if (currentStep === 1 && introMessageIndex < (formSteps[1]?.messages?.length ?? 0) - 1) {
      const timer = setTimeout(() => {
        setIntroMessageIndex(prev => prev + 1);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [currentStep, introMessageIndex]);

  const handleNext = async () => {
    if (currentStep < formSteps.length - 1) {
      setCurrentStep(prev => prev + 1);
    } else {
      try {
        const detailsToSubmit = {
          name: formData.personal_info?.name || formData.name,
          age: formData.personal_info?.age || formData.age,
          gender: formData.personal_info?.gender || formData.gender,
          location: formData.location,
          profession: formData.profession,
          hobby: formData.hobby,
          favoriteMedia: {
            movies: formData.favorites?.movies ? [formData.favorites.movies] : [],
            anime: formData.favorites?.anime ? [formData.favorites.anime] : [],
            series: formData.favorites?.series ? [formData.favorites.series] : [],
            cartoons: formData.favorites?.cartoons ? [formData.favorites.cartoons] : [],
            characters: formData.favorites?.favoriteCharacter ? [formData.favorites.favoriteCharacter] : []
          }
        };

        await authService.updateUserDetails(session, detailsToSubmit);

        await updateUserConfig(session, {
          config: {},
          notifications: {
            daily_notifications: formData.notifications.daily_notifications
          }
        });

        if (setInitialDetails) {
          setInitialDetails(detailsToSubmit);
        }

        toast.success("Profile setup complete!");

        setIsLoading(true);
        const response = await sendExportMessage(session, detailsToSubmit);
        setIsLoading(false);

        if (response.status === 200) {
          navigate(`/export/${response.data.export_id}`, {
            replace: true,
            state: { origin: "onboarding", user }
          });
        }
        onComplete?.();
      } catch (error) {
        setIsLoading(false);
        console.error("Error updating user details:", error);
        toast.error("Failed to update profile. Please try again.");
      }
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const handleChange = (field: keyof FormData, value: any) => {
    setFormData(prev => {
      if (field === "personal_info") {
        return {
          ...prev,
          personal_info: value,
          // Also update the top-level fields for backward compatibility
          name: value.name || prev.name,
          age: value.age || prev.age,
          gender: value.gender || prev.gender
        };
      }
      return {
        ...prev,
        [field]: value
      };
    });
  };

  const isStepRequired = formSteps[currentStep]?.required ?? false;
  const isStepValid = isFormStepValid(formSteps[currentStep], formData[formSteps[currentStep].id as keyof FormData]);

  useEffect(() => {
    // console.log("Rendering step:", currentStep, formSteps[currentStep]);
  }, [currentStep]);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        maxWidth: { xs: "100%", sm: "390px" },
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        background: "#1c1c1f",
        color: "#ffffff",
        position: "relative",
        overflow: "hidden",
        pb: "60px"
      }}
    >
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", position: "relative" }}>
        {/* Progress bar for steps after intro */}
        {currentStep > 1 && (
          <Box sx={{ pt: 5, pb: 1, px: 4, zIndex: 5 }}>
            <Box
              sx={{
                height: 2,
                width: "92%",
                margin: "0 auto",
                background: "rgba(255, 255, 255, 0.1)",
                overflow: "hidden"
              }}
            >
              <Box
                ref={progressRef}
                sx={{
                  height: "100%",
                  background: "linear-gradient(90deg, #FF9500 0%, #FF2D55 100%)",
                  width: `${((currentStep - 1) / (formSteps.length - 2)) * 100}%`,
                  transition: "width 0.3s ease-in-out"
                }}
              />
            </Box>
          </Box>
        )}

        {/* Back button for steps after intro */}
        {currentStep > 0 && (
          <IconButton
            onClick={handleBack}
            sx={{
              position: "absolute",
              top: 35,
              left: 16,
              color: "#ffffff",
              zIndex: 1
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
        )}

        {/* Main content based on current step */}
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {currentStep === 0 ? (
            // Hero section
            <Box>
              <Box
                ref={slideContainerRef}
                sx={{
                  display: "flex",
                  width: "100%",
                  overflow: "hidden",
                  position: "relative"
                }}
              >
                {heroSlides.map((slide, index) => (
                  <Box
                    key={index}
                    sx={{
                      flex: "none",
                      width: "100%",
                      position: "relative"
                    }}
                  >
                    <Box
                      component="img"
                      src={slide.image}
                      sx={{
                        width: "100%",
                        aspectRatio: "16/9",
                        objectFit: "cover"
                      }}
                    />
                    <Box
                      sx={{
                        padding: "24px 20px",
                        textAlign: "left"
                      }}
                    >
                      <Box
                        sx={{ fontSize: "28px", fontWeight: 600, lineHeight: 1.2, mb: 1.5, letterSpacing: "-0.02em" }}>
                        {slide.title}
                      </Box>
                      <Box sx={{ color: "rgba(255, 255, 255, 0.8)", fontSize: "15px", lineHeight: 1.5 }}>
                        {slide.subtitle}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", gap: 1, mt: 2, mb: 3 }}>
                {heroSlides.map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: 6,
                      height: 6,
                      borderRadius: "50%",
                      backgroundColor: index === currentSlide ? "#FF9500" : "rgba(255, 255, 255, 0.3)",
                      transition: "background-color 0.3s ease"
                    }}
                  />
                ))}
              </Box>
            </Box>
          ) : currentStep === 1 ? (
            // Intro screen
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column", pt: 6 }}>
              <Box sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                px: 3
              }}>
                <Box sx={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "18px",
                  padding: "16px 20px",
                  maxWidth: "280px",
                  textAlign: "center",
                  mb: 4,
                  position: "relative",
                  "&:after": {
                    content: "\"\"",
                    position: "absolute",
                    bottom: -8,
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: 0,
                    height: 0,
                    borderLeft: "8px solid transparent",
                    borderRight: "8px solid transparent",
                    borderTop: "8px solid rgba(255, 255, 255, 0.1)"
                  }
                }}>
                  <AnimatedText text={formSteps[1]?.messages?.[introMessageIndex] ?? ""} />
                </Box>
                <Box sx={{ width: 120 }}>
                  <Mascot />
                </Box>
              </Box>
            </Box>
          ) : (
            // Form steps
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", alignItems: "flex-start", px: 3, mt: 6, gap: 2 }}>
                <Box sx={{ width: 80, flexShrink: 0 }}>
                  <Mascot />
                </Box>
                <Box sx={{
                  flex: 1,
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "18px",
                  padding: "12px 16px",
                  position: "relative",
                  "&:before": {
                    content: "\"\"",
                    position: "absolute",
                    left: -8,
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: 0,
                    height: 0,
                    borderTop: "8px solid transparent",
                    borderBottom: "8px solid transparent",
                    borderRight: "8px solid rgba(255, 255, 255, 0.1)"
                  }
                }}>
                  <AnimatedText text={formSteps[currentStep]?.question ?? ""} />
                </Box>
              </Box>
              <Box sx={{ flex: 1, mt: 3 }}>
                <FormStep
                  config={formSteps[currentStep]}
                  value={formData[formSteps[currentStep].id as keyof FormData]}
                  formData={formData}
                  onChange={(value: any) => handleChange(formSteps[currentStep].id as keyof FormData, value)}
                  onNext={handleNext}
                  onBack={handleBack}
                  isFirst={currentStep === 0}
                  isLast={currentStep === formSteps.length - 1}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {/* Continue button */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: "calc(100% - 32px)",
          maxWidth: { xs: "calc(100% - 32px)", sm: "358px" },
          p: 2,
          background: "linear-gradient(to top, #1c1c1f 80%, transparent)",
          zIndex: 10,
          paddingBottom: { xs: 'max(16px, env(safe-area-inset-bottom))', sm: '16px' },
        }}
      >
        <Button
          onClick={handleNext}
          disabled={isStepRequired && !isStepValid}
          fullWidth
          sx={{
            py: 1.5,
            borderRadius: 3,
            background: "linear-gradient(45deg, #FF9500 30%, #FF2D55 90%)",
            color: "#ffffff",
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "none",
            opacity: isStepRequired && !isStepValid ? 0.5 : 1,
            boxShadow: "0 3px 5px 2px rgba(255, 45, 85, .3)",
            "&:hover": {
              background: "linear-gradient(45deg, #FF9500 30%, #FF2D55 90%)",
              boxShadow: "0 4px 6px 2px rgba(255, 45, 85, .4)"
            }
          }}
        >
          {currentStep === formSteps.length - 1 ? "Finish" : "Continue"}
        </Button>
      </Box>
    </Box>
  );
}