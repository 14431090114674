import React, { ReactNode, useContext } from "react";
import "../../assets/css/maxLabel.css"; // Ensure this path is correct
import { ModalContext } from "../modals/ModalContext";
import { useAuthStore } from "../../stores/useAuthStore";

interface CornerMaxButtonProps {
  children: ReactNode;
  blockIsActive?: boolean;
  location?: "options-menu" | "options-item" | "chat-menu" | "default";
}

const CornerMaxButton: React.FC<CornerMaxButtonProps> = ({
                                                           children,
                                                           blockIsActive = true,
                                                           location = "default"
                                                         }) => {
  const modalContext = useContext(ModalContext);
  const { user, session, signOut, isLoading, setUser } = useAuthStore();


  const handleClickCapture = (e: React.MouseEvent<HTMLDivElement>) => {
    if (user && user?.isPremium != "premium") {
      e.preventDefault();
      e.stopPropagation();
      if (modalContext) {
        modalContext.openModal();
      } else {
        console.error("ModalContext is not available");
      }
    }
  };

  const getLabelClass = () => {
    switch (location) {
      case "options-menu":
        return "max-label-text-options-menu";
      case "options-item":
        return "max-label-text-options-item";
      case "chat-menu":
        return "max-label-text-chat-menu";
      case "default":
      default:
        return "max-label-text";
    }
  };

  return (
    <div
      className="corner-max-wrapper"
      onClickCapture={blockIsActive ? handleClickCapture : undefined}
    >
      <div className="corner-max-content">
        {children}
      </div>
      <div className="corner-max-label">
        <span className={getLabelClass()}>MAX</span>
      </div>
    </div>
  );
};

export default CornerMaxButton;
