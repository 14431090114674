import "./assets/css/app.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { setWithExpiry } from "./helper/storageUtils";
import { fetchVersion, getTimeUntilRefresh } from "./api/apiClient";
import { ToastContainer } from "react-toastify";
import { gsap } from "gsap";
import Profile from "./components/user/Profile";
import Chat from "./components/chat/Chat";

import PaymentSuccessful from "./components/payments/PaymentSuccessful";
import PaymentFailed from "./components/payments/PaymentFailed";
import log from "loglevel";
import * as Sentry from "@sentry/react";
import { convertAuthenticatedUserToUser } from "./helper/authHelper";
import VoiceChat from "./components/chat/VoiceChat";
import VoiceChatVapi from "./components/chat/VoiceChatVapi";
import ChatExport from "./components/chat/ChatExport";
import NewChat from "./components/chat/NewChat";
import CameraCaptureSearch from "./components/miscs/CameraCaptureSearch";
import ErrorPage from "./components/miscs/ErrorPage";
import UnsubscribePage from "./components/miscs/UnsubscribePage";
import CheckoutModal from "./components/payments/CheckoutModal";
import { ModalContext } from "./components/modals/ModalContext";
import HybridSystemProvider from "./components/user/HybridSystem";
import ChatRouter from "./components/chat/ChatRouter";
import { PostList } from "./components/export/PostList";
import { StepType, useTour } from "@reactour/tour";
import { Skeleton } from "@mui/material";
import { keyframes } from "@emotion/react";
import { Layout } from "./components/navigation/Layout";
import { FAQ } from "./components/FAQ";
import { useAuthStore } from "./stores/useAuthStore";
import { AnimatePresence, motion } from "framer-motion";
import Search from "./components/search/Search";
import BottomMenuBar from "./components/template/BottomMenuBar";

// Add shimmer animation
const shimmer = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
`;

// Replace the simple LoadingSpinner with an enhanced SkeletonLayout
const SkeletonLayout = () => (
  <div className="skeleton-app-layout">
    {/* Main content area skeleton */}
    <div className="skeleton-main-content">
      {/* Header area */}
      <Skeleton
        variant="rounded"
        height={56}
        sx={{
          borderRadius: "12px",
          bgcolor: "rgba(255, 255, 255, 0.08)",
          mb: 2
        }}
      />

      {/* Content grid */}
      <div className="skeleton-grid">
        {[...Array(8)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rounded"
            width={78}
            height={117}
            animation="wave"
            sx={{
              borderRadius: "10px",
              bgcolor: "rgba(255, 255, 255, 0.08)",
              position: "relative",
              overflow: "hidden",
              boxShadow: "0 2px 11px rgba(0, 0, 0, 0.7)",
              filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              "&::after": {
                content: "\"\"",
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                transform: "translateX(-100%)",
                backgroundImage: `linear-gradient(
                  90deg,
                  rgba(255, 255, 255, 0) 0,
                  rgba(255, 255, 255, 0.05) 20%,
                  rgba(255, 255, 255, 0.1) 60%,
                  rgba(255, 255, 255, 0)
                )`,
                animation: `${shimmer} 2s infinite`,
                animationDelay: `${index * 0.1}s`
              }
            }}
          />
        ))}
      </div>
    </div>

    {/* Bottom navigation skeleton */}
    <Skeleton
      variant="rounded"
      height={60}
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: "12px 12px 0 0",
        bgcolor: "rgba(255, 255, 255, 0.08)"
      }}
    />
  </div>
);

// Create a simple Home component (you can move this to a separate file)
const Home = () => {
  return (
    <div className="home-container">
      {/* Your home content */}
    </div>
  );
};

// Optimize page transition animations
const PageTransition = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  
  // Skip animation for certain routes
  if (location.pathname.includes('/export')) {
    return <>{children}</>;
  }
  
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial={{ 
          opacity: 0,
          y: 10,
        }}
        animate={{ 
          opacity: 1,
          y: 0,
        }}
        exit={{ 
          opacity: 0,
          y: 5,
        }}
        transition={{
          duration: 0.2,
          ease: [0.32, 0.72, 0, 1],
          willChange: "transform, opacity"
        }}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          backfaceVisibility: "hidden",
          WebkitFontSmoothing: "subpixel-antialiased",
          transform: "translate3d(0,0,0)"
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

function App() {
  const { user, session, isLoading: authLoading, initializeAuth } = useAuthStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAppReady, setIsAppReady] = useState(false);
  const hasRun = useRef(false);
  const location = useLocation();
  const { setSteps, setCurrentStep, setIsOpen } = useTour();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);
  const [userUUID, setUserUUID] = useState<string | undefined>(undefined);
  const [previousLocation, setPreviousLocation] = useState<string | null>(null);
  const [tourHistory, setTourHistory] = useState<{ path: string, step: number }[]>([]);
  const currentStepRef = useRef(0);
  const [tourState, setTourState] = useState({
    previousLocation: null as string | null,
    currentStep: 0,
    history: [] as { path: string; step: number }[]
  });

  useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true;
      initializeAuth();
    }
  }, []);

  // Track current step changes
  const handleStepChange = (step: number) => {
    currentStepRef.current = step;
    setCurrentStep(step);
  };

  // Handle tour navigation
  const handleBack = React.useCallback(() => {
    setTourState(prev => {
      if (prev.history.length > 0) {
        const lastLocation = prev.history[prev.history.length - 1];
        navigate(lastLocation.path);
        setCurrentStep(lastLocation.step);
        return {
          ...prev,
          currentStep: lastLocation.step,
          history: prev.history.slice(0, -1)
        };
      }
      return prev;
    });
  }, [navigate, setCurrentStep]);

  // Set up tour steps based on current page
  useEffect(() => {
    if (!setSteps) return;

    // Reset step when location changes
    setCurrentStep(0);

    const steps: StepType[] = location.pathname === "/chat"
      ? [
        {
          selector: ".character-list-item-1",
          content: "👋 Tap a character to start chatting",
          position: "bottom" as const,
          styles: {
            popover: (base) => ({
              ...base,
              zIndex: 1000
            })
          }
        },
        {
          selector: ".chat-message-0",
          content: "💬 Your character's messages appear here",
          position: "bottom" as const,
          styles: {
            popover: (base) => ({
              ...base,
              zIndex: 1000
            })
          }
        },
        {
          selector: ".chat-input-textarea",
          content: "✍️ Type your message and hit send",
          position: "top" as const,
          styles: {
            popover: (base) => ({
              ...base,
              zIndex: 1000
            })
          }
        },
        {
          selector: ".chat-input-textarea",
          content: "🎉 You're all set! Happy chatting with your character!",
          position: "top" as const,
          styles: {
            popover: (base) => ({
              ...base,
              zIndex: 1000,
              background: "linear-gradient(135deg, var(--scenextras-orange), #FF2D55)",
              color: "white"
            })
          }
        }
      ]
      : location.pathname === "/"
        ? [
          {
            selector: ".movie-display-item-0",
            content: "👆 Tap any movie to see the cast",
            position: "bottom" as const,
            styles: {
              popover: (base) => ({
                ...base,
                zIndex: 1000
              })
            }
          },
          {
            selector: ".camera-icon-highlight",
            content: "📸 Scan a movie poster or paused screen to start",
            position: "bottom" as const,
            styles: {
              popover: (base) => ({
                ...base,
                zIndex: 1000
              })
            }
          }
        ]
        : [];

    setSteps(steps);
  }, [location.pathname, setSteps, setCurrentStep]);

  // Handle message input completion
  const handleMessageSubmit = React.useCallback(() => {
    setTimeout(() => {
      setCurrentStep(3); // Move to completion step with a slight delay
    }, 500);
  }, [setCurrentStep]);

  // Expose message submit handler globally
  useEffect(() => {
    (window as any).handleTourMessageSubmit = handleMessageSubmit;
    return () => {
      delete (window as any).handleTourMessageSubmit;
    };
  }, [handleMessageSubmit]);

  // Watch for chat message appearance
  useEffect(() => {
    if (location.pathname === "/chat") {
      const observer = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
          if (mutation.addedNodes.length) {
            const messageElement = document.querySelector(".chat-message-0");
            if (messageElement) {
              setCurrentStep(1); // Move to message step
              observer.disconnect();
            }
          }
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });

      return () => observer.disconnect();
    }
  }, [location.pathname, setCurrentStep]);

  // const openTour = React.useCallback(() => {
  //   setIsOpen(true);
  //   setCurrentStep(0);
  // }, [setIsOpen, setCurrentStep]);

  useEffect(() => {
    handleStepChange(0);
    if (setSteps) {
      // Save previous location when changing pages
      if (location.pathname !== previousLocation) {
        if (previousLocation) {
          setTourHistory(prev => [...prev, {
            path: previousLocation,
            step: currentStepRef.current
          }]);
        }
        setPreviousLocation(location.pathname);
      }

      if (location.pathname === "/chat") {
        setSteps([
          {
            selector: ".character-list-item-1",
            content: "👋 Tap a character to start chatting",
            position: "bottom",
            action: handleBack
          },
          {
            selector: ".chat-message-0",
            content: "💬 Your character's messages appear here",
            position: "bottom",
            action: handleBack
          },
          {
            selector: ".chat-input-textarea",
            content: "✍️ Type your message and hit send",
            position: "top",
            action: handleBack
          }
        ]);
      } else if (location.pathname === "/") {
        setSteps([
          {
            selector: ".movie-display-item-0",
            content: "👆 Tap any movie to see the cast",
            position: "bottom",
            action: handleBack
          },
          {
            selector: ".camera-icon-highlight",
            content: "📸 Scan a movie poster or paused screen to start",
            position: "bottom",
            action: handleBack
          }
        ]);
      }
    }
  }, [location.pathname, setSteps, navigate, previousLocation]);

  useEffect(() => {
    log.info("App ready state:", {
      isAppReady,
      loading,
      hasUser: !!user,
      pathname: location.pathname
    });
  }, [isAppReady, loading, user, location.pathname]);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;

    fetchVersion();
    fetchAndStoreTimestamp();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const fetchAndStoreTimestamp = async () => {
    try {
      const refreshData = await getTimeUntilRefresh();
      if (refreshData) {
        const futureTimestamp = refreshData.time_until_refresh * 1000;
        setWithExpiry("refreshTimestamp", futureTimestamp, 60000);
      }
    } catch (error) {
      Sentry.captureException(error);
      log.error(error);
    }
  };

  // Fixed animation effect
  useEffect(() => {
    if (!isAppReady) return;

    const tl = gsap.timeline({
      paused: true,
      defaults: { 
        force3D: true,
        ease: "power2.out"
      }
    });

    tl.fromTo(".loading-logo",
      { opacity: 0, scale: 0.8 },
      { opacity: 1, scale: 1, duration: 0.3 }
    ).to(".loading-logo", {
      opacity: 0,
      scale: 1.1,
      duration: 0.3,
      delay: 0.5
    });

    tl.play();

    // Proper cleanup
    return () => {
      tl.kill();
    };
  }, [isAppReady]);

  useEffect(() => {
    document.documentElement.style.scrollBehavior = "smooth";
  }, []);

  // Modify preload sequence
  useEffect(() => {
    const preloadAssets = async () => {
      try {
        // Only show loading screen on first visit
        const hasVisited = sessionStorage.getItem("hasVisited");
        
        if (!hasVisited) {
          document.documentElement.setAttribute("data-loading", "true");
          setLoading(true);
          
          // Preload critical assets in parallel
          await Promise.all([
            new Promise((resolve) => {
              const img = new Image();
              img.src = "/logo.svg";
              img.setAttribute('loading', 'eager');
              img.setAttribute('fetchpriority', 'high');
              img.onload = resolve;
              img.onerror = resolve;
            }),
            fetchVersion()
          ]);

          sessionStorage.setItem("hasVisited", "true");
          setTimeout(() => {
            setLoading(false);
            document.documentElement.removeAttribute("data-loading");
          }, 1000);
        }

        setIsAppReady(true);

      } catch (error) {
        console.error("Asset preloading failed:", error);
        setIsAppReady(true);
        setLoading(false);
        document.documentElement.removeAttribute("data-loading");
      }
    };

    preloadAssets();
  }, []);

  return (
    <ModalContext.Provider value={{ openModal: () => setIsModalOpen(true) }}>
      <HybridSystemProvider userEmail={user?.email}>
        <ToastContainer />
        {isModalOpen && user && (
          <CheckoutModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            user={convertAuthenticatedUserToUser(user)}
            displaySource="account"
          />
        )}
        <div className="App">
          <Suspense fallback={<SkeletonLayout />}>
            <Layout onUpgradeClick={openModal}>
              <div className="main-content">
                <PageTransition>
                  <Routes>
                    <Route path="/" element={
                      <Suspense fallback={<SkeletonLayout />}>
                        {<Search />}
                      </Suspense>
                    } />
                    <Route path="/profile" element={
                      <Suspense fallback={<SkeletonLayout />}>
                        <Profile />
                      </Suspense>
                    } />
                    <Route path="/search" element={<Search />} />
                    <Route path="/scenario" element={<PostList />} />
                    <Route path="/chat" element={<Chat chat_type="popular" />} />
                    <Route path="/chat/*" element={<ChatRouter />} />
                    <Route path="/payment-success" element={<PaymentSuccessful />} />
                    <Route path="/payment-failure" element={<PaymentFailed />} />
                    <Route path="/voice-chat" element={<VoiceChat />} />
                    <Route path="/scene-camera" element={<CameraCaptureSearch />} />
                    <Route path="/voice-chat-vapi" element={<VoiceChatVapi />} />
                    <Route path="/new_chat" element={<NewChat />} />
                    <Route path="/api/email/unsubscribe/:token" element={<UnsubscribePage />} />
                    <Route path="/export" element={<ChatExport />} />
                    <Route path="/export/:exportId" element={<ChatExport />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="*" element={<ErrorPage />} />
                  </Routes>
                </PageTransition>
              </div>
            </Layout>
          </Suspense>
        </div>
        <BottomMenuBar />
      </HybridSystemProvider>
    </ModalContext.Provider>
  );
}

export default App;