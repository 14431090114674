export function getImageUrl(path?: string, type: 'poster' | 'backdrop' = 'poster'): string {
  if (!path) {
    return `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`;
  }

  if (path.includes("/static/media/") || path.startsWith("https")) {
    return path;
  }

  const size = type === 'poster' ? 'w500' : 'original';
  return `https://image.tmdb.org/t/p/${size}${path}`;
}

export function getBackdropUrl(movie: { backdrop_path?: string; poster_path?: string }): string | undefined {
  if (!movie.backdrop_path && !movie.poster_path) {
    return undefined;
  }

  const path = movie.backdrop_path || movie.poster_path;
  return path ? getImageUrl(path, 'backdrop') : undefined;
} 