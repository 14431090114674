import React from "react";
import { Helmet } from "react-helmet";

type SEOProps = {
  title: string;
  description: string;
  url: string;
  image: string;
  type?: string;
  character?: {
    name: string;
    series: string;
    description: string;
    image: string;
  };
  keywords?: string[];
};

const SEO: React.FC<SEOProps> = ({ 
  title, 
  description, 
  url, 
  image, 
  type = "website",
  character,
  keywords = []
}) => {
  // Base keywords that should be included for all pages
  const baseKeywords = [
    "scenextras",
    "ai fanfiction",
    "character chat",
    "interactive stories",
    "ai companion",
    "virtual character",
    "immersive chat"
  ];

  // Combine base keywords with page-specific keywords
  const allKeywords = [...baseKeywords, ...keywords].join(", ");

  // Generate structured data based on page type
  const getStructuredData = () => {
    const baseData = {
      "@context": "https://schema.org",
      "@type": character ? "Character" : "WebPage",
      "url": url,
      "name": title,
      "description": description,
      "image": image,
      "isPartOf": {
        "@type": "WebSite",
        "name": "SceneXtras",
        "url": "https://www.scenextras.com"
      }
    };

    if (character) {
      return {
        ...baseData,
        "@type": "Character",
        "characterAttribute": character.name,
        "genre": "AI Fanfiction",
        "creator": {
          "@type": "Organization",
          "name": "SceneXtras",
          "url": "https://www.scenextras.com"
        },
        "isBasedOn": {
          "@type": "CreativeWork",
          "name": character.series
        },
        "interactionStatistic": {
          "@type": "InteractionCounter",
          "interactionType": "https://schema.org/InteractAction",
          "userInteractionCount": "1000+"
        }
      };
    }

    return baseData;
  };

  return (
    <Helmet>
      {/* Essential Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={allKeywords} />
      <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1" />

      {/* OpenGraph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content="SceneXtras" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/jpeg" />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@scenextras" />
      <meta name="twitter:creator" content="@scenextras" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* Mobile & PWA Tags */}
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="theme-color" content="#000000" />

      {/* Canonical URL */}
      <link rel="canonical" href={url} />

      {/* Schema.org Markup */}
      <script type="application/ld+json">
        {JSON.stringify(getStructuredData())}
      </script>

      {/* Preconnect to Critical Origins */}
      <link rel="preconnect" href="https://framerusercontent.com" />
      <link rel="preconnect" href="https://cdn.myanimelist.net" />
    </Helmet>
  );
};

export default SEO;
