import React, { useEffect } from "react";
import { Box, Slider, TextField, Typography, Select, MenuItem, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormStepConfig } from "./OnboardingForm";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    borderRadius: 12,
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.2)",
      borderWidth: 2
    },
    "&:hover fieldset": {
      borderColor: "rgba(255, 255, 255, 0.3)"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF9500"
    }
  },
  "& .MuiInputLabel-root": {
    color: "rgba(255, 255, 255, 0.7)"
  }
});

interface FormStepProps {
  config: FormStepConfig;
  value: any;
  formData: any;
  onChange: (value: any) => void;
  onNext: () => void;
  onBack: () => void;
  isFirst: boolean;
  isLast: boolean;
}

export function isFormStepValid(config: any, value: any): boolean {
  if (config.required) {
    if (config.type === "favorites") {
      return Object.values(value || {}).some(v => v && String(v).trim() !== "");
    }
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return Boolean(value);
  }
  return true;
}

interface DynamicObject {
  [key: string]: any;
}

function renderSelectOrMulti(config: any, value: any, onChange: (value: any) => void, isMulti: boolean) {
  const options = config.options;

  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        px: 2
      }}
    >
      {options.map((option: string) => {
        const isSelected = isMulti
          ? Array.isArray(value) && value.includes(option)
          : value === option;

        return (
          <Box
            key={option}
            onClick={() => {
              if (isMulti) {
                const newValue = Array.isArray(value) ? [...value] : [];
                const index = newValue.indexOf(option);
                if (index === -1) {
                  newValue.push(option);
                } else {
                  newValue.splice(index, 1);
                }
                onChange(newValue);
              } else {
                onChange(option);
              }
            }}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "12px",
              padding: "12px 16px",
              color: "#ffffff",
              cursor: "pointer",
              transition: "all 0.2s ease",
              display: "flex",
              alignItems: "center",
              fontSize: "15px",
              fontWeight: isSelected ? 600 : 400,
              border: isSelected ? "2px solid #FF9500" : "2px solid transparent",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.08)"
              }
            }}
          >
            {option}
          </Box>
        );
      })}
    </Box>
  );
}

export function FormStep({ config, value, formData, onChange, onNext }: FormStepProps) {
  const isValid = isFormStepValid(config, value);

  useEffect(() => {
    if (config.type === "personal_info" && !value) {
      const initialValue: DynamicObject = {};
      config.fields?.forEach(field => {
        initialValue[field.id] = formData[field.id] || "";
      });
      onChange(initialValue);
    } else if (config.type === "favorites" && !value) {
      onChange(formData.favorites || {});
    } else if (!value && formData[config.id]) {
      onChange(formData[config.id]);
    }
  }, [config.id, config.type, formData]);

  const handleNext = () => {
    if (isValid) {
      onNext();
    }
  };

  const renderInput = () => {
    switch (config.type) {
      case "notifications":
        return (
          <Box sx={{ mt: 2, px: 2 }}>
            {config.subheader && (
              <Typography
                variant="body2"
                sx={{
                  color: "rgba(255, 255, 255, 0.7)",
                  mb: 2,
                  fontSize: "15px"
                }}
              >
                {config.subheader}
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "rgba(255, 255, 255, 0.05)",
                borderRadius: "12px",
                padding: "16px",
                color: "#ffffff"
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: 500 }}>Daily Character Messages</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                >
                  Get fun messages from your favorite characters
                </Typography>
              </Box>
              <Switch
                checked={value?.daily_notifications || false}
                onChange={(e) => onChange({ daily_notifications: e.target.checked })}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#FF9500',
                    '& + .MuiSwitch-track': {
                      backgroundColor: '#FF9500'
                    }
                  }
                }}
              />
            </Box>
          </Box>
        );

      case "slider":
        return (
          <Box sx={{ px: 3, mt: 4 }}>
            <Slider
              value={value || config.min || formData.age || config.min}
              onChange={(_, newValue) => onChange(newValue)}
              min={config.min}
              max={config.max}
              sx={{
                color: "#FF9500",
                "& .MuiSlider-thumb": {
                  width: 24,
                  height: 24,
                  backgroundColor: "#ffffff",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  "&:hover, &.Mui-focusVisible": {
                    boxShadow: "0 3px 6px rgba(0,0,0,0.3)"
                  }
                },
                "& .MuiSlider-rail": {
                  opacity: 0.3
                }
              }}
            />
            <Box sx={{ textAlign: "center", mt: 2, color: "rgba(255, 255, 255, 0.8)" }}>
              {value || formData.age || config.min} years old
            </Box>
          </Box>
        );

      case "radio":
        return (
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 1.5, px: 2 }}>
            {config.subheader && (
              <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)", mb: 1 }}>
                {config.subheader}
              </Typography>
            )}
            {config.options?.map((option: string) => (
              <Box
                key={option}
                onClick={() => onChange(option)}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  borderRadius: "12px",
                  padding: "12px 16px",
                  color: "#ffffff",
                  cursor: "pointer",
                  transition: "all 0.2s ease",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  fontSize: "15px",
                  fontWeight: value === option ? 600 : 400,
                  border: value === option ? "2px solid #FF9500" : "2px solid transparent",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.08)"
                  }
                }}
              >
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                    border: "2px solid",
                    borderColor: value === option ? "#FF9500" : "rgba(255, 255, 255, 0.5)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  {value === option && (
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        backgroundColor: "#FF9500"
                      }}
                    />
                  )}
                </Box>
                {option}
              </Box>
            ))}
          </Box>
        );

      case "select":
        return (
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
              px: 2
            }}
          >
            {config.subheader && (
              <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)", mb: 1 }}>
                {config.subheader}
              </Typography>
            )}
            {config.options?.map((option: string) => (
              <Box
                key={option}
                onClick={() => onChange(option)}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  borderRadius: "12px",
                  padding: "12px 16px",
                  color: "#ffffff",
                  cursor: "pointer",
                  transition: "all 0.2s ease",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "15px",
                  fontWeight: value === option ? 600 : 400,
                  border: value === option ? "2px solid #FF9500" : "2px solid transparent",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.08)"
                  }
                }}
              >
                {option}
              </Box>
            ))}
          </Box>
        );

      case "multiSelect":
        return (
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
              px: 2
            }}
          >
            {config.subheader && (
              <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)", mb: 1 }}>
                {config.subheader}
              </Typography>
            )}
            {config.options?.map((option: string) => (
              <Box
                key={option}
                onClick={() => {
                  const newValue = Array.isArray(value) ? [...value] : [];
                  const index = newValue.indexOf(option);
                  if (index === -1) {
                    newValue.push(option);
                  } else {
                    newValue.splice(index, 1);
                  }
                  onChange(newValue);
                }}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  borderRadius: "12px",
                  padding: "12px 16px",
                  color: "#ffffff",
                  cursor: "pointer",
                  transition: "all 0.2s ease",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "15px",
                  fontWeight: Array.isArray(value) && value.includes(option) ? 600 : 400,
                  border: Array.isArray(value) && value.includes(option) ? "2px solid #FF9500" : "2px solid transparent",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.08)"
                  }
                }}
              >
                {option}
              </Box>
            ))}
          </Box>
        );

      case "boolean":
        return (
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
              px: 2
            }}
          >
            {config.subheader && (
              <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)", mb: 1 }}>
                {config.subheader}
              </Typography>
            )}
            {["Yes", "No"].map((option) => (
              <Box
                key={option}
                onClick={() => onChange(option === "Yes")}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  borderRadius: "12px",
                  padding: "12px 16px",
                  color: "#ffffff",
                  cursor: "pointer",
                  transition: "all 0.2s ease",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "15px",
                  fontWeight: (option === "Yes" ? value === true : value === false) ? 600 : 400,
                  border: (option === "Yes" ? value === true : value === false)
                    ? "2px solid #FF9500"
                    : "2px solid transparent",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.08)"
                  }
                }}
              >
                {option}
              </Box>
            ))}
          </Box>
        );

      case "textArea":
        return (
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2, px: 2 }}>
            {config.fields?.map((field: any) => (
              <StyledTextField
                key={field.id}
                label={field.label}
                multiline
                rows={2}
                value={value[field.id] || ""}
                onChange={(e) => {
                  const newValue = { ...value };
                  newValue[field.id] = e.target.value;
                  onChange(newValue);
                }}
                fullWidth
              />
            ))}
          </Box>
        );

      case "favorites":
        return (
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2, px: 2 }}>
            {config.subheader && (
              <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)", mb: 1 }}>
                {config.subheader}
              </Typography>
            )}
            {config.fields?.filter((field: any) => {
              const selectedMedia = formData["interests-media"];
              return field.showWhen === selectedMedia;
            }).map((field: any) => (
              <StyledTextField
                key={field.id}
                label={field.label}
                multiline
                rows={2}
                value={value?.[field.id] || formData.favorites?.[field.id] || ""}
                onChange={(e) => {
                  const newValue = { ...(value || {}) };
                  newValue[field.id] = e.target.value;
                  onChange(newValue);
                }}
                fullWidth
              />
            ))}
          </Box>
        );


      case "personal_info":
        return (
          <Box sx={{ px: 3 }}>
            {config.fields?.map((field) => {
              // Get value from either the current form state or the parent formData
              const fieldValue = (value && value[field.id]) !== undefined
                ? value[field.id]
                : formData[field.id] || "";

              const fieldMin = field.min ?? 0;
              const fieldMax = field.max ?? 100;

              let menuItems;
              if (field.type === "number") {
                menuItems = Array.from(
                  { length: fieldMax - fieldMin + 1 },
                  (_, i) => (
                    <MenuItem key={fieldMin + i} value={fieldMin + i}>
                      {fieldMin + i}
                    </MenuItem>
                  )
                );
              } else {
                menuItems = field.options?.map((opt: string) => (
                  <MenuItem key={opt} value={opt}>{opt}</MenuItem>
                ));
              }

              return (
                <Box key={field.id} sx={{ mb: 2 }}>
                  <Typography variant="body2" sx={{ mb: 1, color: "rgba(255, 255, 255, 0.7)" }}>
                    {field.label}
                  </Typography>
                  {field.type === "select" || field.type === "number" ? (
                    <Select
                      fullWidth
                      value={fieldValue}
                      onChange={(e) => {
                        const newValue = {
                          ...(value || {}),
                          [field.id]: e.target.value
                        };
                        onChange(newValue);
                      }}
                      sx={{
                        color: "#ffffff",
                        bgcolor: "rgba(255,255,255,0.05)",
                        borderRadius: 2,
                        "& .MuiSelect-select": {
                          py: 2,
                          textAlign: "left"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(255, 255, 255, 0.2)"
                        }
                      }}
                    >
                      {menuItems}
                    </Select>
                  ) : (
                    <TextField
                      fullWidth
                      type={field.type}
                      placeholder={field.placeholder}
                      value={fieldValue}
                      onChange={(e) => {
                        const newValue = {
                          ...(value || {}),
                          [field.id]: e.target.value
                        };
                        onChange(newValue);
                      }}
                      InputProps={{
                        sx: {
                          color: "#ffffff",
                          bgcolor: "rgba(255,255,255,0.05)",
                          borderRadius: 2
                        }
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        );

      default:
        return (
          <Box sx={{ mt: 2, px: 2 }}>
            <TextField
              fullWidth
              type="text"
              placeholder={config.placeholder}
              value={value || formData[config.id] || ""}
              onChange={(e) => onChange(e.target.value)}
              InputProps={{
                sx: {
                  color: "#ffffff",
                  bgcolor: "rgba(255,255,255,0.05)",
                  borderRadius: 2,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.2)"
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.3)"
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#FF9500"
                  }
                }
              }}
            />
          </Box>
        );
    }
  };

  return (
    <Box sx={{ flex: 1, overflow: "auto", pb: 8 }}>
      {renderInput()}
      {config.required && !isValid && (
        <Typography
          sx={{
            color: "#FF9500",
            mt: 2,
            px: 2,
            fontSize: "14px"
          }}
        >
          This field is required
        </Typography>
      )}
    </Box>
  );
}
