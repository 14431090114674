import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { motion } from "framer-motion";
import logo from "../../assets/img/scenextras-full-logo-1@2x.png";

const StarField = () => {
  // Create a symmetric grid of stars
  const gridSize = 8; // Reduced from 10 to 8 for better performance
  const stars = Array.from({ length: gridSize * gridSize }, (_, index) => {
    const row = Math.floor(index / gridSize);
    const col = index % gridSize;
    
    return {
      x: `${(col / (gridSize - 1)) * 100}%`,
      y: `${(row / (gridSize - 1)) * 70}%`,
      size: Math.random() * 1.5 + 0.5, // Reduced size range
      delay: (row + col) * 0.03, // Reduced delay
      isSpecial: (row + col) % 4 === 0 // Reduced frequency of special stars
    };
  });

  return (
    <>
      {stars.map((star, i) => (
        <motion.div
          key={i}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ 
            scale: 1, 
            opacity: star.isSpecial ? 0.5 : 0.3 // Reduced opacity
          }}
          transition={{
            delay: star.delay,
            duration: 0.2, // Reduced duration
            repeat: Infinity,
            repeatType: "reverse",
            repeatDelay: star.isSpecial ? 1.5 : 1 // Reduced delays
          }}
          style={{
            position: "fixed",
            left: star.x,
            top: star.y,
            width: star.size,
            height: star.size,
            borderRadius: '50%',
            background: star.isSpecial ? "#FF8A00" : "#ffffff",
            boxShadow: star.isSpecial ? "0 0 2px #ffffff" : "none", // Reduced shadow
            filter: star.isSpecial ? 'blur(0.5px)' : 'none', // Reduced blur
            zIndex: 0,
            willChange: 'transform, opacity',
            transform: 'translate3d(0,0,0)'
          }}
        />
      ))}
    </>
  );
};

const LoadingScreen = () => {
  const logoRef = useRef(null);
  const screenRef = useRef(null);

  useEffect(() => {
    // Check if we should show the loading screen
    const hasVisited = sessionStorage.getItem("hasVisited");
    if (hasVisited) {
      if (screenRef.current) {
        screenRef.current.style.display = "none";
      }
      return;
    }

    const heartbeat = gsap.timeline({ repeat: 2, repeatDelay: 0.3 })
      .to(logoRef.current, {
        scale: 1.05,
        duration: 0.1,
        ease: "power1.inOut"
      })
      .to(logoRef.current, {
        scale: 1,
        duration: 0.1,
        ease: "power1.inOut"
      });

    gsap.set(logoRef.current, { opacity: 1 });

    const fadeOut = () => {
      gsap.to(screenRef.current, {
        opacity: 0,
        duration: 0.3,
        ease: "power2.inOut",
        onComplete: () => {
          if (screenRef.current) {
            screenRef.current.style.display = "none";
          }
        }
      });
    };

    const timer = setTimeout(() => fadeOut(), 2000); // Reduced from 5000 to 2000

    return () => {
      clearTimeout(timer);
      heartbeat.kill();
    };
  }, []);

  return (
    <div ref={screenRef} className="loading-screen"
         style={{ 
           display: "flex", 
           justifyContent: "center", 
           alignItems: "center", 
           height: "100vh", 
           opacity: 1,
           backgroundColor: '#121214',
           position: 'fixed', // Changed from relative to fixed
           top: 0,
           left: 0,
           width: '100%',
           overflow: 'hidden',
           zIndex: 9999,
           transform: 'translate3d(0,0,0)',
           willChange: 'opacity'
         }}>
      <StarField />
      <img ref={logoRef} className="logo" src={logo} alt="Full Logo" 
           style={{ 
             width: "180px", // Reduced from 200px
             height: "auto", 
             position: 'relative', 
             zIndex: 1,
             willChange: 'transform',
             transform: 'translate3d(0,0,0)'
           }} />
    </div>
  );
};

export default LoadingScreen;
