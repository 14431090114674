import React, { FC, useEffect, useState } from "react";
import CornerMaxButton from "../miscs/CornerMaxButton";
import { Delete, Mic, MicOff, Public, Repeat, Settings, Person } from "@mui/icons-material";
import { RoleplayDetailsModal, RoleplayDetailsType } from "../modals/RoleplayDetailsModal";
import { Session } from "@supabase/supabase-js";
import { toast } from "react-toastify";

interface ChatMenuProps {
  toggleMicrophone: () => void;
  removeMessagesForCharacter: () => void;
  toggleFollowups: () => void;
  toggleWorldSim: () => void;
  microphoneEnabled: boolean;
  followupEnabled: boolean;
  currentCharacter: string;
  setTemporaryConfig: () => void;
  isLoadingScenarios: boolean;
  setIsLoadingScenarios: React.Dispatch<React.SetStateAction<boolean>>;
  details: RoleplayDetailsType;
  setDetails: React.Dispatch<React.SetStateAction<RoleplayDetailsType>>;
  session: Session | null;
  movieTitle: string;
  handleRoleplayMessageSubmit: (details: any) => void;
}

const ChatMenuButton: FC<ChatMenuProps> = ({
                                             toggleMicrophone,
                                             removeMessagesForCharacter,
                                             toggleFollowups,
                                             toggleWorldSim,
                                             microphoneEnabled,
                                             followupEnabled,
                                             setTemporaryConfig,
                                             currentCharacter,
                                             isLoadingScenarios,
                                             setIsLoadingScenarios,
                                             details,
                                             setDetails,
                                             session,
                                             movieTitle,
                                             handleRoleplayMessageSubmit
                                           }) => {
  const [isActive, setIsActive] = useState(false);
  const [showRoleplayModal, setShowRoleplayModal] = useState(false);
  const [previousDetails, setPreviousDetails] = useState<RoleplayDetailsType>(details);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target && !target.closest(".list-container")) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRoleplaySubmit = () => {
    const hasChanges =
      details.name !== previousDetails.name ||
      details.age !== previousDetails.age ||
      details.skills_abilities !== previousDetails.skills_abilities ||
      details.scenario !== previousDetails.scenario;

    if (hasChanges) {
      toast.success("Roleplay adventure started");
      handleRoleplayMessageSubmit(details);
    }
    setPreviousDetails(details);
  };

  const handleResetDetails = () => {
    const initialDetails: RoleplayDetailsType = {
      name: "",
      age: 0,
      skills_abilities: "",
      scenario: ""
    };
    setDetails(initialDetails);
    removeMessagesForCharacter();
  };


  const renderMenuItems = () => {
    if (currentCharacter === "Scenester") {
      return (
        <li
          className="more-button-list-item"
          onClick={handleResetDetails}
          role="button"
          tabIndex={0}
          aria-label="Reset character details"
        >
          <Delete />
          <span>Clear Chat</span>
        </li>
      );
    }

    return (
      <>
        <li
          className={`more-button-list-item ${microphoneEnabled ? "enabled" : "disabled"}`}
          onClick={() => toggleMicrophone()}
        >
          {microphoneEnabled ? <Mic /> : <MicOff />}
          <span>Voice Chat</span>
        </li>
        <li
          className="more-button-list-item"
          onClick={() => removeMessagesForCharacter()}
        >
          <Delete />
          <span>Clear Chat</span>
        </li>
        <li
          className={`more-button-list-item ${followupEnabled ? "enabled" : "disabled"}`}
          onClick={() => toggleFollowups()}
        >
          <CornerMaxButton blockIsActive={true} location="chat-menu">
            <Repeat />
            <span>Follow ups</span>
          </CornerMaxButton>
        </li>
        <li
          className="more-button-list-item"
          onClick={() => toggleWorldSim()}
        >
          <CornerMaxButton blockIsActive={true} location="chat-menu">
            <Public />
            <span>WorldSim</span>
          </CornerMaxButton>
        </li>
        <li
          className="more-button-list-item"
          onClick={() => setShowRoleplayModal(true)}
        >
          <CornerMaxButton blockIsActive={true} location="chat-menu">
            <Person />
            <span>Roleplay</span>
          </CornerMaxButton>
        </li>
        {/*<li*/}
        {/*  className={`more-button-list-item ${followupEnabled ? "enabled" : "disabled"}`}*/}
        {/*  onClick={() => setTemporaryConfig()}*/}
        {/*>*/}
        {/*  <CornerMaxButton blockIsActive={true} location="chat-menu">*/}
        {/*    <Settings />*/}
        {/*    <span>Roles</span>*/}
        {/*  </CornerMaxButton>*/}
        {/*</li>*/}
      </>
    );
  };

  return (
    <React.Fragment>
      <div className={`list-container ${isActive ? "active" : ""}`}>
        <button
          className="more-button"
          aria-label="Menu Button"
          onClick={handleClick}
        >
          <div className="menu-icon-wrapper">
            <div className="menu-icon-line"></div>
            <div className="menu-icon-line"></div>
            <div className="menu-icon-line"></div>
          </div>
        </button>
        <ul className="more-button-list">
          {renderMenuItems()}
        </ul>
      </div>

      <RoleplayDetailsModal
        open={showRoleplayModal}
        onClose={() => setShowRoleplayModal(false)}
        onSubmit={handleRoleplaySubmit}
        currentCharacter={currentCharacter}
        isLoadingScenarios={isLoadingScenarios}
        setIsLoadingScenarios={setIsLoadingScenarios}
        details={details}
        setDetails={setDetails}
        session={session}
        movieTitle={movieTitle}
      />
    </React.Fragment>
  );
};

export default ChatMenuButton;