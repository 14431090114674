import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  ClickAwayListener,
  createTheme,
  Grid,
  IconButton,
  Paper,
  Popper,
  ThemeProvider,
  Typography
} from "@mui/material";
import { AutoAwesome, EmojiEvents, Star, Whatshot, WorkspacePremium } from "@mui/icons-material";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import { animated, useSpring } from "react-spring";
import { fetchWinStreakData, updateWinStreak } from "../../api/apiClient";
import { getWithExpiry, setWithExpiry } from "../../helper/storageUtils";
import log from "loglevel";
import * as Sentry from "@sentry/react";

export interface DayStreak {
  date: string;
  completed: boolean;
}

export interface WinStreakData {
  currentStreak: number;
  weeklyProgress: DayStreak[];
  longestStreak: number;
}

export interface StepDetail {
  description: string;
  title: string;
  range: number;
}

interface HybridSystemContextType {
  openModal: () => void;
  openModalAndUpdate: () => void;
  closeModal: () => void;
}

const defaultContext: HybridSystemContextType = {
  openModal: () => {},
  openModalAndUpdate: () => {},
  closeModal: () => {}
};


interface StreakIconProps {
  day: number;
  isActive: boolean;
}

function StreakIcon({ day, isActive }: StreakIconProps) {
  const iconMap = {
    1: Star,
    2: EmojiEvents,
    3: Whatshot,
    4: WorkspacePremium,
    5: AutoAwesome
  };

  const IconComponent = iconMap[day as keyof typeof iconMap] || AutoAwesome;

  return (
    <Box
      sx={{
        width: { xs: 40, sm: 48, md: 56 },
        height: { xs: 40, sm: 48, md: 56 },
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: isActive ? "rgba(255, 167, 38, 0.1)" : "grey.100",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          transform: isActive ? "scale(1.05)" : "none",
          backgroundColor: isActive ? "rgba(255, 167, 38, 0.15)" : "grey.100"
        }
      }}
    >
      <IconComponent
        sx={{
          fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.75rem" },
          color: isActive ? "primary.main" : "grey.400",
          transition: "all 0.2s ease-in-out",
          filter: isActive ? "drop-shadow(0 0 2px rgba(255, 167, 38, 0.3))" : "none"
        }}
      />
    </Box>
  );
}

const HybridSystemContext = createContext<HybridSystemContextType>(defaultContext);

export const useHybridSystem = () => {
  return useContext(HybridSystemContext);
};


export interface HybridSystemProviderProps {
  children: ReactNode;
  userEmail?: string;
  description?: string;
  metric?: string;
  stepDetails?: StepDetail[];
  counterLabel?: string;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF9800"
    },
    grey: {
      300: "#E0E0E0"
    }
  }
});

const WIN_STREAK_KEY = "winStreakData";

// Utility function to calculate milliseconds until next midnight
const getMillisecondsUntilMidnight = () => {
  const now = new Date();
  const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
  return midnight.getTime() - now.getTime();
};


const defaultStepDetails: StepDetail[] = [
  { description: "Keep chatting!", title: "Day 1", range: 1 },
  { description: "Plot twister!", title: "Day 2", range: 2 },
  { description: "Co-star!", title: "Day 3", range: 3 },
  { description: "Supporting Act!", title: "Day 4", range: 4 },
  { description: "Canon Master!", title: "Day 5+", range: 5 }
];


export const HybridSystemProvider: React.FC<HybridSystemProviderProps> = ({
                                                                            children,
                                                                            userEmail,
                                                                            description = "Your Fandom Streak",
                                                                            metric = "Your best character chat run",
                                                                            stepDetails = defaultStepDetails,
                                                                            counterLabel = "days"
                                                                          }) => {
  if (!userEmail) {
    return (<>{children}</>);
  }

  const [isOpen, setIsOpen] = useState(false);
  const [winStreakData, setWinStreakData] = useState<WinStreakData>({
    currentStreak: 0,
    longestStreak: 0,
    weeklyProgress: []
  });
  const fabRef = useRef<HTMLButtonElement>(null);
  const midnightTimeoutRef = useRef<number | null>(null);
  const hasFetched = useRef(false);


  const isCurrentDayChecked = () => {
    const today = new Date().toISOString().split("T")[0];
    return winStreakData.weeklyProgress.some(day => day.date === today && day.completed);
  };


  const getUserTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const fetchAndSetWinStreakData = async () => {
    const timezone = getUserTimezone();
    try {
      const data = await fetchWinStreakData(timezone);
      setWinStreakData(data);
      setWithExpiry(WIN_STREAK_KEY, data, getMillisecondsUntilMidnight());
      return data;
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error loading win streak data:", error);
      return null;
    }
  };


  useEffect(() => {
    if (hasFetched.current) return; // Prevent duplicate fetches in Strict Mode
    hasFetched.current = true;

    // Initial fetch
    fetchAndSetWinStreakData();

    // Set up the midnight timeout
    const setMidnightInterval = () => {
      const msUntilMidnight = getMillisecondsUntilMidnight();
      midnightTimeoutRef.current = window.setTimeout(() => {
        fetchAndSetWinStreakData();
        setMidnightInterval(); // Schedule the next midnight fetch
      }, msUntilMidnight);
    };

    setMidnightInterval();

    // Set up the 5-minute interval
    const intervalId = setInterval(fetchAndSetWinStreakData, 300000); // 300,000 ms = 5 minutes

    // Cleanup on unmount
    return () => {
      if (midnightTimeoutRef.current !== null) {
        clearTimeout(midnightTimeoutRef.current);
      }
      clearInterval(intervalId);
    };
  }, []);

  const toggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const checkAndUpdateStreak = async (timezone: string) => {
    const storageKey = `lastStreak_${timezone}`;
    const lastUpdateData = getWithExpiry(storageKey);
    const today = new Date().toISOString().split('T')[0];

    log.info(`Checking streak - Last update: ${lastUpdateData}, Today: ${today}, Timezone: ${timezone}`);

    if (lastUpdateData === today) {
      log.info('Streak already updated today, skipping');
      return false;
    }

    try {
      const latestData = await fetchWinStreakData(timezone);
      log.info('Fetched streak data:', latestData);

      const todayProgress = latestData.weeklyProgress.find(day => day.date === today);
      log.info('Today\'s progress:', todayProgress);

      if (!todayProgress?.completed) {
        log.info('Updating streak for today');
        const updatedData = await updateWinStreak(timezone);
        setWinStreakData(updatedData);
        setWithExpiry(WIN_STREAK_KEY, updatedData, getMillisecondsUntilMidnight());
        setWithExpiry(storageKey, today, getMillisecondsUntilMidnight());
        setIsOpen(true);
        log.info('Streak update complete');
        return true;
      }
      log.info('Streak already completed for today');
    } catch (error) {
      Sentry.captureException(error);
      log.error("Streak update error:", error instanceof Error ? error.message : "Unknown error");
    }
    return false;
  };


  const openModalAndUpdate = () => {
    const timezone = getUserTimezone();
    checkAndUpdateStreak(timezone);
  };


  const closeModal = () => setIsOpen(false);

  const fadeAnimation = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translateY(0)" : "translateY(-20px)"
  });

  const allowedPaths = ["/", "/chat", "/voice-chat", "/search", "/profile"];
  const shouldShowIcon = allowedPaths.includes(location.pathname);

  return (
    <HybridSystemContext.Provider value={{ openModal: toggleModal, openModalAndUpdate, closeModal }}>
      <ThemeProvider theme={theme}>
        {children}
        <ClickAwayListener onClickAway={closeModal}>
          <div>
            <Popper
              open={isOpen}
              anchorEl={fabRef.current}
              placement="bottom-end"
              style={{ zIndex: 1300 }}
              modifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [0, 10]
                  }
                }
              ]}
            >
              <animated.div style={fadeAnimation}>
                <Paper sx={{
                  width: { xs: "90vw", sm: "80vw", md: "60vw", lg: "40vw" },
                  maxWidth: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 4
                }}>
                  <Typography variant="h3" align="center" color="primary" gutterBottom sx={{ fontWeight: "bold" }}>
                    {winStreakData.currentStreak}
                  </Typography>
                  <Typography variant="h6" align="center" gutterBottom color="primary">
                    {description}
                  </Typography>
                  <Typography variant="body2" align="center" color="text.secondary" gutterBottom>
                    {metric}
                  </Typography>

                  <Grid container justifyContent="space-between" sx={{ my: 3 }} spacing={1}>
                    {stepDetails.slice(0, 5).map((step, index) => (
                      <Grid item xs={12 / 5} key={index}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                          <Box
                            sx={{
                              width: { xs: 40, sm: 50, md: 60 },
                              height: { xs: 40, sm: 50, md: 60 },
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mb: 1
                            }}
                          >
                            <StreakIcon day={index + 1} isActive={index < winStreakData.currentStreak} />
                          </Box>
                          <Typography variant="caption" align="center"
                                      sx={{ fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.8rem" } }}>
                            {step.description}
                          </Typography>
                          <Typography variant="caption" align="center"
                                      sx={{ fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.8rem" } }}>
                            {step.title}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </animated.div>
            </Popper>
            {shouldShowIcon && (<Box sx={{ position: "fixed", top: "1vh", right: "3vw", zIndex: 1200 }}>
                <Badge
                  badgeContent={winStreakData.currentStreak}
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  sx={{
                    "& .MuiBadge-badge": {
                      fontSize: "0.75rem",
                      minWidth: "18px",
                      height: "18px",
                      borderRadius: "9px",
                      backgroundColor: "green",
                      color: "white",
                      padding: "0 4px",
                      transform: "scale(1) translate(25%, -25%)"
                    }
                  }}
                >
                  <IconButton
                    ref={fabRef}
                    aria-label="streak"
                    onClick={toggleModal}
                    sx={{
                      p: 1,
                      bgcolor: "transparent",
                      "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.04)"
                      }
                    }}
                  >
                    <LocalFireDepartmentIcon
                      sx={{
                        color: isCurrentDayChecked() ? "#FF9800" : "text.secondary",
                        fontSize: "2rem"
                      }}
                    />
                  </IconButton>
                </Badge>
              </Box>
            )}
          </div>
        </ClickAwayListener>
      </ThemeProvider>
    </HybridSystemContext.Provider>
  );
};

export default HybridSystemProvider;
