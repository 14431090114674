import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getWithoutExpiry, RECENT_CHARACTERS_KEY } from "../../helper/storageUtils";
import { ActorFilmography, Movie } from "../../types/MovieType";
import { Cast } from "../../types/CastType";
import log from "loglevel";
import { useBackgroundSync } from "../../hooks/BackgroundSyncHook";
import LoadingScreen from "../miscs/LoadingScreen";
import { findContentBySlug } from "../search/Search";
import Chat from "./Chat";

function findCharacterByToken(searchToken: string): string | null {
  log.debug("[CharacterSearch] Starting search for token:", searchToken);
  const allKeys = Object.keys(localStorage);
  const sessionTokenKeys = allKeys.filter(key => key.startsWith("X-Session-Token-"));
  log.debug(`[CharacterSearch] Found ${sessionTokenKeys.length} session tokens`);

  for (const key of sessionTokenKeys) {
    const value = getWithoutExpiry(key);
    log.trace(`[CharacterSearch] Checking key ${key} with value:`, value);
    if (value === searchToken) {
      const characterId = key.replace("X-Session-Token-", "");
      log.info("[CharacterSearch] Match found for character ID:", characterId);
      return characterId;
    }
  }
  log.debug("[CharacterSearch] No matching character found");
  return null;
}

function isActorFilmography(content: unknown): content is ActorFilmography {
  return (
    typeof content === "object" &&
    content !== null &&
    "name" in content &&
    "profile_path" in content &&
    "filmography" in content &&
    "external_ids" in content
  );
}

// http://localhost:3000/chat/kraven-the-hunter/sergei-kravinoff


const ChatRouter: React.FC = () => {
  const { "*": path } = useParams<{ "*": string }>();
  const navigate = useNavigate();
  const [state, setState] = useState<{
    isLoading: boolean;
    character: Cast | null;
    chat_type: "carousel" | "movie" | "actors" | "routing" |null;
    movie: Movie | null;
    cast: Cast[];
  }>({
    isLoading: true,
    character: null,
    chat_type: null,
    movie: null,
    cast: [],
  });

  const { manualSyncSingle } = useBackgroundSync({
    manualSyncOnly: true,
    manualSyncPerToken: path
  });

  const createMovieFromContent = (content: any): Movie => ({
    // Unified movie creation logic
    adult: false,
    backdrop_path: content.backdrop_path || "",
    genre_ids: content.genre_ids || [],
    id: content.id || 0,
    original_language: content.original_language || "en",
    original_title: content.name || content.title || "",
    overview: content.overview || "",
    popularity: content.popularity || 0,
    poster_path: content.poster_path || "",
    release_date: content.release_date || "",
    title: content.name || content.title || "",
    video: false,
    vote_average: content.vote_average || 0,
    vote_count: content.vote_count || 0,
    random_index: 0,
    source: "filmography",
    cast: content.cast || []
  });



  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const initializeChat = async () => {
      log.debug("[ChatRouter] Initializing chat with path:", path);
      if (!path) {
        log.error("[ChatRouter] No path provided");
        safeSetState({ isLoading: false });
        navigate("/");
        return;
      }

      try {
        const slugs = path.split("/");
        log.info("[ChatRouter] Processing slugs:", slugs);
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

        // UUID Session Handling
        if (slugs.length === 1 && uuidRegex.test(slugs[0])) {
          log.info("[ChatRouter] UUID detected, starting sync");
          const token = slugs[0];
          await manualSyncSingle(token);

          const characterId = findCharacterByToken(token);
          log.debug("[ChatRouter] Character ID from token:", characterId);

          if (characterId) {
            const recentCharacters = getWithoutExpiry(RECENT_CHARACTERS_KEY) || [];
            log.debug("[ChatRouter] Recent characters:", recentCharacters);

            const foundCharacter = recentCharacters.find((c: Cast) => c.character === characterId);
            if (foundCharacter) {
              log.info("[ChatRouter] Setting carousel chat state");
              safeSetState({
                isLoading: false,
                character: foundCharacter,
                chat_type: "carousel",
                movie: createMovieFromContent({ "title": foundCharacter.from_movie }),
                cast: [foundCharacter]
              });
              return;
            }
          }
          throw new Error("No matching character found after UUID sync");
        }

        // Content Slug Handling
        log.debug("[ChatRouter] Processing content slugs");
        const { content, character, type } = await findContentBySlug(slugs);

        log.debug("[ChatRouter] Content search results:", { content, character, type });
        if (content && type && character) {
          const movie = createMovieFromContent(content);
          safeSetState({
            isLoading: false,
            character: character,
            chat_type: "routing",
            movie,
            cast: movie.cast || []
          });
        }else if (content && !character) {
          console.log("content", content);

          const movie = createMovieFromContent(content);
          safeSetState({
            isLoading: false,
            chat_type: "routing",
            movie,
            cast: movie.cast
          });
        }else {
          throw new Error("No valid content found");
        }
      } catch (error) {
        log.error("[ChatRouter] Initialization failed:", error);
        if (isMounted) {
          safeSetState({ isLoading: false });
          navigate("/search", { state: { error: "Character not found" } });
        }
      }
    };

    const safeSetState = (update: Partial<typeof state>) => {
      if (isMounted) setState(prev => ({ ...prev, ...update }));
    };



    initializeChat();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [path, manualSyncSingle, navigate]);

  if (state.isLoading) {
    log.debug("[ChatRouter] Rendering loading state");
    return <LoadingScreen />;
  }

  log.debug("[ChatRouter] Final render state:", state);

  if (state.movie && state.cast) {
    return (
      <Chat
        character={state.character ? state.character : undefined}
        chat_type={state.chat_type || "movie"}
        movie={state.movie}
        cast={state.cast}
      />
    );
  }

  return null;
};

export default ChatRouter;