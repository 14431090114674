import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  Typography,
  TextField,
  IconButton,
  Stack,
  Tooltip,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faWandSparkles,
  faDice,
  faPenNib,
  faCheck,
  faBookOpen
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import { generateScenario } from "../../api/apiClient";
import { getWithoutExpiry } from "../../helper/storageUtils";
import * as Sentry from "@sentry/react";
import log from "loglevel";
import { Session } from "@supabase/supabase-js";
import "../../css/RoleplayDetailsModal.css";

interface Props {
  open: boolean
  onClose: () => void
  onSubmit: (details: RoleplayDetailsType) => void
  initialDetails?: Partial<RoleplayDetailsType>
  currentCharacter: string
  isLoadingScenarios: boolean
  setIsLoadingScenarios: React.Dispatch<React.SetStateAction<boolean>>
  details: RoleplayDetailsType
  setDetails: React.Dispatch<React.SetStateAction<RoleplayDetailsType>>
  session: Session | null
  movieTitle: string
}

export interface RoleplayDetailsType {
  name: string
  age: number
  skills_abilities: string
  scenario: string
}

interface Scenario {
  scene: string
  suggestion: string
  name: string
  age: number
  skills_abilities: string
}

interface ScenarioResponse {
  name: string
  age: number
  skills_abilities: string[]
  scenario: string
}

interface MultiScenarioResponse {
  scenarios: ScenarioResponse[]
}

const FALLBACK_SCENARIOS: Record<string, Scenario[]> = {
  "Default": [{
    scene: "First encounter with the extraordinary",
    suggestion: "What makes your character unique in this world?",
    name: "New Hero",
    age: 25,
    skills_abilities: "Adaptability\nQuick thinking"
  }]
}

interface MultiScenarioResponse {
  scenarios: ScenarioResponse[];
}


interface RoleplayDetailsModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (details: RoleplayDetailsType) => void;
  initialDetails?: Partial<RoleplayDetailsType>;
  currentCharacter: string;
  isLoadingScenarios: boolean;
  setIsLoadingScenarios: React.Dispatch<React.SetStateAction<boolean>>;
  details: RoleplayDetailsType;
  setDetails: React.Dispatch<React.SetStateAction<RoleplayDetailsType>>;
  session?: Session;
  movieTitle: string;
  // isLoading: boolean;
}


const StyledModal = styled(Modal)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ".loading-overlay": {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
    borderRadius: "inherit"
  }
}));


const ModalContent = styled(Box)(() => ({
  position: "relative",
  margin: "auto",
  outline: "none",
  width: 1000,
  minHeight: 600
}));

export function RoleplayDetailsModal({
                                       open,
                                       onClose,
                                       onSubmit,
                                       initialDetails,
                                       currentCharacter,
                                       isLoadingScenarios,
                                       setIsLoadingScenarios,
                                       details,
                                       setDetails,
                                       session,
                                       movieTitle
                                     }: Props) {
  const [dynamicScenarios, setDynamicScenarios] = useState<Scenario[]>([]);

  const [currentScenarioIndex, setCurrentScenarioIndex] = useState(0);
  const [isEditingScenario, setIsEditingScenario] = useState(false);
  const [editedScenario, setEditedScenario] = useState("");
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isShuffling, setIsShuffling] = useState(false);

  const currentGradient = (currentScenarioIndex % 3) + 1;

  useEffect(() => {
    const hasDefaultValues =
      details.name === "" &&
      details.age === 0 &&
      details.skills_abilities === "" &&
      details.scenario === "";

    async function generateDynamicScenarios() {
      try {
        setIsInitialLoading(true);
        setIsLoadingScenarios(true);

        const token = getWithoutExpiry(`X-Session-Token-${currentCharacter}`);
        if (!token) {
          throw new Error("No session token found");
        }

        const response: MultiScenarioResponse = await generateScenario(session, {
          title: movieTitle,
          characters: [currentCharacter],
          description: `A roleplay scenario involving ${currentCharacter}`
        });

        const newScenarios = response.scenarios.map(scenario => ({
          scene: scenario.scenario,
          suggestion: `Channel your inner hero alongside ${currentCharacter}`,
          name: scenario.name,
          age: scenario.age,
          skills_abilities: scenario.skills_abilities.join("\n")
        }));

        setDynamicScenarios(newScenarios);

        // Only set initial scenario if we have default values
        if (hasDefaultValues) {
          const initialScenario = newScenarios[0];
          setDetails({
            name: initialScenario.name,
            age: initialScenario.age,
            skills_abilities: initialScenario.skills_abilities,
            scenario: initialScenario.scene
          });
          setCurrentScenarioIndex(0);
        }

      } catch (error) {
        Sentry.captureException(error);
        log.error("[RoleplayModal] Failed to generate scenarios:", error);

        setDynamicScenarios(FALLBACK_SCENARIOS.Default);

        // Only set fallback scenario if we have default values
        if (hasDefaultValues) {
          const fallbackScenario = FALLBACK_SCENARIOS.Default[0];
          setDetails({
            name: fallbackScenario.name,
            age: fallbackScenario.age,
            skills_abilities: fallbackScenario.skills_abilities,
            scenario: fallbackScenario.scene
          });
          setCurrentScenarioIndex(0);
        }
      } finally {
        setIsLoadingScenarios(false);
        setIsInitialLoading(false);
      }
    }

    if (open && currentCharacter && hasDefaultValues) {
      generateDynamicScenarios();
    }
  }, [open, currentCharacter, movieTitle, session, details]);

  const LoadingFields = () => (
    <>
      <Skeleton variant="rectangular" height={40} sx={{ borderRadius: 1 }} />
      <Skeleton variant="rectangular" height={40} sx={{ borderRadius: 1 }} />
      <Skeleton variant="rectangular" height={92} sx={{ borderRadius: 1 }} />
      <Skeleton variant="rectangular" height={40} sx={{ borderRadius: 1, width: "100%" }} />
    </>
  );


  // Get current scenarios (either dynamic or fallback)
  const scenarios = dynamicScenarios.length > 0 ? dynamicScenarios : FALLBACK_SCENARIOS.Default;

  // Character-specific placeholder suggestions
  const getPlaceholder = () => {
    return `e.g. A new ally for ${currentCharacter}`;
  };

  const getPowersPlaceholder = () => {
    return `e.g. Powers that complement ${currentCharacter}'s abilities`;
  };

  const handleRandomize = async () => {
    if (isEditingScenario || isLoadingScenarios || isShuffling) return;

    setIsShuffling(true);
    setIsLoadingScenarios(true); // Show loading overlay

    await new Promise(resolve => setTimeout(resolve, 1500));

    const nextIndex = (currentScenarioIndex + 1) % scenarios.length;
    const nextScenario = scenarios[nextIndex];

    setCurrentScenarioIndex(nextIndex);
    setDetails({
      name: nextScenario.name,
      age: nextScenario.age,
      skills_abilities: nextScenario.skills_abilities,
      scenario: nextScenario.scene
    });

    setIsShuffling(false);
    setIsLoadingScenarios(false); // Hide loading overlay
  };


  const handleEditScenario = () => {
    setEditedScenario(details.scenario);
    setIsEditingScenario(true);
  };

  const handleSaveScenario = () => {
    setDetails(prev => ({
      ...prev,
      scenario: editedScenario
    }));
    setIsEditingScenario(false);
  };

  const handleSubmit = () => {
    onSubmit(details);
    onClose();
  };

  // Add expanded state for scenario field
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <StyledModal open={open} onClose={onClose} className="roleplay-modal">
      <AnimatePresence>
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          transition={{ type: "spring", duration: 0.4 }}
        >
          <ModalContent className="MuiPaper-root">
            <IconButton
              onClick={onClose}
              className="icon-button close-button"
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>

            <Stack spacing={2} sx={{ minHeight: '500px' }}>
              <Typography className="modal-title">
                <FontAwesomeIcon icon={faBookOpen} />
                {isInitialLoading ? "Generating Roleplay" : "Roleplay Generated"}
              </Typography>

              {isInitialLoading ? (
                <motion.div className="scenario-display" data-gradient={1}>
                  <Skeleton variant="text" width="60%" height={32} />
                  <Skeleton variant="rectangular" height={100} />
                </motion.div>
              ) : (
                <motion.div
                  key={isEditingScenario ? "edit" : details.scenario}
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    height: isExpanded ? "auto" : "initial",
                    scale: isExpanded ? 1.02 : 1
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 30
                  }}
                  className="scenario-display"
                  data-gradient={currentGradient}
                  onClick={() => !isEditingScenario && setIsExpanded(!isExpanded)}
                  style={{ cursor: isEditingScenario ? "default" : "pointer" }}
                >
                  <Typography className="scenario-title">
                    Your Scenario
                    <div className="scenario-actions">
                      {!isEditingScenario ? (
                        <>
                          <Tooltip title="Write your own scenario">
                            <IconButton
                              size="small"
                              onClick={handleEditScenario}
                              className="icon-button"
                            >
                              <FontAwesomeIcon icon={faPenNib} size="sm" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Roll for a new scenario">
                            <IconButton
                              size="small"
                              onClick={handleRandomize}
                              className={`icon-button ${isShuffling ? "rotating" : ""}`}
                              disabled={isShuffling}
                            >
                              <FontAwesomeIcon icon={faDice} size="sm" spin={isShuffling} />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip title="Save your scenario">
                          <IconButton
                            size="small"
                            onClick={handleSaveScenario}
                            className="icon-button"
                          >
                            <FontAwesomeIcon icon={faCheck} size="sm" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </Typography>

                  {isEditingScenario ? (
                    <TextField
                      fullWidth
                      multiline
                      size="small"
                      value={editedScenario}
                      onChange={(e) => setEditedScenario(e.target.value)}
                      variant="outlined"
                      className="scenario-edit"
                      autoFocus
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "8px 12px",
                          maxHeight: "200px",
                          overflow: "auto"
                        },
                        "& .MuiInputBase-input": {
                          maxHeight: "200px",
                          overflow: "auto",
                          fontSize: "15px",
                          lineHeight: 1.4,
                          padding: "8px 0"
                        }
                      }}
                    />
                  ) : (
                    <div className="scenario-content" style={{ padding: "0 16px", background: "transparent" }}>
                      <Typography
                        className="scenario-text"
                        sx={{
                          fontFamily: "\"Roboto\",\"Helvetica\",\"Arial\",sans-serif",
                          fontWeight: 400,
                          letterSpacing: "0.00938em",
                          padding: "8px 0",
                          maxHeight: isExpanded ? "200px" : "4.2em",
                          overflowY: "auto",
                          overflowX: "hidden",
                          margin: "0px",
                          marginBottom: "8px",
                          whiteSpace: "pre-wrap",
                          textOverflow: "ellipsis",
                          color: "var(--text-primary)",
                          fontSize: "15px",
                          lineHeight: 1.4,
                          paddingRight: "32px",
                          width: "100%",
                          wordWrap: "break-word",
                          display: "-webkit-box",
                          WebkitLineClamp: isExpanded ? "unset" : 3,
                          WebkitBoxOrient: "vertical",
                          background: "transparent",
                          "&::-webkit-scrollbar": {
                            width: "4px",
                            background: "transparent"
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "var(--icon-bg)",
                            borderRadius: "2px"
                          }
                        }}
                      >
                        {details.scenario}
                      </Typography>
                      {scenarios.find(s => s.scene === details.scenario)?.suggestion && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          <Typography
                            className="scenario-suggestion"
                            sx={{
                              fontSize: "15px",
                              color: "text.secondary",
                              marginTop: "8px"
                            }}
                          >
                            {scenarios.find(s => s.scene === details.scenario)?.suggestion}
                          </Typography>
                        </motion.div>
                      )}
                    </div>
                  )}

                </motion.div>
              )}

              {(isInitialLoading || isShuffling) ? (
                <Stack spacing={2}>
                  <LoadingFields />
                </Stack>
              ) : (
                <>
                  <TextField
                    label="Character Name"
                    value={details.name}
                    onChange={(e) => setDetails({ ...details, name: e.target.value })}
                    fullWidth
                    variant="outlined"
                    placeholder={getPlaceholder()}
                    size="small"
                  />

                  <TextField
                    label="Age"
                    type="number"
                    value={details.age || ""}
                    onChange={(e) => setDetails({ ...details, age: parseInt(e.target.value) || 0 })}
                    fullWidth
                    variant="outlined"
                    placeholder="18"
                    size="small"
                  />

                  <TextField
                    label="Abilities & Skills"
                    value={details.skills_abilities}
                    onChange={(e) => setDetails({
                      ...details,
                      skills_abilities: e.target.value
                    })}
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    placeholder={getPowersPlaceholder()}
                    size="small"
                  />

                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    disabled={!details.name || !details.skills_abilities || isLoadingScenarios || isInitialLoading}
                  >
                    <FontAwesomeIcon icon={faWandSparkles} style={{ marginRight: 8 }} />
                    {isLoadingScenarios ? "Loading..." : "Begin Adventure"}
                  </button>
                </>
              )}
            </Stack>
          </ModalContent>
        </motion.div>
      </AnimatePresence>
    </StyledModal>
  );
}

export default RoleplayDetailsModal;