import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Typography,
  Avatar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faComments, 
  faHouse, 
  faHouseUser,
} from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faComments as faCommentsRegular } from "@fortawesome/free-regular-svg-icons";
import GridViewIcon from '@mui/icons-material/GridView';
import HomeIcon from '@mui/icons-material/Home';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import { AuthenticatedUser } from '../../../helper/authHelper';
import { useSidebar } from '../../hooks/useSidebar';
import '../../../assets/css/navigation.css';
import { getWithoutExpiry, RECENT_CHARACTERS_KEY } from '../../../helper/storageUtils';
import { Cast } from '../../../types/CastType';
import { useAuthStore } from "../../../stores/useAuthStore";
import { generateSlug } from "../../search/Search";
import { Message } from "../../chat/InnerChat";

const drawerWidth = 280;
const collapsedWidth = 72;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

const StyledDrawer = styled(Drawer)<{ open: boolean }>(({ theme, open }) => ({
  width: open ? drawerWidth : collapsedWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth : collapsedWidth,
    backgroundColor: 'rgba(28, 28, 28, 0.95)',
    backdropFilter: 'blur(20px)',
    WebkitBackdropFilter: 'blur(20px)',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    border: 'none',
    borderRight: '1px solid rgba(255, 255, 255, 0.1)',
  },
}));

const Logo = styled('img')({
  height: 28,
  marginLeft: 8,
});

const OutsideLogo = styled('img')({
  height: 28,
  position: 'fixed',
  left: '80px',
  top: '30px',
  transition: 'opacity 0.3s ease',
});

const StyledListItem = styled(ListItem)<{ isopen: string }>(({ theme, isopen }) => ({
  margin: '4px 12px',
  borderRadius: '8px',
  padding: '10px 16px',
  color: 'white',
  cursor: 'pointer',
  width: 'auto',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiListItemIcon-root': {
    minWidth: 36,
    color: 'white',
  },
  '& .MuiListItemText-primary': {
    fontSize: '14px',
    fontWeight: 500,
    marginRight: isopen === 'true' ? '16px' : '0',
  },
}));

const RecentChatsSection = styled(Box)<{ isopen: string }>(({ isopen }) => ({
  marginTop: '12px',
  padding: '0 8px',
  '& .recent-chat-title': {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '12px',
    padding: '0 12px 8px',
    display: isopen === 'true' ? 'block' : 'none',
  },
}));

const RecentChatItem = styled(ListItem)<{ isopen: string }>(({ theme, isopen }) => ({
  borderRadius: '8px',
  padding: '8px 12px',
  marginBottom: '4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
  },
  '& .chat-name': {
    marginLeft: isopen === 'true' ? '12px' : '0',
    color: 'white',
    fontSize: '14px',
    display: isopen === 'true' ? 'block' : 'none',
  },
}));

const UserSection = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  width: 'inherit',
  padding: '12px',
  borderTop: '1px solid rgba(255, 255, 255, 0.1)',
  backgroundColor: 'rgba(28, 28, 28, 0.95)',
  backdropFilter: 'blur(20px)',
  WebkitBackdropFilter: 'blur(20px)',
}));

const UserInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  cursor: 'pointer',
  padding: '6px 8px',
  borderRadius: '8px',
  maxWidth: '240px',
  margin: '0 auto',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    '& .settings-icon': {
      opacity: 1,
    },
  },
});

const PremiumIndicator = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::after': {
    content: '"MAX"',
    position: 'absolute',
    bottom: -6,
    right: 0,
    background: 'black',
    color: '#FFB800',
    fontSize: '8px',
    padding: '1px 4px',
    borderRadius: '4px',
    fontWeight: 'bold',
    zIndex: 1,
    transform: 'translateX(-2px)'
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -2,
    left: -2,
    right: -2,
    bottom: -2,
    border: '2px solid #FFB800',
    borderRadius: '50%',
    zIndex: 0
  }
});

const StyledSettingsIcon = styled(SettingsIcon)({
  color: 'rgba(255, 255, 255, 0.7)',
  fontSize: '18px',
  opacity: 0.5,
  transition: 'opacity 0.2s ease, transform 0.4s ease',
  marginLeft: 'auto',
  padding: '2px',
  transform: 'rotate(0deg)',
  '&:hover': {
    transform: 'rotate(180deg)',
    opacity: 1
  }
});

const UserUpgradeButton = styled('button')({
  background: 'var(--apple-grey)',
  border: 'none',
  transition: 'all 0.3s ease',
  position: 'relative',
  fontWeight: 650,
  fontSize: '1em',
  padding: '0.9em 1.8em',
  borderRadius: '50px',
  lineHeight: 1.5,
  overflow: 'hidden',
  boxShadow: '0 2px 30px rgba(255, 255, 255, 0.1), 0 0 2px rgb(255, 255, 255)',
  width: '100%',
  marginTop: '12px',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -3,
    right: -3,
    bottom: -3,
    left: -3,
    background: 'var(--frosted-gradient)',
    borderRadius: '23px',
    zIndex: -1,
    filter: 'blur(2px)',
  },
  '&:hover': {
    background: '#E5E5E7',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1), 0 0 3px rgba(255, 255, 255, 0.2)',
  },
  '&:active': {
    transform: 'translateY(1px)',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(255, 255, 255, 0.1)',
  },
});

const DiscordButton = styled('button')({
  background: 'var(--apple-grey)',
  border: 'none',
  transition: 'all 0.3s ease',
  position: 'relative',
  fontWeight: 650,
  fontSize: '0.85em',
  padding: '0.6em',
  borderRadius: '50px',
  lineHeight: 1.5,
  overflow: 'hidden',
  boxShadow: '0 2px 30px rgba(255, 255, 255, 0.1), 0 0 2px rgb(255, 255, 255)',
  width: '85%',
  minWidth: '40px',
  height: '40px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  color: 'white',
  cursor: 'pointer',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -3,
    right: -3,
    bottom: -3,
    left: -3,
    background: 'var(--frosted-gradient)',
    borderRadius: '23px',
    zIndex: -1,
    filter: 'blur(2px)',
  },
  '&:hover': {
    background: '#E5E5E7',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1), 0 0 3px rgba(255, 255, 255, 0.2)',
    color: '#5865F2',
  },
  '&:active': {
    transform: 'translateY(1px)',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(255, 255, 255, 0.1)',
  },
  '& .discord-icon': {
    color: '#5865F2',
    fontSize: '1.2em',
    minWidth: '20px',
  }
});

// Brand color and design constants
const BRAND_COLOR = '#FF8A00';
const BRAND_COLOR_DARK = '#E67A00';

// Carefully curated color palettes with brand color influence
const gradientPalettes = [
  [BRAND_COLOR, '#4ECDC4'],      // Brand Ocean
  [BRAND_COLOR_DARK, '#FFD3B6'], // Warm Brand
  ['#FFB74D', BRAND_COLOR],      // Golden Brand
  ['#79DAE8', BRAND_COLOR],      // Cool Brand
  ['#E8F9FD', BRAND_COLOR],      // Fresh Brand
  [BRAND_COLOR, '#FF6A88'],      // Vibrant Brand
  ['#2AF598', BRAND_COLOR],      // Nature Brand
  [BRAND_COLOR, '#FA709A'],      // Sunset Brand
  ['#B721FF', BRAND_COLOR],      // Electric Brand
  ['#8EC5FC', BRAND_COLOR],      // Sky Brand
  [BRAND_COLOR, '#B5FFFC'],      // Aqua Brand
  ['#FFF720', BRAND_COLOR],      // Citrus Brand
] as const;

// Sophisticated hash function for consistent color selection
function getColorIndicesForUser(identifier: string): number {
  if (!identifier) return 0;
  const hash = Array.from(identifier).reduce(
    (acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0
  );
  return Math.abs(hash) % gradientPalettes.length;
}

// Popcorn icon SVG path (simplified, outline style)
const PopcornIcon = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  opacity: 0,
  transition: 'all 0.2s ease',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '48%',
    width: '70%',
    height: '70%',
    transform: 'translate(-50%, -50%)',
    background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='1.5'%3E%3Cpath d='M7 8c0-.5.5-1 1-1s1 .5 1 1m2-2c0-.5.5-1 1-1s1 .5 1 1m2 2c0-.5.5-1 1-1s1 .5 1 1m2-2c0-.5.5-1 1-1s1 .5 1 1M6 10l1.5 1.5L9 10l1.5 1.5L12 10l1.5 1.5L15 10l1.5 1.5L18 10v8H6v-8z'/%3E%3C/svg%3E") center/contain no-repeat`,
    opacity: 0.9,
  }
});

// Dynamic Avatar component with sophisticated gradient background and brand elements
const DynamicAvatar = styled(Avatar)<{ identifier: string }>(({ identifier }) => {
  const colorIndex = getColorIndicesForUser(identifier);
  const [primaryColor, secondaryColor] = gradientPalettes[colorIndex];
  const nextIndex = (colorIndex + 1) % gradientPalettes.length;
  const [accentColor1, accentColor2] = gradientPalettes[nextIndex];
  
  return {
    background: `linear-gradient(135deg, 
      ${primaryColor} 0%, 
      ${secondaryColor} 50%,
      ${accentColor1} 75%,
      ${accentColor2} 100%
    )`,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'inherit',
      borderRadius: 'inherit',
      filter: 'brightness(1.1) saturate(1.2)',
      opacity: 0,
      transition: 'opacity 0.3s ease',
    },
    '&:hover': {
      '&::before': {
        opacity: 1,
      },
      '& .popcorn-icon': {
        opacity: 0.85,
      }
    },
    '& .MuiAvatar-fallback': {
      color: 'white',
      fontWeight: 600,
      fontSize: '1.1em',
      textShadow: '0 1px 2px rgba(0,0,0,0.1)',
    },
    // Subtle brand ring
    '&::after': {
      content: '""',
      position: 'absolute',
      top: -1,
      left: -1,
      right: -1,
      bottom: -1,
      borderRadius: 'inherit',
      background: `linear-gradient(135deg, ${BRAND_COLOR}20, ${BRAND_COLOR}40)`,
      opacity: 0.5,
      transition: 'opacity 0.3s ease',
    }
  };
});

interface SidebarProps {
  user?: AuthenticatedUser;
  onUpgradeClick: () => void;
}

export const Sidebar: React.FC<SidebarProps> = ({ onUpgradeClick }) => {
  const { user, session, signOut, isLoading, setUser } = useAuthStore();

  const { isOpen, isMobile, toggleSidebar } = useSidebar();
  const messagesJson = getWithoutExpiry("messages");
  const messages = messagesJson ? JSON.parse(messagesJson) : {};

// Process messages to get recent chats sorted by last interaction
  const historyToShow = Object.entries(messages as Record<string, Message[]>)
    .sort(([, messagesA], [, messagesB]) => {
      const lastMessageA = (messagesA as Message[])[messagesA.length - 1];
      const lastMessageB = (messagesB as Message[])[messagesB.length - 1];
      const timestampA = lastMessageA?.timestamp ? new Date(lastMessageA.timestamp).getTime() : 0;
      const timestampB = lastMessageB?.timestamp ? new Date(lastMessageB.timestamp).getTime() : 0;
      return timestampB - timestampA;
    })
    .map(([character, characterMessages]) => {
      const lastMessage = (characterMessages as Message[])[characterMessages.length - 1];
      return {
        character,
        profileImage: lastMessage?.profileImage || `${process.env.PUBLIC_URL}/Placeholder-Portrait.jpg`,
      };
    })
    .slice(0, 8);
  // Get recent characters and merge with message data
  const recentCharacters = getWithoutExpiry(RECENT_CHARACTERS_KEY) || [];
  const recentChats = historyToShow
    .map(chat => {
      const cast = recentCharacters.find((c: Cast) => c.character === chat.character);
      return cast ? { ...cast, profile_path: chat.profileImage } : null;
    })
    .filter(Boolean) as Cast[];


  const navigate = useNavigate();

  if (isMobile) {
    return null;
  }

  const handleUserClick = () => {
    navigate('/profile');
  };

  return (
    <>
      {!isOpen && (
        <OutsideLogo src="/full_logo.svg" alt="Logo" />
      )}
      <StyledDrawer
        variant="permanent"
        open={isOpen}
        className="desktop-only"
      >
        <div className="sidebar-top-border" />
        <DrawerHeader>
          {isOpen ? (
            <>
              <Logo src="/full_logo.svg" alt="Logo" />
              <IconButton 
                onClick={toggleSidebar}
                sx={{ 
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </>
          ) : (
            <IconButton 
              onClick={toggleSidebar}
              sx={{ 
                color: 'white',
                marginLeft: 1,
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                }
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        
        <List>
          <StyledListItem
            onClick={() => navigate('/')}
            isopen={isOpen.toString()}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={location.pathname === '/' ? faHouseUser : faHouse} />
            </ListItemIcon>
            <ListItemText 
              primary="Home"
              sx={{ 
                opacity: isOpen ? 1 : 0,
                transition: 'opacity 0.2s',
              }}
            />
          </StyledListItem>

          <StyledListItem
            onClick={() => navigate('/chat')}
            isopen={isOpen.toString()}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={location.pathname === '/chat' ? faComments : faCommentsRegular} />
            </ListItemIcon>
            <ListItemText 
              primary="Chat"
              sx={{ 
                opacity: isOpen ? 1 : 0,
                transition: 'opacity 0.2s',
              }}
            />
          </StyledListItem>

          <StyledListItem
            onClick={() => navigate('/scenario')}
            isopen={isOpen.toString()}
          >
            <ListItemIcon>
              <ViewQuiltIcon />
            </ListItemIcon>
            <ListItemText 
              primary="My Scenarios"
              sx={{ 
                opacity: isOpen ? 1 : 0,
                transition: 'opacity 0.2s',
              }}
            />
          </StyledListItem>
        </List>

        <RecentChatsSection isopen={isOpen.toString()}>
          <Typography className="recent-chat-title">
            RECENT CHATS
          </Typography>
          <List>
            {recentChats.map((character: Cast, index: number) => (
              <RecentChatItem
                key={index}
                onClick={() => navigate(`/chat/${generateSlug(character.from_movie)}/${generateSlug(character.character)}`)}
                isopen={isOpen.toString()}
              >
                <Avatar
                  src={character.profile_path}
                  alt={character.character}
                />
                <Typography className="chat-name" noWrap>
                  {character.character}
                </Typography>
              </RecentChatItem>
            ))}
          </List>
        </RecentChatsSection>

        <Box 
          sx={{ 
            position: 'fixed',
            bottom: '80px',
            left: 0,
            width: isOpen ? `${drawerWidth}px` : `${collapsedWidth}px`,
            transition: 'width 0.2s',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '12px 0 0 0',
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            background: 'rgba(28, 28, 28, 0.95)',
            backdropFilter: 'blur(20px)',
            WebkitBackdropFilter: 'blur(20px)',
            '& button': {
              width: isOpen ? '85%' : '40px',
              transition: 'all 0.3s ease',
            }
          }}
        >
          <DiscordButton onClick={() => window.open('https://discord.com/invite/AscUxE2NEC', '_blank')}>
            <FontAwesomeIcon icon={faDiscord} className="discord-icon" />
            {isOpen && 'Join our Community'}
          </DiscordButton>
        </Box>

        <UserSection>
          {user && !user.isPremium && isOpen && (
            <Box mb={2}>
              <button 
                className="upgrade-button" 
                onClick={onUpgradeClick}
              >
                <span className="upgrade-button-text">Get MAX</span>
              </button>
            </Box>
          )}
          
          <UserInfo onClick={handleUserClick}>
            {user?.isPremium === "premium" ? (
              <PremiumIndicator>
                <DynamicAvatar
                  identifier={user?.email || user?.name || 'anonymous'}
                  sx={{ 
                    width: 32, 
                    height: 32,
                  }}
                >
                  <PopcornIcon className="popcorn-icon" />
                  {(user?.name?.[0] || user?.email?.[0] || 'U').toUpperCase()}
                </DynamicAvatar>
              </PremiumIndicator>
            ) : (
              <DynamicAvatar
                identifier={user?.email || user?.name || 'anonymous'}
                sx={{ 
                  width: 32, 
                  height: 32,
                }}
              >
                <PopcornIcon className="popcorn-icon" />
                {(user?.name?.[0] || user?.email?.[0] || 'A').toUpperCase()}
              </DynamicAvatar>
            )}
            {isOpen && (
              <>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: 'white',
                    fontWeight: 500,
                    flexGrow: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {user?.name || user?.email?.split('@')[0] || 'Anonymous'}
                </Typography>
                <StyledSettingsIcon className="settings-icon" />
              </>
            )}
          </UserInfo>
        </UserSection>
      </StyledDrawer>
    </>
  );
}; 