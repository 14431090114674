import log from "loglevel";
import { ContentType } from "./components/search/Search";

export const convertMinutesToMilliseconds = (minutes: number) => {
  return minutes * 60 * 1000;
};

export const convertDaysToMilliseconds = (days: number) => {
  return days * 24 * 60 * 60 * 1000;
};

export const getCurrentIp = (ip: string) => {
  fetch("https://api.ipify.org?format=json")
    .then(response => response.json())
    .then(data => {
      if (data.ip === "212.93.130.122") {
        log.info(process.env);
        return data.ip;
      }
    })
    .catch(error => {
      log.error(error);
    });
};
export const MOVIE_DISPLAY_ITEMS_PER_PAGE = 50;
export const MOVIE_CAST_ITEMS_PER_PAGE = 20;
export const POPULAR_MOVIE_LIMIT = 20;
export const POPULAR_MOVIE_CAST_LIMIT = 20;
export const CAST_LIMIT = 20;
export const CARTOONS_CAST_LIMIT = 6;
export const MOVIE_LIMIT = 20;


export const TTL_POPULAR_MOVIES = convertDaysToMilliseconds(2);

export const currentEnv = process.env.REACT_APP_ENV || "development";
export const backendEnv = process.env.REACT_APP_BACKEND_ENV || "local";

export const retellai_id = process.env.REACT_APP_RETELL_AI_AGENT_ID || "31aef5a437423bac8939f9e4a048ef0d";
export const retell_mode = process.env.REACT_APP_RETELL_MODE || "API";


export const VAPI_PUBLIC_KEY = process.env.REACT_APP_VAPI_PUBLIC_KEY || "0c6ba807-6a10-4567-82bc-28fa9f95ccb1";
export const VAPI_ASSISTANT_ID = process.env.REACT_APP_VAPI_ASSISTANT_ID || "4984d8d3-2028-4886-acbe-2b0ee0b1f054";

// Play.ht Configuration
export const PLAYHT_API_KEY = process.env.REACT_APP_PLAYHT_API_KEY || "";
export const PLAYHT_USER_ID = process.env.REACT_APP_PLAYHT_USER_ID || "";

let API_URL: string;
if (backendEnv === "local") {
  API_URL = process.env.REACT_APP_LOCAL_API_URL || "http://localhost:8080";
} else {
  API_URL = process.env.REACT_APP_PROD_API_URL || "https://scenextras.herokuapp.com";
}

export { API_URL };

// Function to check if analytics and error tracking should be active

export const isAnalyticsEnabled = () => currentEnv === "production";

// Function to log environment info (useful for debugging)
export const logEnvironmentInfo = () => {
  log.info(`Current Environment: ${currentEnv}`);
  log.info(`Backend Environment: ${backendEnv}`);
  log.info(`API URL: ${API_URL}`);
  log.info(`Analytics Enabled: ${isAnalyticsEnabled()}`);
};

export const EXCLUDED_TITLES = [
  "Heavenly Touch"
];

export const getPopularKey = (type: string) => {
  return `popular_${type}_23.01.2025`;
};

export const getSearchResultsKey = (category: ContentType): string => {
  return `cachedSearches_new_${category}_23.01.2025`;
};

