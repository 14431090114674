import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Button,
  Tabs,
  Tab,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Skeleton,
} from "@mui/material";
import { PostCard } from "./PostCard";
import { Session } from "@supabase/supabase-js";
import { fetchUserExports, updateExportInteraction, ChatExport, fetchAllExports } from "../../api/apiClient";
import Loading from "../miscs/Loading";
import logo from "../../assets/img/scenextras_logo.svg";
import { useNavigate } from "react-router-dom";
import TopMenuBar from "../template/TopMenuBar";
import { getWithExpiry, setWithExpiry } from "../../helper/storageUtils";
import { keyframes } from "@emotion/react";

interface PostListProps {
  session?: Session | null;
}

interface PostListProps {
  session?: Session | null;
}

type SortOption = "created_at" | "views" | "likes" | "shares";
type ViewMode = "all" | "personal";

const CACHE_TTL = 3 * 60 * 1000; // 3 minutes in milliseconds

interface CachedData {
  posts: ChatExport[];
  timestamp: number;
}

const getCacheKey = (mode: ViewMode) => `export_cache_${mode}`;

const shimmer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export function PostList({ session }: PostListProps) {
  const [rawPosts, setRawPosts] = useState<ChatExport[]>([]);
  const [displayedPosts, setDisplayedPosts] = useState<ChatExport[]>([]);
  const [loading, setLoading] = useState(true);
  const [isResourceLoading, setResourceIsLoading] = useState(false);
  const [viewMode, setViewMode] = useState<ViewMode>("all");
  const [sortBy, setSortBy] = useState<SortOption>("likes");
  const navigate = useNavigate();

  const sortPosts = (posts: ChatExport[], sortOption: SortOption): ChatExport[] => {
    return [...posts].sort((a, b) => {
      switch (sortOption) {
        case "created_at":
          return new Date(b.created_at || 0).getTime() - new Date(a.created_at || 0).getTime();
        case "views":
          return (b.views || 0) - (a.views || 0);
        case "likes":
          return (b.likes || 0) - (a.likes || 0);
        case "shares":
          return (b.shares || 0) - (a.shares || 0);
        default:
          return 0;
      }
    });
  };

  const handleViewModeChange = async (_event: React.SyntheticEvent, newValue: ViewMode) => {
    setViewMode(newValue);
    setLoading(true);
    await loadPosts(newValue);
  };

  const handleSortChange = (event: SelectChangeEvent) => {
    const newSortBy = event.target.value as SortOption;
    setSortBy(newSortBy);
    setDisplayedPosts(sortPosts(rawPosts, newSortBy));
  };

  const loadPosts = async (mode: ViewMode) => {
    setLoading(true);
    try {
      const CACHE_KEY = getCacheKey(mode);
      const cachedData = getWithExpiry(CACHE_KEY) as CachedData | null;
      const currentTime = Date.now();

      // If cache exists and is still fresh (less than 3 minutes old)
      if (cachedData && currentTime - cachedData.timestamp < CACHE_TTL) {
        setRawPosts(cachedData.posts);
        setDisplayedPosts(sortPosts(cachedData.posts, sortBy));
        setLoading(false);
        return;
      }

      // If cache is stale or doesn't exist, fetch fresh data
      const fetchFunction = mode === "all" ? fetchAllExports : fetchUserExports;
      const response = await fetchFunction(session, {
        page: 1,
        limit: 1000,
        sortBy: "created_at",
        sortOrder: "desc"
      });

      // Update cache with new data
      const newCacheData: CachedData = {
        posts: response.exports,
        timestamp: currentTime
      };
      setWithExpiry(CACHE_KEY, newCacheData, CACHE_TTL);

      // Update state
      setRawPosts(response.exports);
      setDisplayedPosts(sortPosts(response.exports, sortBy));
    } catch (error) {
      console.error("Failed to load posts:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    loadPosts(viewMode);
  }, [session]);

  const handleLike = async (exportId: string) => {
    const success = await updateExportInteraction(session, exportId, "like");
    if (success) {
      const updatedPosts = rawPosts.map(post =>
        post.export_id === exportId
          ? { ...post, likes: (post.likes || 0) + 1 }
          : post
      );
      setRawPosts(updatedPosts);
      setDisplayedPosts(sortPosts(updatedPosts, sortBy));
    }
  };

  const getTimeAgo = (timestamp: string | undefined): string => {
    if (!timestamp) return "Unknown time ago";

    const now = new Date();
    const messageTime = new Date(timestamp);
    const diffInMinutes = Math.floor((now.getTime() - messageTime.getTime()) / (1000 * 60));

    if (diffInMinutes < 1) {
      return "Just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes !== 1 ? "s" : ""} ago`;
    } else if (diffInMinutes < 1440) {
      const hours = Math.floor(diffInMinutes / 60);
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(diffInMinutes / 1440);
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    }
  };


  const handleShare = async (exportId: string) => {
    const success = await updateExportInteraction(session, exportId, "share");
    if (success) {
      const updatedPosts = rawPosts.map(post =>
        post.export_id === exportId
          ? { ...post, shares: (post.shares || 0) + 1 }
          : post
      );
      setRawPosts(updatedPosts);
      setDisplayedPosts(sortPosts(updatedPosts, sortBy));

      const shareUrl = `${window.location.origin}/export/${exportId}`;
      await navigator.clipboard.writeText(shareUrl);
    }
  };

  const svgBackground = {
    backgroundImage: `url(${process.env.PUBLIC_URL}background.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    height: "100%"
  };

  return (
    <>
      {isResourceLoading && <Loading />}
      <div style={svgBackground}>
        <div className="top-bar" onClick={() => navigate("/")}>
          <img className="logo" src={logo} alt="Logo" />
        </div>

        <TopMenuBar />

        <Box sx={{
          maxWidth: 800,
          mx: "auto",
          mt: 2,
          px: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2
        }}>
          <Tabs
            value={viewMode}
            onChange={handleViewModeChange}
            sx={{
              minHeight: 'unset',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              borderRadius: '100px',
              padding: '2px',
              '& .MuiTabs-flexContainer': {
                gap: '2px',
              },
              '& .MuiTab-root': {
                color: 'rgba(255, 255, 255, 0.7)',
                textTransform: 'none',
                minHeight: '32px',
                height: '32px',
                padding: '0 20px',
                borderRadius: '100px',
                fontSize: '14px',
                '&.Mui-selected': {
                  color: '#000',
                  backgroundColor: '#fff',
                }
              },
              '& .MuiTabs-indicator': {
                display: 'none',
              }
            }}
          >
            <Tab label="All Portals" value="all" />
            <Tab label="My Portals" value="personal" />
          </Tabs>

          <Select
            value={sortBy}
            onChange={handleSortChange}
            size="small"
            sx={{
              height: '32px',
              fontSize: '14px',
              color: 'white',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              borderRadius: '100px',
              minWidth: '130px',
              '& .MuiSelect-select': {
                padding: '4px 14px',
              },
              '& fieldset': {
                borderColor: 'transparent',
              },
              '&:hover fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
              },
              '& .MuiSelect-icon': {
                color: 'white',
              }
            }}
          >
            <Box sx={{
              p: 2,
              pb: 1,
              color: 'rgba(0, 0, 0, 0.7)',
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              fontSize: '14px'
            }}>
              SORT POSTS BY
            </Box>
            <MenuItem value="likes" sx={{ fontSize: '14px', py: 1 }}>Most Liked</MenuItem>
            <MenuItem value="created_at" sx={{ fontSize: '14px', py: 1 }}>Most Recent</MenuItem>
            <MenuItem value="views" sx={{ fontSize: '14px', py: 1 }}>Most Viewed</MenuItem>
            <MenuItem value="shares" sx={{ fontSize: '14px', py: 1 }}>Most Shared</MenuItem>
          </Select>
        </Box>

        <Box
          component="section"
          sx={{
            maxWidth: 800,
            mx: "auto",
            p: 2,
            height: 'calc(100vh - 200px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
              },
            },
            scrollBehavior: 'smooth',
            msOverflowStyle: 'none',
            scrollbarWidth: 'thin',
          }}
        >
          {loading ? (
            // Skeleton loading state
            [...Array(3)].map((_, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,
                  p: 2,
                  borderRadius: '16px',
                  bgcolor: 'rgba(255, 255, 255, 0.08)',
                  position: 'relative',
                  overflow: 'hidden',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    transform: 'translateX(-100%)',
                    backgroundImage: `linear-gradient(
                      90deg,
                      rgba(255, 255, 255, 0) 0,
                      rgba(255, 255, 255, 0.05) 20%,
                      rgba(255, 255, 255, 0.1) 60%,
                      rgba(255, 255, 255, 0)
                    )`,
                    animation: `${shimmer} 2s infinite`,
                    animationDelay: `${index * 0.1}s`
                  }
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Skeleton 
                    variant="rounded"
                    width={120}
                    height={120}
                    sx={{ 
                      borderRadius: '12px',
                      bgcolor: 'rgba(255, 255, 255, 0.08)'
                    }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <Skeleton 
                      variant="rounded"
                      width="60%"
                      height={28}
                      sx={{ 
                        mb: 1,
                        borderRadius: '6px',
                        bgcolor: 'rgba(255, 255, 255, 0.08)'
                      }}
                    />
                    <Skeleton 
                      variant="rounded"
                      width="40%"
                      height={20}
                      sx={{ 
                        mb: 2,
                        borderRadius: '6px',
                        bgcolor: 'rgba(255, 255, 255, 0.08)'
                      }}
                    />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      {[...Array(3)].map((_, i) => (
                        <Skeleton 
                          key={i}
                          variant="rounded"
                          width={60}
                          height={24}
                          sx={{ 
                            borderRadius: '6px',
                            bgcolor: 'rgba(255, 255, 255, 0.08)'
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))
          ) : (
            displayedPosts.map((post) => (
              <PostCard
                key={post.export_id}
                title={post.title || `Chat with ${post.character}`}
                author={post.user_name || "Anonymous"}
                timeAgo={getTimeAgo(post.created_at)}
                character={post.character}
                likes={post.likes}
                views={post.views}
                shares={post.shares}
                thumbnail={post.backgroundImage || undefined}
                exportId={post.export_id}
                onLike={() => handleLike(post.export_id)}
                onShare={() => handleShare(post.export_id)}
              />
            ))
          )}
        </Box>
      </div>
    </>
  );
}